import React from 'react';

import { gray1, white } from '../../styles/colors';
import { Row, Col } from '../../styles/grid';
import { Image, ImageBox, NavPageButton, TalkToExpertButton, Text, AdminHeaderButtons } from '../../styles/common';

import px2vw from '../../utils/responsiveness/px2vw';
import { getResource } from '../../utils/constants';

import { admin, adminFellow, adminHarmonizeBR, adminHarmonizePT, adminUsers, fellowSpecialization, harmonizeMethod } from '../../routes/routeMap';

export default function SidebarMenu({ handler }) {
  const userAuthenticatedData = JSON.parse(localStorage.getItem("userAuthenticated"));

  return (
    <Col height="auto" alignCenter padtop={px2vw(40)} padbottom={px2vw(40)}>
      <Row height="auto" justifyCenter margtop={px2vw(40)}>
        <ImageBox width={px2vw(800)} height={px2vw(120)}>
          <Image src={getResource('logoImageBlack')} />
        </ImageBox>
      </Row>
      {!handler.isAdminRoute && (
        <>
          <Row margtop={px2vw(80)} margbottom={px2vw(40)}>
            <NavPageButton width={px2vw(800)} height={px2vw(130)} borderradius={px2vw(100)}>
              <a href={harmonizeMethod}>
                <Text color={white} fontWeight="bold" fontSize={px2vw(55)}>{getResource('navPageButtonHarmonizeText')}</Text>
              </a>
            </NavPageButton>
          </Row>
          {handler.country === 'br' && (
            <Row margbottom={px2vw(40)}>
              <NavPageButton width={px2vw(800)} height={px2vw(130)} borderradius={px2vw(100)}>
                <a href={fellowSpecialization}>
                  <Text color={white} fontWeight="bold" fontSize={px2vw(55)}>{getResource('navPageButtonFellowText')}</Text>
                </a>
              </NavPageButton>
            </Row>
          )}
          <TalkToExpertButton width={px2vw(800)} height={px2vw(130)} borderradius={px2vw(100)}
            onClick={() => {
              handler.handleSetSidebarOpen(false);
              handler.handleSetIsTalkToExpertFormOpen(true);
            }}
          >
            <Text color={gray1} fontSize={px2vw(55)}>{getResource('talkToExpertText')}</Text>
          </TalkToExpertButton>
        </>
      )}
      {handler.isAdminRoute && (
        <Col margtop={px2vw(80)} margleft={px2vw(150)}>
          <a href={adminHarmonizeBR}>
            <AdminHeaderButtons
              isMobile
              borderradius={px2vw(30)} 
              active={handler.currentAdminPage.includes('metodo-harmonize-br')} 
            >
              <Text color={gray1} fontSize={px2vw(50)}>Método Harmonize Brasil</Text>
            </AdminHeaderButtons>
          </a>
          <a href={adminHarmonizePT}>
            <AdminHeaderButtons
              isMobile
              borderradius={px2vw(30)} 
              active={handler.currentAdminPage.includes('metodo-harmonize-pt')} 
            >
              <Text color={gray1} fontSize={px2vw(50)}>Método Harmonize Portugal</Text>
            </AdminHeaderButtons>  
          </a>   
          <a href={adminFellow}>
            <AdminHeaderButtons
              isMobile 
              borderradius={px2vw(30)} 
              active={handler.currentAdminPage.includes('especializacao-fellow')}  
            >
              <Text color={gray1} fontSize={px2vw(50)}>Especialização Fellow</Text>
            </AdminHeaderButtons>   
          </a>
          {false && userAuthenticatedData.is_admin && (
            <a href={adminUsers}>
              <AdminHeaderButtons 
                isMobile
                borderradius={px2vw(30)} 
                active={handler.currentAdminPage.includes('usuarios')} 
              >
                <Text color={gray1} fontSize={px2vw(50)}>Usuários</Text>
              </AdminHeaderButtons>
            </a>
          )}   
          <a href={admin}>
            <AdminHeaderButtons
              isMobile
              borderradius={px2vw(30)}
              margtop={px2vw(120)}  
              onClick={() => localStorage.clear()}
            >
              <Text color={gray1} fontSize={px2vw(50)}>Sair</Text>
            </AdminHeaderButtons>
          </a>
        </Col>
      )}
    </Col>
  )
}
