import React from 'react';

import { gray, gray1, white } from '../../../../styles/colors';
import { Row } from '../../../../styles/grid';
import { Image, ImageBox, AdminHeaderButtons, Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';

import { home } from '../../../../routes/routeMap';

export default function HeaderWeb({ handler }) {
  const userAuthenticatedData = JSON.parse(localStorage.getItem("userAuthenticated"));

  return (
    <Row height={px2vw(80)} alignCenter boxshadow={`0px 7px 9px -4px ${gray}`} fixed top="0" zIndex="10" background={white}>
      <Row width="auto" height="auto" justifyStart padleft={px2vw(40)}>
        <ImageBox width={px2vw(200)} height={px2vw(30)}>
          <a href={home}>
            <Image src={getResource('logoImageBlack')} />
          </a>
        </ImageBox>
      </Row>
      <Row margright={px2vw(50)} justifyEnd height="auto" alignCenter>
        <AdminHeaderButtons 
          borderradius={px2vw(30)} 
          active={handler.currentAdminPage.includes('metodo-harmonize-br')} 
          onClick={() => handler.handleHeaderNavigation('harmonize-br')}
        >
          <Text color={gray1} fontSize={px2vw(15)}>Método Harmonize Brasil</Text>
        </AdminHeaderButtons>
        <AdminHeaderButtons 
          borderradius={px2vw(30)} 
          active={handler.currentAdminPage.includes('metodo-harmonize-pt')}  
          onClick={() => handler.handleHeaderNavigation('harmonize-pt')}
        >
          <Text color={gray1} fontSize={px2vw(15)}>Método Harmonize Portugal</Text>
        </AdminHeaderButtons>     
        <AdminHeaderButtons 
          borderradius={px2vw(30)} 
          active={handler.currentAdminPage.includes('especializacao-fellow')}  
          onClick={() => handler.handleHeaderNavigation('fellow')}
        >
          <Text color={gray1} fontSize={px2vw(15)}>Especialização Fellow</Text>
        </AdminHeaderButtons>   
        {false && userAuthenticatedData.is_admin && (
          <AdminHeaderButtons 
            borderradius={px2vw(30)} 
            margright={px2vw(30)}
            active={handler.currentAdminPage.includes('usuarios')} 
            onClick={() => handler.handleHeaderNavigation('users')}
          >
            <Text color={gray1} fontSize={px2vw(15)}>Usuários</Text>
          </AdminHeaderButtons>
        )}   
        <AdminHeaderButtons 
          borderradius={px2vw(30)} 
          onClick={() => handler.handleLogout()}
        >
          <Text color={gray1} fontSize={px2vw(15)}>Sair</Text>
        </AdminHeaderButtons>    
      </Row>
    </Row>
  )
}
