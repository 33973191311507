import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import queryString from "query-string";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Sidebar from "react-sidebar";
import { toast, ToastContainer } from 'react-toastify';
import { child, get, onValue, ref } from "firebase/database";
import useHotjar from 'react-use-hotjar';

import GlobalStyle from './styles/global';
import { red, red1, red2, yellow, yellow2, yellow3 } from "./styles/colors";

import Home from './pages/Home';
import HarmonizeMethod from './pages/HarmonizeMethod';
import FellowSpecialization from './pages/FellowSpecialization';
import AdminLogin from './pages/Admin/Login';
import AdminHarmonizeBR from './pages/Admin/HarmonizeBR';
import AdminFellow from "./pages/Admin/Fellow";
import AdminHarmonizePT from "./pages/Admin/HarmonizePT";

import { 
  home, harmonizeMethod, fellowSpecialization, admin, 
  adminHarmonizeBR, adminFellow, adminHarmonizePT 
} from "./routes/routeMap";

import RealTimeDimensions from './utils/responsiveness/RealTimeDimensions';
import { diffBetweenDateAndNow, leadingZeros } from "./utils/utils";

import SidebarMenu from "./components/SidebarMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TalkToExpertForm from "./components/TalkToExpertForm";
import RequireAuth from './components/RequireAuth';

import { ProvideAuth } from './hooks/useAuth'

import { getResource } from "./utils/constants";
import db from "./utils/firebase";

function App() {
  const defaultCountry = process.env.REACT_APP_DEFAULT_COUNTRY;
  const queryParams = queryString.parse(window.location.search);
  const { initHotjar } = useHotjar();
  const [country, setCountry] = useState("");
  const [harmonizeMethodWaitingPT, setHarmonizeMethodWaitingPT] = useState(false);
  const [nextClassHarmonizeMethodPT, setNextClassHarmonizeMethodPT] = useState(null);
  const [availableSeatsHarmonizeMethodPT, setAvailableSeatsHarmonizeMethodPT] = useState(0);
  const [scheduleDocURLPDFHarmonizeMethodPT, setScheduleDocURLPDFHarmonizeMethodPT] = useState(null);
  const [scheduleDocURLPDFHarmonizeMethodAdvancedPT, setScheduleDocURLPDFHarmonizeMethodAdvancedPT] = useState(null);
  const harmonizeRefPT = useRef(null);
  const [timerHarmonizeMethodPT, setTimerHarmonizeMethodPT] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [nextClassHarmonizeMethodBR, setNextClassHarmonizeMethodBR] = useState(null);
  const [availableSeatsHarmonizeMethodBR, setAvailableSeatsHarmonizeMethodBR] = useState(0);
  const [scheduleDocURLPDFHarmonizeMethodBR, setScheduleDocURLPDFHarmonizeMethodBR] = useState(null);
  const harmonizeRefBR = useRef(null);
  const [timerHarmonizeMethodBR, setTimerHarmonizeMethodBR] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [fellowSpecializationWaiting, setFellowSpecializationWaiting] = useState(false);
  const [nextClassFellowSpecialization, setNextClassFellowSpecialization] = useState(null);
  const [availableSeatsFellowSpecialization, setAvailableSeatsFellowSpecialization] = useState(0);
  const [scheduleDocURLPDFFellowSpecialization, setScheduleDocURLPDFFellowSpecialization] = useState(null);
  const [moduleDatesFellowSpecialization, setModuleDatesFellowSpecialization] = useState([]);
  const fellowRef = useRef(null);
  const [timerFellowSpecialization, setTimerFellowSpecialization] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;
  const [methodType, setMethodType] = useState('harmonize');
  const [resultsWeb, setResultsWeb] = useState(1);
  const [resultsMobile, setResultsMobile] = useState([1, 2, 3, 4, 5, 6]);
  const [students, setStudents] = useState([1, 2, 3, 4]);
  const [modules, setModules] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]);
  const [isTalkToExpertFormOpen, setIsTalkToExpertFormOpen] = useState(false);
  const [isModulesExpanded, setIsModulesExpanded] = useState(false);
  const [isAdminRoute, setIsAdminRoute] = useState(false);
  const [currentAdminPage, setCurrentAdminPage] = useState('harmonize-br');
  const [selectDaysContent, setSelectDaysContent] = useState([]);
  const [selectYearsContent, setSelectYearsContent] = useState([]);

  // Get country code to display brazil or portugal site
  useEffect(() => {
    if (queryParams.country === 'br' || queryParams.country === 'pt') {
      setCountry(queryParams.country)
    } else {
      axios.get("https://ipapi.co/json/")
      .then((response) => {
        setCountry(response.data.country_name === 'Portugal' ? 'pt' : defaultCountry);
      })
      .catch((error) => {
        console.log('Error retrieving the country: ', error);

        if (queryParams.country === 'br' || queryParams.country === 'pt') {
          setCountry(queryParams.country)
        } else {
          setCountry(defaultCountry); 
        }
      });
    }
  // eslint-disable-next-line  
  }, []);

  // Get methods database data
  useEffect(() => {
    // Get harmonize method pt database data
    get(child(ref(db), `methods/harmonize_pt`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          throw new Error('Error retrieving Harmonize Method Portugal data')
        }

        const data = snapshot.val();
        setHarmonizeMethodWaitingPT(data.waiting_list);
        setAvailableSeatsHarmonizeMethodPT(data.available_seats);
        setNextClassHarmonizeMethodPT(data.next_class_date);
        setScheduleDocURLPDFHarmonizeMethodPT(data.schedule_doc_url);
        setScheduleDocURLPDFHarmonizeMethodAdvancedPT(data.schedule_advanced_doc_url);
      })
      .catch(() => {
        toast.error("Erro ao carregar as informações do Método Harmonize Portugal. Tente recarregar a página!", {
          autoClose: 6000,
        });
      });

    // Get harmonize method br database data
    get(child(ref(db), `methods/harmonize_br`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          throw new Error('Error retrieving Harmonize Method Brasil data')
        }

        const data = snapshot.val();
        setAvailableSeatsHarmonizeMethodBR(data.available_seats);
        setNextClassHarmonizeMethodBR(data.next_class_date);
        setScheduleDocURLPDFHarmonizeMethodBR(data.schedule_doc_url);
      })
      .catch(() => {
        toast.error("Erro ao carregar as informações do Método Harmonize Brasil. Tente recarregar a página!", {
          autoClose: 6000,
        });
      });

    // Get fellow specialization database data
    get(child(ref(db), `methods/fellow`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          throw new Error('Error retrieving Fellow Specialization data')
        }

        const data = snapshot.val();
        setFellowSpecializationWaiting(data.waiting_list);
        setAvailableSeatsFellowSpecialization(data.available_seats);
        setNextClassFellowSpecialization(data.next_class_date);
        setScheduleDocURLPDFFellowSpecialization(data.schedule_doc_url);

        onValue(ref(db, 'methods/fellow/modules_schedule'), (snapshot) => {
          const modules = [];
    
          snapshot.forEach((snap) => {
            const key = snap.key;
            const val = snap.val();
            modules.push({
              'key' : key,
              'val' : val
            })
          })
    
          setModuleDatesFellowSpecialization(modules);
        })
      })
      .catch(() => {
        toast.error("Erro ao carregar as informações da Especialização Fellow. Tente recarregar a página!", {
          autoClose: 6000,
        });
      });
  }, [])

  // Create Admin select Days, Months and Years
  useEffect(() => {
    // Days
    const selectDaysOptions = [];
    const selectYearsOptions = [];

    // Days
    for (var i = 1; i <= 31; i++) {
      selectDaysOptions.push(leadingZeros(i));
    }
    setSelectDaysContent(selectDaysOptions);
  
    // Years
    for (var k = 0; k <= 4; k++) {
      const currentYear = new Date().getFullYear();

      selectYearsOptions.push(currentYear + k);
    }
    setSelectYearsContent(selectYearsOptions);
  }, [])

  // Hotjar definitions
  useEffect(() => {
    initHotjar(3174350, 6, false);
  }, [initHotjar]);

  // Define timer for next harmonize method PT class
  useEffect(() => {
    const harmonizeTimer = diffBetweenDateAndNow(nextClassHarmonizeMethodPT);

    if (harmonizeTimer.total >= 0) {
      setTimerHarmonizeMethodPT({
        days: harmonizeTimer.days,
        hours: harmonizeTimer.hours,
        minutes: harmonizeTimer.minutes,
        seconds: harmonizeTimer.seconds
      });

      const id = setInterval(() => {
        const harmonizeTimer = diffBetweenDateAndNow(nextClassHarmonizeMethodPT);

        if (harmonizeTimer.total >= 0) {
          setTimerHarmonizeMethodPT({
            days: harmonizeTimer.days,
            hours: harmonizeTimer.hours,
            minutes: harmonizeTimer.minutes,
            seconds: harmonizeTimer.seconds
          });
        }
      }, 1000);
      harmonizeRefPT.current = id;
    } else if (harmonizeRefPT) {
      clearInterval(harmonizeRefPT.current)
    }
  }, [nextClassHarmonizeMethodPT]);

  // Define timer for next harmonize method BR class
  useEffect(() => {
    const harmonizeTimer = diffBetweenDateAndNow(nextClassHarmonizeMethodBR);

    if (harmonizeTimer.total >= 0) {
      setTimerHarmonizeMethodBR({
        days: harmonizeTimer.days,
        hours: harmonizeTimer.hours,
        minutes: harmonizeTimer.minutes,
        seconds: harmonizeTimer.seconds
      });

      const id = setInterval(() => {
        const harmonizeTimer = diffBetweenDateAndNow(nextClassHarmonizeMethodBR);

        if (harmonizeTimer.total >= 0) {
          setTimerHarmonizeMethodBR({
            days: harmonizeTimer.days,
            hours: harmonizeTimer.hours,
            minutes: harmonizeTimer.minutes,
            seconds: harmonizeTimer.seconds
          });
        }
      }, 1000);
      harmonizeRefBR.current = id;
    } else if (harmonizeRefBR) {
      clearInterval(harmonizeRefBR.current)
    }
  }, [nextClassHarmonizeMethodBR]);

  // Define timer for next fellow specialization class
  useEffect(() => {
    const fellowTimer = diffBetweenDateAndNow(nextClassFellowSpecialization);

    if (fellowTimer.total > 0) {
      setTimerFellowSpecialization({
        days: fellowTimer.days,
        hours: fellowTimer.hours,
        minutes: fellowTimer.minutes,
        seconds: fellowTimer.seconds
      });

      const id = setInterval(() => {
        const fellowTimer = diffBetweenDateAndNow(nextClassFellowSpecialization);

        if (fellowTimer.total >= 0) {
          setTimerFellowSpecialization({
            days: fellowTimer.days,
            hours: fellowTimer.hours,
            minutes: fellowTimer.minutes,
            seconds: fellowTimer.seconds
          });
        }
      }, 1000);
      fellowRef.current = id;
    } else if (fellowRef) {
      clearInterval(fellowRef.current)
    }
  }, [nextClassFellowSpecialization]);

  function onSetSidebarOpen(isOpen) {
    setSidebarOpen(isOpen);
  }

  function onSetResultWeb() {
    if (resultsWeb === 1) {
      setResultsWeb(2);
    } else {
      setResultsWeb(1);
    }
  }

  function onSetResultMobile(nav) {
    const newResults = [...resultsMobile]
    if (nav === 'previous') {
      newResults[0] = resultsMobile[5]
      newResults[1] = resultsMobile[0]
      newResults[2] = resultsMobile[1] 
      newResults[3] = resultsMobile[2]
      newResults[4] = resultsMobile[3]
      newResults[5] = resultsMobile[4]

    } else {
      newResults[0] = resultsMobile[1]
      newResults[1] = resultsMobile[2]
      newResults[2] = resultsMobile[3] 
      newResults[3] = resultsMobile[4]
      newResults[4] = resultsMobile[5]
      newResults[5] = resultsMobile[0]

    }
    setResultsMobile([...newResults])
  }

  function onSetStudents(nav) {
    const newStudents = [...students]
    if (nav === 'previous') {
      newStudents[0] = students[3]
      newStudents[1] = students[0]
      newStudents[2] = students[1] 
      newStudents[3] = students[2]
    } else {
      newStudents[0] = students[1]
      newStudents[1] = students[2]
      newStudents[2] = students[3] 
      newStudents[3] = students[0]
    }
    setStudents([...newStudents])
  }

  function onSetModules(nav) {
    const newModules = [...modules]
    if (nav === 'previous') {
      newModules[0] = modules[17]
      newModules[1] = modules[0]
      newModules[2] = modules[1] 
      newModules[3] = modules[2]
      newModules[4] = modules[3]
      newModules[5] = modules[4]
      newModules[6] = modules[5]
      newModules[7] = modules[6]
      newModules[8] = modules[7]
      newModules[9] = modules[8]
      newModules[10] = modules[9]
      newModules[11] = modules[10]
      newModules[12] = modules[11]
      newModules[13] = modules[12]
      newModules[14] = modules[13]
      newModules[15] = modules[14]
      newModules[16] = modules[15]
      newModules[17] = modules[16]
    } else {
      newModules[0] = modules[1]
      newModules[1] = modules[2]
      newModules[2] = modules[3] 
      newModules[3] = modules[4]
      newModules[4] = modules[5]
      newModules[5] = modules[6]
      newModules[6] = modules[7]
      newModules[7] = modules[8]
      newModules[8] = modules[9]
      newModules[9] = modules[10]
      newModules[10] = modules[11]
      newModules[11] = modules[12]
      newModules[12] = modules[13]
      newModules[13] = modules[14]
      newModules[14] = modules[15]
      newModules[15] = modules[16]
      newModules[16] = modules[17]
      newModules[17] = modules[0]
    }
    setModules([...newModules])
  }

  const handler = {
    country: country,
    isWeb: isWeb,
    harmonizeMethodWaitingPT: harmonizeMethodWaitingPT,
    timerHarmonizeMethodPT: timerHarmonizeMethodPT,
    nextClassHarmonizeMethodPT: nextClassHarmonizeMethodPT,
    availableSeatsHarmonizeMethodPT: availableSeatsHarmonizeMethodPT,
    scheduleDocURLPDFHarmonizeMethodPT: scheduleDocURLPDFHarmonizeMethodPT,
    scheduleDocURLPDFHarmonizeMethodAdvancedPT: scheduleDocURLPDFHarmonizeMethodAdvancedPT,
    timerHarmonizeMethodBR: timerHarmonizeMethodBR,
    nextClassHarmonizeMethodBR: nextClassHarmonizeMethodBR,
    availableSeatsHarmonizeMethodBR: availableSeatsHarmonizeMethodBR,
    scheduleDocURLPDFHarmonizeMethodBR: scheduleDocURLPDFHarmonizeMethodBR,
    fellowSpecializationWaiting: fellowSpecializationWaiting,
    timerFellowSpecialization: timerFellowSpecialization,
    nextClassFellowSpecialization: nextClassFellowSpecialization,
    availableSeatsFellowSpecialization: availableSeatsFellowSpecialization,
    scheduleDocURLPDFFellowSpecialization: scheduleDocURLPDFFellowSpecialization,
    moduleDatesFellowSpecialization: moduleDatesFellowSpecialization,
    methodType: methodType,
    resultsWeb: resultsWeb,
    resultsMobile: resultsMobile,
    students: students,
    modules: modules,
    isTalkToExpertFormOpen: isTalkToExpertFormOpen,
    isModulesExpanded: isModulesExpanded,
    isAdminRoute: isAdminRoute,
    currentAdminPage: currentAdminPage,
    handleSetSidebarOpen: (isOpen) => onSetSidebarOpen(isOpen),
    handleSetResultsWeb: () => onSetResultWeb(),
    handleSetResultsMobile: (nav) => onSetResultMobile(nav),
    handleSetStudents: (nav) => onSetStudents(nav),
    handleSetModules: (nav) => onSetModules(nav),
    handleSetMethodType: (methodType) => setMethodType(methodType),
    handleSetIsTalkToExpertFormOpen: (isOpen) => setIsTalkToExpertFormOpen(isOpen),
    handleSetIsModulesExpanded: (isOpen) => setIsModulesExpanded(isOpen),
  }

  const adminHandler = {
    isWeb: isWeb,
    currentAdminPage: currentAdminPage,
    harmonizeMethodWaitingPT: harmonizeMethodWaitingPT,
    nextClassHarmonizeMethodPT: nextClassHarmonizeMethodPT,
    availableSeatsHarmonizeMethodPT: availableSeatsHarmonizeMethodPT,
    scheduleDocURLPDFHarmonizeMethodPT: scheduleDocURLPDFHarmonizeMethodPT,
    scheduleDocURLPDFHarmonizeMethodAdvancedPT: scheduleDocURLPDFHarmonizeMethodAdvancedPT,
    nextClassHarmonizeMethodBR: nextClassHarmonizeMethodBR,
    availableSeatsHarmonizeMethodBR: availableSeatsHarmonizeMethodBR,
    scheduleDocURLPDFHarmonizeMethodBR: scheduleDocURLPDFHarmonizeMethodBR,
    fellowSpecializationWaiting: fellowSpecializationWaiting,
    nextClassFellowSpecialization: nextClassFellowSpecialization,
    availableSeatsFellowSpecialization: availableSeatsFellowSpecialization,
    scheduleDocURLPDFFellowSpecialization: scheduleDocURLPDFFellowSpecialization,
    moduleDatesFellowSpecialization: moduleDatesFellowSpecialization,
    selectModulesDays: selectDaysContent,
    selectModulesYears: selectYearsContent,
    handleSetSidebarOpen: (isOpen) => onSetSidebarOpen(isOpen),
    handleSetIsAdminRoute: (isAdminRoute) => setIsAdminRoute(isAdminRoute),
    handleSetCurrentAdminPage: (currAdminPage) => setCurrentAdminPage(currAdminPage),
    handleSetHarmonizeMethodWaitingPT: (isActive) => setHarmonizeMethodWaitingPT(isActive),
    handleSetAvailableSeatsHarmonizeMethodPT: (seats) => setAvailableSeatsHarmonizeMethodPT(seats),
    handleSetNextClassHarmonizeMethodPT: (date) => setNextClassHarmonizeMethodPT(date),
    handleSetScheduleDocURLPDFHarmonizeMethodPT: (doc) => setScheduleDocURLPDFHarmonizeMethodPT(doc),
    handleSetScheduleDocURLPDFHarmonizeMethodAdvancedPT: (doc) => setScheduleDocURLPDFHarmonizeMethodAdvancedPT(doc),
    handleSetAvailableSeatsHarmonizeMethodBR: (seats) => setAvailableSeatsHarmonizeMethodBR(seats),
    handleSetNextClassHarmonizeMethodBR: (date) => setNextClassHarmonizeMethodBR(date),
    handleSetScheduleDocURLPDFHarmonizeMethodBR: (doc) => setScheduleDocURLPDFHarmonizeMethodBR(doc),
    handleSetFellowSpecializationWaiting: (isActive) => setFellowSpecializationWaiting(isActive),
    handleSetAvailableSeatsFellowSpecialization: (seats) => setAvailableSeatsFellowSpecialization(seats),
    handleSetNextClassFellowSpecialization: (date) => setNextClassFellowSpecialization(date),
    handleSetScheduleDocURLPDFFellowSpecialization: (doc) => setScheduleDocURLPDFFellowSpecialization(doc),
    handleSetModuleDatesFellowSpecialization: (modules) => setModuleDatesFellowSpecialization(modules),
  }

  const routesContent = (
    <BrowserRouter>
      <Routes>
        <Route path={home} element={
          <>
            <Header handler={handler} isAtHome={true} />
            <Home handler={handler} />
            <Footer handler={handler} />
            <TalkToExpertForm handler={handler} 
              isAtHarmonize
              bgColor={`linear-gradient(${red1}, ${red2})`}
              isVisible={isTalkToExpertFormOpen} 
              isMobile={!isWeb} 
              rdEventType={getResource('rdEventTalkToExpert', country)} 
            />
          </>
        }/>
        <Route path={harmonizeMethod} element={
          <>
            <Header handler={handler} isAtHarmonize={true} timer={country === 'br' ? timerHarmonizeMethodBR : timerHarmonizeMethodPT} />
            <HarmonizeMethod handler={handler} />
            <Footer handler={handler} />
            <TalkToExpertForm handler={handler}
              isAtHarmonize
              bgColor={`linear-gradient(${red1}, ${red2})`}
              isVisible={isTalkToExpertFormOpen} 
              isMobile={!isWeb} 
              rdEventType={getResource('rdEventTHarmonizeMethod', country)} 
            />
          </>
        }/>
        <Route path={fellowSpecialization} element={
          <>
            <Header handler={handler} isAtFellow={true} timer={timerFellowSpecialization} bgColor={red} />
            <FellowSpecialization handler={handler} />
            <Footer handler={handler} isAtFellow={true} />
            <TalkToExpertForm handler={handler} 
              isAtFellow
              bgColor={`linear-gradient(${yellow}, ${yellow3}, ${yellow2})`}
              isVisible={isTalkToExpertFormOpen} 
              isMobile={!isWeb} 
              rdEventType={getResource('rdEventFellowBR')} 
            />
          </>
        }/>
        <Route path={admin} element={<AdminLogin isWeb={isWeb} />}/>
        <Route path={adminHarmonizeBR} element={<RequireAuth><AdminHarmonizeBR handler={adminHandler} /></RequireAuth>}/>
        <Route path={adminHarmonizePT} element={<RequireAuth><AdminHarmonizePT handler={adminHandler} /></RequireAuth>}/>
        <Route path={adminFellow} element={<RequireAuth><AdminFellow handler={adminHandler} /></RequireAuth>}/>
        <Route path="*" element={
          <>
            <Header handler={handler} isAtHome={true} />
            <Home handler={handler}/>
            <Footer handler={handler} />
            <TalkToExpertForm handler={handler}
              isAtHarmonize
              bgColor={`linear-gradient(${red1}, ${red2})`}
              isVisible={isTalkToExpertFormOpen} 
              isMobile={!isWeb} 
              rdEventType={getResource('rdEventTalkToExpert', country)} 
            />
          </>
        }/>
      </Routes>
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </BrowserRouter>
  );

  const mobileContent = (
    <Sidebar
      sidebar={<SidebarMenu handler={handler} />}
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
      touch={false}
      styles={{
        sidebar: { 
          zIndex: 9999,
          background: 'white',
          width: '70%'
        }
      }}
    >
      {routesContent}
    </Sidebar>
  );

  return (
    <ProvideAuth>
      {isWeb ? routesContent : mobileContent}
    </ProvideAuth>
  );
}

export default App;
