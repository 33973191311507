import React from 'react';

import { gray } from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';
import { Image, ImageBox, Text } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

export default function FooterWeb({ country, isAtFellow = false }) {
  return (
    <Row height={px2vw(100)} padtop={px2vw(30)} boxshadow={`0px -7px 9px -4px ${gray}`}>
      <Col justifyCenter padleft={px2vw(60)} padright={px2vw(100)}>
        <Row spaceBetween>
          <ImageBox width={px2vw(330)} height={px2vw(50)}>
            <Image src={getResource('logoImageBlack')} />
          </ImageBox>
          {isAtFellow && (
            <ImageBox width={px2vw(150)} height={px2vw(55)}>
              <Image src={getResource('fellowFacopImage')} />
            </ImageBox>
          )}
          <Row width={px2vw(100)} spaceBetween height="auto">
            <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer">
              <a href={getResource('instagramTextLink', country)} target="_blank" rel="noreferrer">
                <Image src={getResource('instagramImage')} />
              </a>
            </ImageBox>
            <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer">
              <a href={getResource('youtubeTextLink', country)} target="_blank" rel="noreferrer">
                <Image src={getResource('youtubeImage')} />
              </a>
            </ImageBox>
          </Row> 
        </Row>
        <Row alignCenter justifyStart>
          <ImageBox width={px2vw(10)} height={px2vw(13)} margtop={px2vw(-9)} margright={px2vw(8)}>
            <Image src={getResource('addressImage')} />
          </ImageBox> 
          <Text>{getResource('addressText', country)}</Text>
        </Row>
      </Col>
      <Col alignEnd justifyCenter padright={px2vw(50)} width="60%">
        <Row alignCenter justifyStart>
          <ImageBox width={px2vw(13)} height={px2vw(14)} margright={px2vw(8)} margleft={px2vw(170)}>
            <Image src={getResource('whatsappImage')} />
          </ImageBox> 
          <Text>{getResource('whatsappText', country)}</Text> 
        </Row>
        <Row justifyStart>
          <ImageBox width={px2vw(15)} height={px2vw(13)} margright={px2vw(8)} margleft={px2vw(170)}>
            <Image src={getResource('mailImage')} />
          </ImageBox> 
          <Text>{getResource('mailText', country)}</Text> 
        </Row>
      </Col>
    </Row>
  )
}
