import React from "react";

import HeaderWeb from "../../components/Header/Web";
import HeaderMobile from "../../components/Header/Mobile";
import { white } from "../../styles/colors";

export default function Header({ handler, isAtHome = false, isAtHarmonize = false, isAtFellow = false, timer = null, bgColor = white }) {
  if (handler.isWeb) {
    return <HeaderWeb handler={handler} isAtHome={isAtHome} isAtHarmonize={isAtHarmonize} isAtFellow={isAtFellow} timer={timer} bgColor={bgColor} />
  } else {
    return <HeaderMobile handler={handler} />
  }
}
