import React from "react";

import { white, black, red1 } from "../../../styles/colors";
import { Row, Col } from "../../../styles/grid";
import { Image, ImageBox, ReasonsBox, TalkToExpertButton, Text, HarmonizeFooterBox } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';
import Roulette from "../../../components/Roulette";

export default function HarmonizeMethodWeb({ handler }) {
  return (
    <Col background={black}>
      {/* Session 1 */}
      <Row relative>
        <ImageBox width={px2vw(1366)} height={px2vw(800)} absolute>
          <Image src={getResource('webHarmonizeSession1Image', handler.country)} />
        </ImageBox>
        <Col absolute>
          <Text 
            margtop={px2vw(350)}
            margleft={px2vw(100)}
            color={white}
            width={px2vw(420)}
            fontSize={px2vw(25)}
          >
            {getResource('webHarmonizeSession1Text1', handler.country)}
          </Text>
          <Text 
            margtop={px2vw(20)}
            margleft={px2vw(100)}
            color={white}
            width={px2vw(320)}
            fontSize={px2vw(12)}
          >
            {getResource('harmonizeSession1Text2', handler.country)}
          </Text>
          <Row width="auto" justifyStart margtop={px2vw(30)} margleft={px2vw(100)}>
            <TalkToExpertButton harmonize
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession1ButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Row>
        </Col>
      </Row>   
      <Row 
        margtop={px2vw(-150)}
        justifyCenter
        zIndex="1"
      >
        <ImageBox width={px2vw(400)} height={px2vw(400)}>
          <Image src={getResource('webHarmonizeCircleRedLeftImage')} />
        </ImageBox>
      </Row>
      {/* Session 2 */}
      <Row margtop={px2vw(-230)} width="auto" zIndex="2">
        <Col width="auto" margleft={px2vw(100)} margright={px2vw(50)}>
          <Text
            margtop={px2vw(20)}
            margleft={px2vw(100)}
            color={white}
            width={px2vw(500)}
            fontSize={px2vw(23)}
            fontWeight="bold"
          >
            {getResource('harmonizeSession2TitleText', handler.country)}
          </Text>
          <Text
            margtop={px2vw(20)}
            margleft={px2vw(100)}
            color={white}
            width={handler.country === 'br' ? px2vw(390) : px2vw(400)}
            fontSize={px2vw(15)}
          >
            {getResource('harmonizeSession2Text1', handler.country)}
          </Text>
          <Text
            margtop={px2vw(20)}
            margleft={px2vw(100)}
            color={white}
            width={handler.country === 'br' ? px2vw(390) : px2vw(410)}
            fontSize={px2vw(15)}
          >
            {getResource('harmonizeSession2Text2', handler.country)}
          </Text>
          <Row width="auto" justifyStart margtop={px2vw(30)} margleft={px2vw(100)}>
            <TalkToExpertButton width={px2vw(280)} harmonize
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession2ButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Row>
        </Col>
        <ImageBox width={px2vw(400)} height={px2vw(500)} margtop={px2vw(-30)}>
          <Image src={getResource('webHarmonizeSession2DrLucasExperienceImage')} />
        </ImageBox>
      </Row>
      <Row 
        margtop={px2vw(-120)}
        margleft={px2vw(1000)}
        justifyEnd
        zIndex="3"
        width="auto"
      >
        <ImageBox width={px2vw(200)} height={px2vw(200)}>
          <Image src={getResource('webHarmonizeCircleRedLeftImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 */}
      {/* Methods Type - PT */}
      {handler.country === 'pt' && (
        <Col alignCenter margtop={px2vw(-60)}>
          <Row>
            <TalkToExpertButton harmonize inactive={handler.methodType === 'advanced'}
              onClick={() => handler.handleSetMethodType('harmonize')}
            >
              <Text color={handler.methodType === 'advanced' ? red1 : white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession3Button1Text', handler.country)}</Text>
            </TalkToExpertButton>
            <TalkToExpertButton margleft={px2vw(20)} harmonize inactive={handler.methodType === 'harmonize'}
              onClick={() => handler.handleSetMethodType('advanced')}
            >
              <Text color={handler.methodType === 'harmonize' ? red1 : white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession3Button2Text', handler.country)}</Text>
            </TalkToExpertButton>
          </Row>
          <Row margtop={px2vw(50)}>
            <ImageBox width={px2vw(300)} height={px2vw(250)}>
              <Image isInvisible={handler.methodType !== 'harmonize'} src={getResource('harmonizeSession3MethodHarmonizeImage', handler.country)} />
              <Image isInvisible={handler.methodType !== 'advanced'} src={getResource('harmonizeSession3MethodAdvancedImage', handler.country)} />
            </ImageBox>
            <Col width="auto" alignStart margleft={px2vw(40)}>
              <Text color={white} fontWeight="bold" fontSize={px2vw(18)}>{getResource(handler.methodType === 'harmonize' ? 'harmonizeSession3MethodHarmonizeTitleText' : 'harmonizeSession3MethodAdvancedTitleText', handler.country)}</Text>
              <Text width={px2vw(340)} margtop={px2vw(20)} color={white} fontSize={px2vw(13)}>{getResource(handler.methodType === 'harmonize' ? 'harmonizeSession3MethodHarmonizeText' : 'harmonizeSession3MethodAdvancedText', handler.country)}</Text>
            </Col>
          </Row>
        </Col>
      )}
      <Col margtop={px2vw(handler.country === 'br' ? 0 : 60)} alignCenter>
        <Text color={white} fontWeight="bold" fontSize={px2vw(23)} margleft={px2vw(50)} margbottom={px2vw(-45)}>{getResource('harmonizeSession3LearnText', handler.country)}</Text>
        <Roulette country={handler.country} methodType={handler.methodType} />
        <Text color={white} fontSize={px2vw(14)} cursor="pointer" underline>
          <a href={handler.scheduleDownloadDoc} target="_blank" rel="noreferrer">
            {getResource('harmonizeSession3DownloadSchedule', handler.country)}
          </a>
        </Text>
        <TalkToExpertButton width={px2vw(250)} margtop={px2vw(30)} harmonize
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession3ButtonText', handler.country)}</Text>
        </TalkToExpertButton>
      </Col>
      {/* Session 4 */}
      <Row justifyStart>
        <ImageBox width={px2vw(520)} height={px2vw(650)}>
          <Image src={getResource('webHarmonizeSession4DrLucasReasonsImage')} />
        </ImageBox>
        <Col alignStart width="auto" margleft={px2vw(70)} margtop={px2vw(50)}>
          <Text width={px2vw(460)} color={white} fontWeight="bold" fontSize={px2vw(23)}>{getResource('webHarmonizeSession4ReasonsTitleText', handler.country)}</Text>
          <ImageBox width={px2vw(65)} height={px2vw(20)} margleft={px2vw(310)} margtop={px2vw(-24.5)}>
            <Image src={getResource('webHarmonizeSession4FlagsImage')} />
          </ImageBox>
          <Col alignStart margtop={px2vw(30)}>
            <Row>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason1Text', handler.country)}</Text>
              </ReasonsBox>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason2Text', handler.country)}</Text>
              </ReasonsBox>
            </Row>
            <Row>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason3Text', handler.country)}</Text>
              </ReasonsBox>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason4Text', handler.country)}</Text>
              </ReasonsBox>
            </Row>
            <Row>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason5Text', handler.country)}</Text>
              </ReasonsBox>
              <ReasonsBox>
                <Text color={white}>{getResource('harmonizeSession4Reason6Text', handler.country)}</Text>
              </ReasonsBox>
            </Row>
          </Col>
          <Row justifyCenter>
            <TalkToExpertButton width={px2vw(220)} margtop={px2vw(20)} harmonize
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession4ButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Row>
        </Col>
      </Row>
      {/* Session 5 */}
      <Col margtop={px2vw(50)} alignCenter>
        <Text color={white} fontWeight="bold" fontSize={px2vw(23)}>{getResource('harmonizeSession5Title', handler.country)}</Text>
        <Row alignCenter margtop={px2vw(30)}>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetResultsWeb()} zIndex="2">
            <Image src={getResource('harmonizeArrowLeftImage')} />
          </ImageBox>
          <ImageBox margleft={px2vw(-20)} width={px2vw(1000)} height={px2vw(300)} zIndex="1">
            <Image isInvisible={handler.resultsWeb !== 1} src={getResource(`webHarmonizeSession5Results1Image`)} />
            <Image isInvisible={handler.resultsWeb !== 2} src={getResource(`webHarmonizeSession5Results2Image`)} />
          </ImageBox>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetResultsWeb()} margleft={px2vw(-20)} zIndex="2">
            <Image src={getResource('harmonizeArrowRightImage')} />
          </ImageBox>
        </Row>
        <Row 
          margtop={px2vw(-370)}
          margleft={px2vw(-170)}
          margbottom={px2vw(80)}
          justifyEnd
        >
          <ImageBox width={px2vw(300)} height={px2vw(300)}>
            <Image src={getResource('webHarmonizeCircleRedRightImage')} />
          </ImageBox>
        </Row>
      </Col>
      {/* Session 6 */}
      <Col margtop={px2vw(50)} alignCenter>
        <Text color={white} fontWeight="bold" fontSize={px2vw(23)}>{getResource('harmonizeSession6Title', handler.country)}</Text>
        <Row alignCenter margtop={px2vw(30)}>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetStudents('previous')} zIndex="2">
            <Image src={getResource('harmonizeArrowLeftImage')} />
          </ImageBox>
          <ImageBox margleft={px2vw(-20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image isInvisible={handler.students[0] !== 1} src={getResource(`webHarmonizeSession6Students1Image`)} />
            <Image isInvisible={handler.students[0] !== 2} src={getResource(`webHarmonizeSession6Students2Image`)} />
            <Image isInvisible={handler.students[0] !== 3} src={getResource(`webHarmonizeSession6Students3Image`)} />
            <Image isInvisible={handler.students[0] !== 4} src={getResource(`webHarmonizeSession6Students4Image`)} />
          </ImageBox>
          <ImageBox margleft={px2vw(20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image src={getResource(`webHarmonizeSession6Students${handler.students[1]}Image`)} />
          </ImageBox>
          <ImageBox margleft={px2vw(20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image src={getResource(`webHarmonizeSession6Students${handler.students[2]}Image`)} />
          </ImageBox>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetStudents('forward')} margleft={px2vw(-20)} zIndex="2">
            <Image src={getResource('harmonizeArrowRightImage')} />
          </ImageBox>
        </Row>
        <Row 
          margtop={px2vw(-520)}
          margleft={px2vw(-770)}
          margbottom={px2vw(300)}
          alignStart
          width="auto"
        >
          <ImageBox width={px2vw(300)} height={px2vw(300)}>
            <Image src={getResource('webHarmonizeCircleRedLeftImage')} />
          </ImageBox>
        </Row>
        <Row 
          margtop={px2vw(-320)}
          justifyStart
        >
          <ImageBox width="100%" height={px2vw(400)}>
            <Image src={getResource('webHarmonizeSession6FooterImage')} />
          </ImageBox>
        </Row>
        <Row justifyCenter margtop={px2vw(-160)}>
          <TalkToExpertButton width={px2vw(220)} margtop={px2vw(20)} harmonize
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession6ButtonText', handler.country)}</Text>
          </TalkToExpertButton>
        </Row>
        <HarmonizeFooterBox>
          <Row alignCenter spaceAround>
            <Col width="auto">
              <Text color={white} fontWeight="bold" fontSize={px2vw(23)}>{getResource('harmonizeSession6FooterText1', handler.country)}</Text>
              <Text color={white} margtop={px2vw(10)} fontSize={px2vw(14)}>{getResource('harmonizeSession6FooterText2', handler.country)}</Text>
            </Col>
            <TalkToExpertButton width={px2vw(220)} harmonize
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('harmonizeSession6FooterButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Row>
        </HarmonizeFooterBox>
      </Col>
    </Col>
  );
}
