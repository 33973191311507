import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { ImageBox, Image, SaveBox, SaveInput, SaveButton, Text } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';
import { onlyNumbers } from "../../../../utils/utils";

import Header from "../../../../components/Admin/Header";

import { harmonizeMethod } from "../../../../routes/routeMap";

export default function AdminHarmonizePTWeb({ handler }) {
  return (
    <Col alignCenter padtop={px2vw(80)}>
      <Header handler={handler} />
      <Row margtop={px2vw(40)}>
        <SaveBox>
          <Row alignCenter spaceBetween>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizePTIsWaitingListActiveText')}</Text>
            </Row>
            <Row width={px2vw(209)} justifyStart>
              <SaveInput
                type="checkbox"
                width={px2vw(500)}
                checked={handler.harmonizeMethodWaitingPT}
                onClick={() => handler.handleSetHarmonizeMethodWaitingPT(!handler.harmonizeMethodWaitingPT)}
              />
            </Row>
            <ImageBox width={px2vw(250)} height={px2vw(75)} margtop={px2vw(-30)}>
              <a href={`${harmonizeMethod}?country=pt`} target="_blank" rel="noreferrer">
                <Image src={getResource('adminHomeHarmonizePTImage')} />
              </a>
            </ImageBox>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizePTAvailableSeatsText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.availableSeatsHarmonizeMethodPT}
                onChange={(e) => handler.handleSetAvailableSeatsHarmonizeMethodPT(onlyNumbers(e.target.value))}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizePTNextClassDateText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="date"
                width={px2vw(500)}
                value={handler.nextClassHarmonizeMethodPT?.substring(0, 10)}
                onChange={(e) => handler.handleSetNextClassHarmonizeMethodPT(e.target.value + " 09:00:00")}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizePTSchedulePDFURLText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFHarmonizeMethodPT}
                onChange={(e) => handler.handleSetScheduleDocURLPDFHarmonizeMethodPT(e.target.value)}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizePTScheduleAdvancedPDFURLText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFHarmonizeMethodAdvancedPT}
                onChange={(e) => handler.handleSetScheduleDocURLPDFHarmonizeMethodAdvancedPT(e.target.value)}
              />
            </Row>
          </Row>        
          <Row margtop={px2vw(50)}>
            <SaveButton 
              width={px2vw(315)}
              height={px2vw(35)}
              borderradius={px2vw(30)}
              onClick={() => handler.handleSaveData()}
            >
              <Text color={white}>{getResource('adminSaveButtonLabelText')}</Text>
            </SaveButton>  
          </Row>
        </SaveBox>
      </Row>
    </Col>
  );
}
