import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { 
  Image, ImageBox, Text, LoginBox, LoginInput, 
  LoginButton, SeePasswordButton, SeePasswordImage  
} from '../../../../styles/common';

import { getResource } from '../../../../utils/constants';
import px2vw from "../../../../utils/responsiveness/px2vw";

import { home } from "../../../../routes/routeMap";

export default function AdminLoginMobile({ handler }) {
  return (
    <Col alignCenter justifyCenter height="100%">
      <ImageBox width={px2vw(940)} height={px2vw(160)} margbottom={px2vw(200)}>
        <a href={home}>
          <Image src={getResource('logoImageBlack')} />
        </a>
      </ImageBox>
      <LoginBox isMobile>
        <Col alignStart margleft={px2vw(110)}>
          <Text fontSize={px2vw(38)} margbottom={px2vw(20)}>{getResource('adminLoginUserLabelText')}</Text>
          <LoginInput  
            name="user"
            width={px2vw(800)} 
            margbottom={px2vw(60)} 
            type="text"
            value={handler.user}
            onChange={(e) => handler.setUser(e.target.value)}
            isMobile
          />
          <Text fontSize={px2vw(38)} margbottom={px2vw(20)}>{getResource('adminLoginPwdLabelText')}</Text>
          <Row justifyStart margbottom={px2vw(90)} height="auto">
            <LoginInput 
              name="pwd"
              width={px2vw(800)} 
              type={handler.passwordVisible ? "text" : "password"}
              value={handler.pwd}
              onChange={(e) => handler.setPwd(e.target.value)}
              isMobile
            />
            <SeePasswordButton
              type="button"
              onClick={() => handler.setPasswordVisible(!handler.passwordVisible)}
              isMobile
            >
              <SeePasswordImage
                src={getResource(handler.passwordVisible ? 'adminLoginSeePasswordImage' : 'adminLoginNoSeePasswordImage')}
                isMobile
              />
            </SeePasswordButton>
          </Row>
          <LoginButton 
            width={px2vw(870)} 
            height={px2vw(110)}
            borderradius={px2vw(60)}
            onClick={() => handler.submitLogin()}
            isMobile
          >
            <Text color={white} fontSize={px2vw(38)}>{getResource('adminLoginButtonLabelText')}</Text>
          </LoginButton>
        </Col>
      </LoginBox>
    </Col>
  );
}
