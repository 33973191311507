import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../hooks/useAuth";
import { useCurrentPath } from "../../../hooks/useCurrentPath";

import { admin, adminHarmonizeBR, adminHarmonizePT, adminFellow } from "../../../routes/routeMap";

import HeaderWeb from "./Web";
import HeaderMobile from "./Mobile";

export default function Header({ handler }) {
  const navigate = useNavigate();
  const currentPage = useCurrentPath();
  const { logout } = useAuth();
  
  useEffect(() => {
    handler.handleSetIsAdminRoute(true);
  // eslint-disable-next-line  
  }, [])

  useEffect(() => {
    handler.handleSetCurrentAdminPage(currentPage)
  // eslint-disable-next-line  
  }, [currentPage])

  const handleLogout = () => {
    logout();
    navigate(admin);
  };

  const handleHeaderNavigation = (page) => {
    switch (page) {
      case 'harmonize-br':
        navigate(adminHarmonizeBR);
        break;
      case 'harmonize-pt':
        navigate(adminHarmonizePT);
        break;
      case 'fellow':
        navigate(adminFellow);
        break;      
      default:
        navigate(adminHarmonizeBR);
        break;
    }
  }

  const headerHandler = {
    ...handler,
    handleLogout: () => handleLogout(),
    handleHeaderNavigation: (page) => handleHeaderNavigation(page)
  }

  if (handler.isWeb) {
    return <HeaderWeb handler={headerHandler} />
  } else {
    return <HeaderMobile handler={headerHandler} />
  }
}
