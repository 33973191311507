import React from "react";

import { black, gray1, red, white, yellow, yellow2 } from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';
import { Image, ImageBox, Text, TalkToExpertButton, ReasonsBox, ModuleDateBox, TimelineBox, FellowFooterBox } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

import ClassTimer from "../../../components/ClassTimer";

export default function FellowSpecializationMobile({ handler }) {
  let showTimer = true;
  let headerText1 = getResource('headerClassFellowText1', handler.country);
  let headerText2 = getResource('headerClassFellowText2', handler.country);
  let headerText3 = getResource('headerClassFellowText3', handler.country);

  if (handler.fellowSpecializationWaiting) {
    headerText1 = getResource('headerClassFellowWaitingMobileText', handler.country);
    headerText2 = null;
    headerText3 = null;
    showTimer = false;
  } else {
    headerText2 = headerText2?.replace('@@SEATS@@', handler.availableSeatsFellowSpecialization + (parseInt(handler.availableSeatsFellowSpecialization) === parseInt(1) ? ' VAGA' : ' VAGAS'));
  }

  return (
    <Col height="auto">
      {/* Timer */}
      <Col justifyCenter margtop={px2vw(270)} padtop={px2vw(50)} padbottom={px2vw(50)} background={red} height="auto" width={!showTimer ? "100%" : "100%"}>
        <Col alignCenter>
          <Text textAlign fontSize={px2vw(90)} margbottom={px2vw(10)} color={white}>{headerText1}</Text>
          {headerText2 && <Text fontSize={px2vw(100)} margbottom={px2vw(10)} fontWeight="bold" color={white}>&nbsp;{headerText2}</Text>}
          {headerText3 && <Text fontSize={px2vw(90)} color={white}>&nbsp;{headerText3}</Text>}
        </Col>
        {showTimer && (
          <Row margtop={px2vw(30)} padleft={px2vw(25)}>
            <ClassTimer timer={handler.timerFellowSpecialization} textColor={white} isMobile />
          </Row>
        )}
        {!showTimer && (
          <Row margtop={px2vw(60)} padleft={px2vw(25)}>
            <TalkToExpertButton 
              width={px2vw(1000)} height={px2vw(130)} borderradius={px2vw(100)}
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)} noBorder={handler.country === 'br'}>
              <Text color={gray1} fontSize={px2vw(50)}>{getResource('talkToExpertText')}</Text>
            </TalkToExpertButton>
          </Row>
        )}
      </Col>
      {/* Session 1 */}
      <ImageBox width={px2vw(1365)} height={px2vw(1100)}>
        <Image src={getResource('mobileFellowSession1Image')} />
      </ImageBox>
      <Text 
        margtop={px2vw(80)}
        fontSize={px2vw(70)}
        textAlign
      >
        {getResource('mobileFellowSession1Text1')}
      </Text>
      <Text 
        margtop={px2vw(80)}
        fontSize={px2vw(54)}
        margleft={px2vw(50)}
      >
        {getResource('fellowSession1Text2')}
      </Text>
      <Row justifyCenter margtop={px2vw(80)} zIndex="2">
        <TalkToExpertButton fellow width={px2vw(1100)} height={px2vw(150)} borderradius={px2vw(100)}
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession1ButtonText')}</Text>
        </TalkToExpertButton>
      </Row>
      {/* Session 2 */}
      <Col margtop={px2vw(100)} width="auto" margleft={px2vw(100)}>
        <Text
          margtop={px2vw(20)}
          width={px2vw(500)}
          fontSize={px2vw(65)}
          fontWeight="bold"
        >
          {getResource('fellowSession2TitleText1')}
        </Text>
        <Text
          margtop={px2vw(5)}
          gradient={`linear-gradient(${yellow}, ${yellow2})`}
          fontSize={px2vw(65)}
          fontWeight="bold"
        >
          {getResource('fellowSession2TitleText2')}
        </Text>
        <Text
          margtop={px2vw(50)}
          fontSize={px2vw(55)}
          width={px2vw(1200)}
        >
          {getResource('fellowSession2Text1')}
        </Text>
        <Text
          margtop={px2vw(50)}
          fontSize={px2vw(55)}
          width={px2vw(1200)}
        >
          {getResource('fellowSession2Text2')}
        </Text>
        <Text
          margtop={px2vw(50)}
          fontSize={px2vw(55)}
          width={px2vw(1200)}
        >
          {getResource('fellowSession2Text3')}
        </Text>
        <Text 
          margtop={px2vw(110)}
          fontSize={px2vw(60)}
          fontWeight="bold"
        >
          {getResource('fellowSession2Text4')}
        </Text>
      </Col>
      <Col alignCenter>
        <Row alignCenter justifyCenter margtop={px2vw(70)}>
          <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetModules('previous')} zIndex="1">
            <Image src={getResource('fellowArrowLeftImage')} />
          </ImageBox>
          <ImageBox margleft={px2vw(-70)} width={px2vw(1100)} height={px2vw(1500)}>
            <Image isInvisible={handler.modules[0] !== 1} src={getResource(`fellowSession2Modules1Image`)} />
            <Image isInvisible={handler.modules[0] !== 2} src={getResource(`fellowSession2Modules2Image`)} />
            <Image isInvisible={handler.modules[0] !== 3} src={getResource(`fellowSession2Modules3Image`)} />
            <Image isInvisible={handler.modules[0] !== 4} src={getResource(`fellowSession2Modules4Image`)} />
            <Image isInvisible={handler.modules[0] !== 5} src={getResource(`fellowSession2Modules5Image`)} />
            <Image isInvisible={handler.modules[0] !== 6} src={getResource(`fellowSession2Modules6Image`)} />
            <Image isInvisible={handler.modules[0] !== 7} src={getResource(`fellowSession2Modules7Image`)} />
            <Image isInvisible={handler.modules[0] !== 8} src={getResource(`fellowSession2Modules8Image`)} />
            <Image isInvisible={handler.modules[0] !== 9} src={getResource(`fellowSession2Modules9Image`)} />
            <Image isInvisible={handler.modules[0] !== 10} src={getResource(`fellowSession2Modules10Image`)} />
            <Image isInvisible={handler.modules[0] !== 11} src={getResource(`fellowSession2Modules11Image`)} />
            <Image isInvisible={handler.modules[0] !== 12} src={getResource(`fellowSession2Modules12Image`)} />
            <Image isInvisible={handler.modules[0] !== 13} src={getResource(`fellowSession2Modules13Image`)} />
            <Image isInvisible={handler.modules[0] !== 14} src={getResource(`fellowSession2Modules14Image`)} />
            <Image isInvisible={handler.modules[0] !== 15} src={getResource(`fellowSession2Modules15Image`)} />
            <Image isInvisible={handler.modules[0] !== 16} src={getResource(`fellowSession2Modules16Image`)} />
            <Image isInvisible={handler.modules[0] !== 17} src={getResource(`fellowSession2Modules17Image`)} />
            <Image isInvisible={handler.modules[0] !== 18} src={getResource(`fellowSession2Modules18Image`)} />
          </ImageBox>
          <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetModules('forward')} margleft={px2vw(-70)} zIndex="1">
            <Image src={getResource('fellowArrowRightImage')} />
          </ImageBox>
        </Row>
        <TalkToExpertButton width={px2vw(1200)} height={px2vw(150)} 
          borderradius={px2vw(100)} margtop={px2vw(80)} fellow margbottom={px2vw(100)}
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession2ButtonText')}</Text>
        </TalkToExpertButton>
      </Col>
      {/* Session 3 */}
      <Row relative margtop={px2vw(15)}>
        <ImageBox width={px2vw(1366)} height={px2vw(500)} absolute>
          <Image src={getResource('mobileFellowSession3Image')} />
        </ImageBox>
        <Col absolute alignCenter>
          <Text 
            color={black}
            width={px2vw(1130)}
            fontSize={px2vw(50)}
            textAlign
            font="overlock"
          >
            {getResource('mobileFellowSession3Text1')}
          </Text>
        </Col>
      </Row>
      <Col margtop={px2vw(70)} alignCenter>
        <Text 
          color={black}
          width={px2vw(1000)}
          fontSize={px2vw(70)}
          textAlign
          fontWeight="bold"
          margbottom={px2vw(70)}
        >
          {getResource('fellowSession3Text2')}
        </Text>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More1Text')}</Text>
        </ReasonsBox>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More2Text')}</Text>
        </ReasonsBox>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More3Text')}</Text>
        </ReasonsBox>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More4Text')}</Text>
        </ReasonsBox>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More5Text')}</Text>
        </ReasonsBox>
        <ReasonsBox fellow isMobile>
          <Text color={black} fontWeight="bold" fontSize={px2vw(57)}>{getResource('fellowSession3More6Text')}</Text>
        </ReasonsBox>
        <TalkToExpertButton fellow width={px2vw(1100)} height={px2vw(150)} 
          borderradius={px2vw(100)} margtop={px2vw(40)}
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession3ButtonText')}</Text>
        </TalkToExpertButton>
      </Col>
      {/* Session 4 */}
      <Col margtop={px2vw(100)} alignCenter>
        <Text 
          color={black}
          fontSize={px2vw(65)}
          textAlign
          fontWeight="bold"
        >
          {getResource('fellowSession4Text1')}
        </Text>
        <Row margtop={px2vw(120)}>
          <TimelineBox isMobile isOpenned={handler.isModulesExpanded}>
            <Col justifyStart>
              {handler.moduleDatesFellowSpecializationPart1.map((module, idx) => {
                return (
                  <Row key={`modules-${idx + 1}`} height="auto" margtop={px2vw(idx === 0 ? 0 : 30)}>
                    <ModuleDateBox isMobile>
                      <Text fontWeight="bold" textAlign fontSize={px2vw(28)}>
                        {`${module.val.day_1} E ${module.val.day_2}`}
                        <br />
                        {module.val.month}
                        <br />
                        {module.val.year}
                      </Text>
                    </ModuleDateBox>
                    <Col margleft={px2vw(30)}>
                      <Text fontSize={px2vw(40)}>{getResource(`fellowSession4Module${idx + 1}Text1`)}</Text>
                      <Text fontSize={px2vw(30)} margtop={px2vw(15)}>{getResource(`fellowSession4Module${idx + 1}Text2`)}</Text>
                    </Col>
                  </Row>
                );
              })}
              {!handler.isModulesExpanded && (
                <Row margtop={px2vw(40)}>
                  <TalkToExpertButton fellow width={px2vw(1100)} 
                    height={px2vw(150)} borderradius={px2vw(100)}
                    onClick={() => handler.handleSetIsModulesExpanded(true)}
                  >
                    <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession4Button1Text')}</Text>
                  </TalkToExpertButton>
                </Row>
              )}
              {handler.isModulesExpanded && (
                <>
                  {handler.moduleDatesFellowSpecializationPart2.map((module, idx) => {
                    return (
                      <Row key={`modules-${idx + 7}`} height="auto" margtop={px2vw(30)}>
                        <ModuleDateBox isMobile> 
                          <Text fontWeight="bold" textAlign fontSize={px2vw(28)}>
                            {`${module.val.day_1} E ${module.val.day_2}`}
                            <br />
                            {module.val.month}
                            <br />
                            {module.val.year}
                          </Text>
                        </ModuleDateBox>
                        <Col margleft={px2vw(30)}>
                          <Text fontSize={px2vw(40)}>{getResource(`fellowSession4Module${idx + 7}Text1`)}</Text>
                          <Text fontSize={px2vw(30)} margtop={px2vw(15)}>{getResource(`fellowSession4Module${idx + 7}Text2`)}</Text>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row height="auto" margtop={px2vw(30)}>
                    <ModuleDateBox isMobile>
                      <Text fontWeight="bold" textAlign fontSize={px2vw(28)}>{getResource('fellowSession4Module18Date')}</Text>
                    </ModuleDateBox>
                    <Col margleft={px2vw(30)}>
                      <Text fontSize={px2vw(40)}>{getResource('fellowSession4Module18Text1')}</Text>
                      <Text fontSize={px2vw(30)} margtop={px2vw(15)}>{getResource('fellowSession4Module18Text2')}</Text>
                    </Col>
                  </Row>
                  <Row margtop={px2vw(40)}>
                    <TalkToExpertButton fellow width={px2vw(1100)} 
                    height={px2vw(150)} borderradius={px2vw(100)}
                      onClick={() => handler.handleSetIsModulesExpanded(false)}
                    >
                      <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession4Button2Text')}</Text>
                    </TalkToExpertButton>
                  </Row>
                </>
              )}
            </Col>
          </TimelineBox>
        </Row>
      </Col>
      {/* Session 5 */}
      <Col margtop={px2vw(90)} alignCenter>
        <Text gradient={`linear-gradient(${yellow}, ${yellow2})`} fontSize={px2vw(50)} cursor="pointer" underline textAlign>
          <a href={handler.scheduleDocURLPDFFellowSpecialization} target="_blank" rel="noreferrer">
            {getResource('fellowSession5DownloadSchedule')}
          </a>
        </Text>
        <Row margtop={px2vw(130)}>
          <ImageBox width={px2vw(1100)} height={px2vw(1100)}>
            <Image src={getResource('fellowSession5Image')} />
          </ImageBox>
        </Row>
        <Row margtop={px2vw(90)}>
          <Text fontSize={px2vw(70)} fontWeight="bold" lineThrough>
            {getResource('fellowSession5OldAmount')}
          </Text>
        </Row>
        <Row margtop={px2vw(50)}>
          <Text fontSize={px2vw(50)} fontWeight="bold" textAlign>
            {getResource('mobileFellowSession5NewAmount')}
          </Text>
        </Row>
        <Row margtop={px2vw(80)} zIndex="2">
          <TalkToExpertButton fellow width={px2vw(1100)} 
            height={px2vw(150)} borderradius={px2vw(100)}
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession5ButtonText')}</Text>
          </TalkToExpertButton>
        </Row>
      </Col>
      <Row 
        margtop={px2vw(-560)}
        justifyEnd
        zIndex="1"
      >
        <ImageBox width={px2vw(1200)} height={px2vw(1200)}>
          <Image src={getResource('mobileFellowCircleYellowRightImage')} />
        </ImageBox>
      </Row>
      {/* Session 6 */}
      <Col margtop={px2vw(-500)} alignCenter>
        <Text fontWeight="bold" fontSize={px2vw(65)} textAlign width={px2vw(1100)}>{getResource('fellowSession6Title')}</Text>
        <Row alignCenter justifyCenter margtop={px2vw(90)}>
          <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetStudents('previous')} zIndex="1">
            <Image src={getResource('fellowArrowLeftImage')} />
          </ImageBox>
          <ImageBox margleft={px2vw(-70)} width={px2vw(1200)} height={px2vw(1900)}>
            <Image isInvisible={handler.students[0] !== 1} src={getResource(`fellowSession6Students1Image`)} />
            <Image isInvisible={handler.students[0] !== 2} src={getResource(`fellowSession6Students2Image`)} />
            <Image isInvisible={handler.students[0] !== 3} src={getResource(`fellowSession6Students3Image`)} />
            <Image isInvisible={handler.students[0] !== 4} src={getResource(`fellowSession6Students4Image`)} />
          </ImageBox>
          <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetStudents('forward')} margleft={px2vw(-70)} zIndex="1">
            <Image src={getResource('fellowArrowRightImage')} />
          </ImageBox>
        </Row>
        <TalkToExpertButton width={px2vw(1200)} height={px2vw(150)} 
          borderradius={px2vw(100)} margtop={px2vw(80)} fellow margbottom={px2vw(100)}
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(50)}>{getResource('fellowSession6ButtonText')}</Text>
        </TalkToExpertButton>
        <FellowFooterBox isMobile>
          <Col alignCenter>
            <Text color={white} fontWeight="bold" fontSize={px2vw(70)}>{getResource('fellowSession6FooterText1')}</Text>
            <Text color={white} textAlign margtop={px2vw(50)} fontSize={px2vw(57)}>{getResource('fellowSession6FooterText2')}</Text>
            <TalkToExpertButton margtop={px2vw(50)} width={px2vw(1100)} height={px2vw(150)} 
              borderradius={px2vw(100)} fellow inactive
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text gradient={`linear-gradient(${yellow}, ${yellow2})`} fontWeight="bold" fontSize={px2vw(60)}>{getResource('fellowSession6FooterButtonText')}</Text>
            </TalkToExpertButton>
          </Col>
        </FellowFooterBox>
      </Col>
    </Col>
  );
}
