import { textsResources } from './texts';
import { imagesResources } from './images';

export const resources = {
  ...textsResources,
  ...imagesResources
};

export function getResource(text, country = '') {
  return resources[text + country.toUpperCase()];
}
