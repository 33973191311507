export function diffBetweenDateAndNow(date) {
  const total = Date.parse(new Date(date)) - Date.parse(new Date());
  const days = Math.floor((total / 1000 / 60 / 60 / 24));
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const seconds = Math.floor((total / 1000) % 60);
  
  return {
    total: total,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
}

export function validateEmail(value) {
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(value)
}

export function onlyNumbers(value) {
  const number = `${value}`;
  if (number !== null && number !== undefined && number !== '') {
    return number.replace(/\D/g, '');
  }
  return '';
}

export function leadingZeros(num) {
  const numLength = num.toString().length;
  const s = '0'.repeat(numLength) + num;
  return s.substring(numLength === 1 ? s.length-2 : s.length-numLength);
}

export function dddPhoneMask(value, country) {
  if (value !== null && value !== undefined) {
    const phone = onlyNumbers(value);

    if (country === 'br') {
      // (00) 0000-0000
      if (phone.length === 10) {
        return phone
          .replace(/(\d{2})(\d)/, '($1) $2')
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(-\d{4})\d+?$/, '$1');
      }
      // (00) 00000-0000
      return phone
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    } else {
      // 000 000 000
      return phone
        .replace(/(\d{3})(\d)/, '$1 $2')
        .replace(/(\d{3})(\d)/, '$1 $2')
        .replace(/(-\d{3})\d+?$/, '$1');
    }
  }
  return '';
}