import React from 'react';

import { black, gray, gray1, red, white } from '../../../styles/colors';
import { Row } from '../../../styles/grid';
import { Image, ImageBox, NavPageButton, TalkToExpertButton, Text } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

import { fellowSpecialization, harmonizeMethod, home } from '../../../routes/routeMap';

import ClassTimer from "../../../components/ClassTimer";

export default function HeaderWeb({ handler, timer, isAtHome = false, isAtHarmonize = false, isAtFellow = false, bgColor = white}) {
  const textColor = bgColor === red ? white : black;
  const logoImage = getResource(bgColor === red ? 'logoImageWhite' : 'logoImageBlack');
  let showTimer = true;
  let headerText1 = getResource(isAtHarmonize ? 'headerClassHarmonizeText1' : 'headerClassFellowText1', handler.country);
  let headerText2 = getResource(isAtHarmonize ? 'headerClassHarmonizeText2' : 'headerClassFellowText2', handler.country);
  let headerText3 = getResource(isAtHarmonize ? 'headerClassHarmonizeText3' : 'headerClassFellowText3', handler.country);
  if (isAtHarmonize) {
    if (handler.country === 'pt' && handler.harmonizeMethodWaitingPT) {
      headerText1 = getResource('headerClassHarmonizeWaitingText', handler.country);
      headerText2 = null;
      headerText3 = null;
      showTimer = false;
    } else {
      const availableSeats = handler.country === 'pt' ?  handler.availableSeatsHarmonizeMethodPT : handler.availableSeatsHarmonizeMethodBR;
      headerText2 = headerText2?.replace('@@SEATS@@', availableSeats + (availableSeats === 1 ? ' VAGA' : ' VAGAS'));
    }
  } else {
    if (handler.fellowSpecializationWaiting) {
      headerText1 = getResource('headerClassFellowWaitingText', handler.country);
      headerText2 = null;
      headerText3 = null;
      showTimer = false;
    } else {
      headerText2 = headerText2?.replace('@@SEATS@@', handler.availableSeatsFellowSpecialization + (parseInt(handler.availableSeatsFellowSpecialization) === parseInt(1) ? ' VAGA' : ' VAGAS'));
    }
  }

  return (
    <Row height={px2vw(80)} alignCenter background={bgColor} boxshadow={`0px 7px 9px -4px ${gray}`} fixed top="0" zIndex="10">
      <Row width="auto" height="auto" justifyStart padleft={px2vw(40)}>
        <ImageBox width={px2vw(200)} height={px2vw(30)}>
          <a href={home}>
            <Image src={logoImage} />
          </a>
        </ImageBox>
      </Row>
      {/* Home Header */}
      {isAtHome && (
        <Row justifyEnd height="auto" padright={px2vw(50)}>
          <Row width="auto" margright={px2vw(20)}>
            <NavPageButton>
              <a href={harmonizeMethod}>
                <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('navPageButtonHarmonizeText')}</Text>
              </a>
            </NavPageButton>
          </Row>
          {handler.country === 'br' && (
            <Row width="auto" margright={px2vw(20)}>
              <NavPageButton>
                <a href={fellowSpecialization}>
                  <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('navPageButtonFellowText')}</Text>
                </a>
              </NavPageButton>
            </Row>
          )}
          <TalkToExpertButton onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}>
            <Text color={gray1} fontSize={px2vw(15)}>{getResource('talkToExpertText')}</Text>
          </TalkToExpertButton>
        </Row>
      )}
      {/* Fellow Specialization and Harmonize Method Header */}
      {(isAtHarmonize || isAtFellow) && (
        <Row alignCenter height="auto" padright={px2vw(50)}>
          <Row height="auto" justifyCenter>
            <Text fontSize={px2vw(19)} color={textColor}>{headerText1}</Text>
            {headerText2 && <Text fontSize={px2vw(19)} fontWeight="bold" color={textColor}>&nbsp;{headerText2}</Text>}
            {headerText3 && <Text fontSize={px2vw(19)} color={textColor}>&nbsp;{headerText3}</Text>}
          </Row>
          {!showTimer && (
            <Row width="auto" height="auto" justifyEnd>
              <TalkToExpertButton onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)} noBorder={handler.country === 'br'}>
                <Text color={gray1} fontSize={px2vw(15)}>{getResource('talkToExpertText')}</Text>
              </TalkToExpertButton>
            </Row>
          )}
          {showTimer && (
            <Row width="auto" height="auto" justifyEnd>
              <ClassTimer timer={timer} textColor={textColor} />
            </Row>
          )}
        </Row>
      )}
    </Row>
  )
}
