import React from "react";

import HarmonizeMethodWeb from './Web';
import HarmonizeMethodMobile from './Mobile';

export default function HarmonizeMethod({ handler }) {
  let scheduleDownloadDoc = null;

  if (handler.country === 'br') {
    scheduleDownloadDoc = handler.scheduleDocURLPDFHarmonizeMethodBR;
  } else {
    if (handler.methodType === 'harmonize') {
      scheduleDownloadDoc = handler.scheduleDocURLPDFHarmonizeMethodPT;
    } else {
      scheduleDownloadDoc = handler.scheduleDocURLPDFHarmonizeMethodAdvancedPT;
    }
  }

  const handlerHarmonizeMethod = {
    ...handler,
    scheduleDownloadDoc: scheduleDownloadDoc
  }

  if (handler.isWeb) {
    return <HarmonizeMethodWeb handler={handlerHarmonizeMethod} />
  } else {
    return <HarmonizeMethodMobile handler={handlerHarmonizeMethod} />
  }
}
