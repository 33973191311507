import React, { createContext, useState, useContext } from "react";
import { child, get, ref } from "firebase/database";
import { toast } from "react-toastify";
import { sha256 } from "js-sha256";

import db from "../utils/firebase";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
  
export const useAuth = () => {
  return useContext(authContext);
}

function validateUserWasAuthenticated() {
  if (localStorage.getItem("userAuthenticated")) {
    const userAuthenticatedData = JSON.parse(localStorage.getItem("userAuthenticated"));

    return userAuthenticatedData.token === localStorage.getItem("token");
  }

  return false;
}

function useProvideAuth() {
  const [authed, setAuthed] = useState(validateUserWasAuthenticated());

  return {
    authed,
    login(user, pwd) {
      return new Promise((res) => {
        get(child(ref(db), `users/${user}`))
          .then((snapshot) => {
            if (!snapshot.exists() || snapshot.val().token !== sha256(pwd)) {
              throw new Error('Invalid Credentials')
            }

            setAuthed(true);
            localStorage.setItem("token", sha256(pwd));
            localStorage.setItem("userAuthenticated", JSON.stringify({ "user": snapshot.key, ...snapshot.val()}));
            res();
          })
          .catch(() => {
            toast.error("Credenciais inválidas!", {
              autoClose: 6000,
            });
            setAuthed(false);
            localStorage.clear();
            res();
          });
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        localStorage.clear();
        res();
      });
    },
  };
}
  