/* ------------------------ Images ------------------------ */
// Common
const commonImagesResources = {
  logoImageBlack: '/images/logo/logo-black.png',
  logoImageWhite: '/images/logo/logo-white.png',
}
// Header
const headerImagesResources = {
  mobileMenuImage: '/images/header/mobile-menu.png',
}
// Talk to Expert Form
const talkToExpertFormResources = {
  closeFormImage: '/images/talk-to-expert-form/close.png',
  successFormImage: '/images/talk-to-expert-form/success.png',
}
// Home
const homeImagesResources = {
  // Home
  homeCircleRedImage: '/images/home/circle/web/red.png',
  homeCircleRedRightMobileImage: '/images/home/circle/mobile/red-right.png',
  homeCircleRedLeftMobileImage: '/images/home/circle/mobile/red-left.png',
  homeCircleYellowRightImage: '/images/home/circle/web/yellow-right.png',
  homeCircleYellowLeftImage: '/images/home/circle/web/yellow-left.png',
  homeCircleYellowRightMobileImage: '/images/home/circle/mobile/yellow-right.png',
  homeCircleYellowLeftMobileImage: '/images/home/circle/mobile/yellow-left.png',
  // Home - Session 1
  webHomeSession1Image: '/images/home/session1/main-web.png',
  mobileHomeSession1Image: '/images/home/session1/main-mobile.png',
  homeSession1HarmonizeImage: '/images/home/session1/harmonize.png',
  homeSession1FellowImage: '/images/home/session1/fellow.png',
  // Home - Session 2
  homeSession2DrLucasImage: '/images/home/session2/dr-lucas.png',
  homeSession2InstagramImage: '/images/home/session2/instagram.png',
  homeSession2YoutubeImage: '/images/home/session2/youtube.png',
  // Home - Session 3
  homeSession3Sec1Image: '/images/home/session3/sec1.png',
  homeSession3Sec1TextImage: '/images/home/session3/sec1-text.png',
  homeSession3Sec2Image: '/images/home/session3/sec2.png',
  homeSession3Sec3Image: '/images/home/session3/sec3.png',
  homeSession3Sec4Image: '/images/home/session3/sec4.png',
  homeSession3Sec5Image: '/images/home/session3/sec5.png',
  homeSession3Sec6Image: '/images/home/session3/sec6.png',
  homeSession3Sec6TextImage: '/images/home/session3/sec6-text.png',
  homeSession3Sec7Image: '/images/home/session3/sec7.png',
  homeSession3Sec8Image: '/images/home/session3/sec8.png',
  homeSession3Sec9Image: '/images/home/session3/sec9.png',
  homeSession3Sec9TextImage: '/images/home/session3/sec9-text.png',
}
// Harmonize
const harmonizeImagesResources = {
  // Harmonize - Common
  webHarmonizeCircleRedLeftImage: '/images/harmonize-method/circle/web/red-left.png',
  webHarmonizeCircleRedRightImage: '/images/harmonize-method/circle/web/red-right.png',
  mobileHarmonizeCircleRedLeftImage: '/images/harmonize-method/circle/mobile/red-left.webp',
  mobileHarmonizeCircleRedRightImage: '/images/harmonize-method/circle/mobile/red-right.webp',
  // Harmonize - Session 1
  webHarmonizeSession1ImageBR: '/images/harmonize-method/br/main-web.png',
  webHarmonizeSession1ImagePT: '/images/harmonize-method/pt/main-web.png',
  mobileHarmonizeSession1ImageBR: '/images/harmonize-method/br/main-mobile.webp', 
  mobileHarmonizeSession1ImagePT: '/images/harmonize-method/pt/main-mobile.webp',
  // Harmonize - Session 2
  webHarmonizeSession2DrLucasExperienceImage: '/images/harmonize-method/dr-lucas/web/experience.png',
  mobileHarmonizeSession2DrLucasExperienceImage: '/images/harmonize-method/dr-lucas/mobile/experience.webp',
  // Harmonize - Session 3
  harmonizeSession3MethodHarmonizeImagePT: '/images/harmonize-method/pt/method-harmonize.png',
  harmonizeSession3MethodAdvancedImagePT: '/images/harmonize-method/pt/method-advanced.png',
  webHarmonizeSession3RouletteImage1Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-1.png',
  webHarmonizeSession3RouletteImage2Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-2.png',
  webHarmonizeSession3RouletteImage3Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-3.png',
  webHarmonizeSession3RouletteImage4Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-4.png',
  webHarmonizeSession3RouletteImage5Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-5.png',
  webHarmonizeSession3RouletteImage6Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-6.png',
  webHarmonizeSession3RouletteImage7Harmonize: '/images/harmonize-method/roulette/harmonize/web/roulette-7.png',
  webHarmonizeSession3RouletteImage1Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-1.png',
  webHarmonizeSession3RouletteImage2Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-2.png',
  webHarmonizeSession3RouletteImage3Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-3.png',
  webHarmonizeSession3RouletteImage4Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-4.png',
  webHarmonizeSession3RouletteImage5Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-5.png',
  webHarmonizeSession3RouletteImage6Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-6.png',
  webHarmonizeSession3RouletteImage7Advanced: '/images/harmonize-method/roulette/advanced/web/roulette-7.png',
  mobileHarmonizeSession3RouletteImage1Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-1.webp',
  mobileHarmonizeSession3RouletteImage2Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-2.webp',
  mobileHarmonizeSession3RouletteImage3Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-3.webp',
  mobileHarmonizeSession3RouletteImage4Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-4.webp',
  mobileHarmonizeSession3RouletteImage5Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-5.webp',
  mobileHarmonizeSession3RouletteImage6Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-6.webp',
  mobileHarmonizeSession3RouletteImage7Harmonize: '/images/harmonize-method/roulette/harmonize/mobile/roulette-7.webp',
  mobileHarmonizeSession3RouletteImage1Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-1.webp',
  mobileHarmonizeSession3RouletteImage2Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-2.webp',
  mobileHarmonizeSession3RouletteImage3Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-3.webp',
  mobileHarmonizeSession3RouletteImage4Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-4.webp',
  mobileHarmonizeSession3RouletteImage5Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-5.webp',
  mobileHarmonizeSession3RouletteImage6Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-6.webp',
  mobileHarmonizeSession3RouletteImage7Advanced: '/images/harmonize-method/roulette/advanced/mobile/roulette-7.webp',
  // Harmonize - Session 4
  webHarmonizeSession4DrLucasReasonsImage: '/images/harmonize-method/dr-lucas/web/reasons.png',
  mobileHarmonizeSession4DrLucasReasonsImage: '/images/harmonize-method/dr-lucas/mobile/reasons.webp',
  webHarmonizeSession4FlagsImage: '/images/harmonize-method/flags/web/flags.png',
  mobileHarmonizeSession4FlagsImage: '/images/harmonize-method/flags/mobile/flags.png',
  // Harmonize - Session 5
  harmonizeArrowLeftImage: '/images/harmonize-method/arrows/arrow-left.png',
  harmonizeArrowRightImage: '/images/harmonize-method/arrows/arrow-right.png',
  webHarmonizeSession5Results1Image: '/images/harmonize-method/results/web/results-1.png',
  webHarmonizeSession5Results2Image: '/images/harmonize-method/results/web/results-2.png',
  mobileHarmonizeSession5Results1Image: '/images/harmonize-method/results/mobile/results-1.webp',
  mobileHarmonizeSession5Results2Image: '/images/harmonize-method/results/mobile/results-2.webp',
  mobileHarmonizeSession5Results3Image: '/images/harmonize-method/results/mobile/results-3.webp',
  mobileHarmonizeSession5Results4Image: '/images/harmonize-method/results/mobile/results-4.webp',
  mobileHarmonizeSession5Results5Image: '/images/harmonize-method/results/mobile/results-5.webp',
  mobileHarmonizeSession5Results6Image: '/images/harmonize-method/results/mobile/results-6.webp',
  // Harmonize - Session 6
  webHarmonizeSession6Students1Image: '/images/harmonize-method/students/web/student-1.png',
  webHarmonizeSession6Students2Image: '/images/harmonize-method/students/web/student-2.png',
  webHarmonizeSession6Students3Image: '/images/harmonize-method/students/web/student-3.png',
  webHarmonizeSession6Students4Image: '/images/harmonize-method/students/web/student-4.png',
  mobileHarmonizeSession6Students1Image: '/images/harmonize-method/students/mobile/student-1.webp',
  mobileHarmonizeSession6Students2Image: '/images/harmonize-method/students/mobile/student-2.webp',
  mobileHarmonizeSession6Students3Image: '/images/harmonize-method/students/mobile/student-3.webp',
  mobileHarmonizeSession6Students4Image: '/images/harmonize-method/students/mobile/student-4.webp',
  webHarmonizeSession6FooterImage: '/images/harmonize-method/footer/web/footer.png',
  mobileHarmonizeSession6FooterImage: '/images/harmonize-method/footer/mobile/footer.webp',
}
// Fellow
const fellowImagesResources = {
  // Fellow - Common
  webFellowCircleYellowLeftImage: '/images/fellow-specialization/circle/web/yellow-left.png',
  webFellowCircleYellowRightImage: '/images/fellow-specialization/circle/web/yellow-right.png',
  webFellowCircleYellowRight2Image: '/images/fellow-specialization/circle/web/yellow-right-2.png',
  mobileFellowCircleYellowRightImage: '/images/fellow-specialization/circle/mobile/yellow-right.png',
  fellowArrowLeftImage: '/images/fellow-specialization/arrows/arrow-left.png',
  fellowArrowRightImage: '/images/fellow-specialization/arrows/arrow-right.png',
  // Fellow - Session 1
  webFellowSession1Image: '/images/fellow-specialization/session1/web/main.png',
  fellowSession1FellowImage: '/images/fellow-specialization/session1/web/fellow.png',
  mobileFellowSession1Image: '/images/fellow-specialization/session1/mobile/main.png',
  // Fellow - Session 2
  fellowSession2Modules1Image: '/images/fellow-specialization/session2/module-1.png',
  fellowSession2Modules2Image: '/images/fellow-specialization/session2/module-2.png',
  fellowSession2Modules3Image: '/images/fellow-specialization/session2/module-3.png',
  fellowSession2Modules4Image: '/images/fellow-specialization/session2/module-4.png',
  fellowSession2Modules5Image: '/images/fellow-specialization/session2/module-5.png',
  fellowSession2Modules6Image: '/images/fellow-specialization/session2/module-6.png',
  fellowSession2Modules7Image: '/images/fellow-specialization/session2/module-7.png',
  fellowSession2Modules8Image: '/images/fellow-specialization/session2/module-8.png',
  fellowSession2Modules9Image: '/images/fellow-specialization/session2/module-9.png',
  fellowSession2Modules10Image: '/images/fellow-specialization/session2/module-10.png',
  fellowSession2Modules11Image: '/images/fellow-specialization/session2/module-11.png',
  fellowSession2Modules12Image: '/images/fellow-specialization/session2/module-12.png',
  fellowSession2Modules13Image: '/images/fellow-specialization/session2/module-13.png',
  fellowSession2Modules14Image: '/images/fellow-specialization/session2/module-14.png',
  fellowSession2Modules15Image: '/images/fellow-specialization/session2/module-15.png',
  fellowSession2Modules16Image: '/images/fellow-specialization/session2/module-16.png',
  fellowSession2Modules17Image: '/images/fellow-specialization/session2/module-17.png',
  fellowSession2Modules18Image: '/images/fellow-specialization/session2/module-18.png',
  // Fellow - Session 3
  webFellowSession3Image: '/images/fellow-specialization/session3/web/main.png',
  mobileFellowSession3Image: '/images/fellow-specialization/session3/mobile/main.png',
  // Fellow - Session 4
  fellowSession4Image: '/images/fellow-specialization/session4/dr-lucas.png',
  // Fellow - Session 5
  fellowSession5Image: '/images/fellow-specialization/session5/steps.png',
  // Fellow - Session 6
  fellowSession6Students1Image: '/images/fellow-specialization/session6/student-1.png',
  fellowSession6Students2Image: '/images/fellow-specialization/session6/student-2.png',
  fellowSession6Students3Image: '/images/fellow-specialization/session6/student-3.png',
  fellowSession6Students4Image: '/images/fellow-specialization/session6/student-4.png',
  // Fellow - Footer
  fellowFacopImage: '/images/fellow-specialization/footer/facop.png',
}
// Footer
const footerImagesResources = {
  // Footer
  instagramImage: '/images/social-medias/instagram.png',
  youtubeImage: '/images/social-medias/youtube.png',
  addressImage: '/images/contact/address.png',
  whatsappImage: '/images/contact/whatsapp.png',
  mailImage: '/images/contact/mail.png',
}
// Admin
const adminImagesResources = {
  // Login
  adminLoginSeePasswordImage: '/images/admin/login/eye-outline.png',
  adminLoginNoSeePasswordImage: '/images/admin/login/eye-off-outline.png',
  // Header
  adminMobileMenuImage: '/images/admin/header/mobile-menu-admin.png',
  // Home
  adminHomeHarmonizeBRImage: '/images/admin/methods/harmonize-br.png',
  adminHomeHarmonizePTImage: '/images/admin/methods/harmonize-pt.png',
  adminHomeFellowImage: '/images/admin/methods/fellow.png',
}

export const imagesResources = {
  ...commonImagesResources,
  ...headerImagesResources,
  ...talkToExpertFormResources,
  ...homeImagesResources,
  ...harmonizeImagesResources,
  ...fellowImagesResources,
  ...footerImagesResources,
  ...adminImagesResources
}