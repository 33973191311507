import React from "react";
import { toast } from "react-toastify";
import { ref, set } from "firebase/database";

import db from "../../../utils/firebase";

import AdminFellowWeb from './Web';
import AdminFellowMobile from './Mobile';

export default function AdminFellow({ handler }) {
  function handleSaveData() {
    if (handler.availableSeatsFellowSpecialization.trim() === '' || handler.nextClassFellowSpecialization.trim() === '' || handler.scheduleDocURLPDFFellowSpecialization.trim() === '') {
      toast.error("Algum campo foi deixado sem preenchimento!", {
        autoClose: 6000,
      });
      return;
    }
    if (handler.fellowSpecializationWaiting !== true && handler.fellowSpecializationWaiting !== false) {
      toast.error("Valor inválido para lista de espera!", {
        autoClose: 6000,
      });
      return;
    }
    if (parseInt(handler.availableSeatsFellowSpecialization) < parseInt(0)) {
      toast.error("Número de vagas não pode ser menor que zero", {
        autoClose: 6000,
      });
      return;
    }
    if (new Date(handler.nextClassFellowSpecialization) <= new Date()) {
      toast.error("A data da próxima turma não pode ser menor ou igual a data atual!", {
        autoClose: 6000,
      });
      return;
    }

    set(ref(db, `methods/fellow`), {
      waiting_list: handler.fellowSpecializationWaiting,
      available_seats: handler.availableSeatsFellowSpecialization,
      next_class_date: handler.nextClassFellowSpecialization,
      schedule_doc_url: handler.scheduleDocURLPDFFellowSpecialization
    });

    handler.moduleDatesFellowSpecialization.forEach((moduleDate) => {
      set(ref(db, `methods/fellow/modules_schedule/${moduleDate.key}`), {
        day_1: moduleDate.val.day_1,
        day_2: moduleDate.val.day_2,
        month: moduleDate.val.month,
        year: moduleDate.val.year,
      });
    })

    toast.success("Dados salvos!", {
      autoClose: 6000,
    });
  }

  const adminFellowHandler = {
    ...handler,
    handleSaveData: () => handleSaveData(),
  }

  if (handler.isWeb) {
    return <AdminFellowWeb handler={adminFellowHandler} />
  } else {
    return <AdminFellowMobile handler={adminFellowHandler} />
  }
}
