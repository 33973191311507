import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCCYKQ8CrLbfVrW2o_khWv3OlfhFGZV7hc",
  authDomain: "harmonize-cc323.firebaseapp.com",
  databaseURL: "https://harmonize-cc323-default-rtdb.firebaseio.com",
  projectId: "harmonize-cc323", 
  storageBucket: "harmonize-cc323.appspot.com",
  messagingSenderId: "444087501862",
  appId: "1:444087501862:web:7c0dc103d3ab5aa74d12e7"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export default db;