import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { ImageBox, Image, SaveBox, SaveInput, SaveButton, Text } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';
import { onlyNumbers } from "../../../../utils/utils";

import Header from "../../../../components/Admin/Header";

import { harmonizeMethod } from "../../../../routes/routeMap";

export default function AdminHarmonizeBRWeb({ handler }) {
  return (
    <Col alignCenter padtop={px2vw(80)}>
      <Header handler={handler} />
      <Row margtop={px2vw(40)}>
        <SaveBox>
          <Row justifyEnd margbottom={px2vw(20)}>
            <ImageBox width={px2vw(250)} height={px2vw(75)} margtop={px2vw(-30)}>
              <a href={harmonizeMethod} target="_blank" rel="noreferrer">
                <Image src={getResource('adminHomeHarmonizeBRImage')} />
              </a>
            </ImageBox>
          </Row>
          <Row alignCenter>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizeBRAvailableSeatsText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.availableSeatsHarmonizeMethodBR}
                onChange={(e) => handler.handleSetAvailableSeatsHarmonizeMethodBR(onlyNumbers(e.target.value))}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizeBRNextClassDateText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="date"
                width={px2vw(500)}
                value={handler.nextClassHarmonizeMethodBR?.substring(0, 10)}
                onChange={(e) => handler.handleSetNextClassHarmonizeMethodBR(e.target.value + " 09:00:00")}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminHarmonizeBRSchedulePDFURLText')}</Text>
            </Row>
            <Row width={px2vw(500)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFHarmonizeMethodBR}
                onChange={(e) => handler.handleSetScheduleDocURLPDFHarmonizeMethodBR(e.target.value)}
              />
            </Row>
          </Row>
          <Row margtop={px2vw(50)}>
            <SaveButton 
              width={px2vw(315)}
              height={px2vw(35)}
              borderradius={px2vw(30)}
              onClick={() => handler.handleSaveData()}
            >
              <Text color={white}>{getResource('adminSaveButtonLabelText')}</Text>
            </SaveButton>  
          </Row>
        </SaveBox>
      </Row>
    </Col>
  );
}
