import React from 'react';

import { gray } from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';
import { Image, ImageBox, Text } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

export default function FooterMobile({ country, isAtFellow = false }) {
  return (
    <Col height="auto" padtop={px2vw(30)} padbottom={px2vw(60)} boxshadow={`0px -7px 9px -4px ${gray}`}>
      <Row height="auto" padtop={px2vw(50)}>
        <ImageBox width={px2vw(1100)} height={px2vw(180)}>
          <Image src={getResource('logoImageBlack')} />
        </ImageBox>
      </Row>
      {isAtFellow && (
        <Row alignCenter margtop={px2vw(90)}>
          <ImageBox width={px2vw(460)} height={px2vw(185)}>
            <Image src={getResource('fellowFacopImage')} />
          </ImageBox>
        </Row>
      )}
      <Row height="auto" margtop={px2vw(90)}>
        <ImageBox width={px2vw(200)} height={px2vw(200)} cursor="pointer" margright={px2vw(70)}>
          <a href={getResource(country, 'instagramTextLink', country)} target="_blank" rel="noreferrer">
            <Image src={getResource('instagramImage')} />
          </a>
        </ImageBox>
        <ImageBox width={px2vw(200)} height={px2vw(200)} cursor="pointer" margleft={px2vw(70)}>
          <a href={getResource(country, 'youtubeTextLink', country)} target="_blank" rel="noreferrer">
            <Image src={getResource('youtubeImage')} />
          </a>
        </ImageBox>
      </Row> 
      <Row width="auto" height="auto" justifyStart padleft={px2vw(90)} margtop={px2vw(120)}>
        <ImageBox width={px2vw(53)} height={px2vw(55)} margright={px2vw(32)}>
          <Image src={getResource('whatsappImage')} />
        </ImageBox> 
        <Text fontSize={px2vw(45)}>{getResource('whatsappText', country)}</Text> 
      </Row>
      <Row width="auto" justifyStart padleft={px2vw(90)} margtop={px2vw(50)}>
        <ImageBox width={px2vw(53)} height={px2vw(50)} margtop={px2vw(-5)} margright={px2vw(32)}>
          <Image src={getResource('mailImage')} />
        </ImageBox> 
        <Text fontSize={px2vw(45)}>{getResource('mailText', country)}</Text> 
      </Row>
      <Row width="auto" justifyStart padleft={px2vw(90)} margtop={px2vw(50)}>
        <Row width="auto">
          <ImageBox width={px2vw(50)} height={px2vw(57)} margtop={px2vw(-2)} margleft={px2vw(3)} margright={px2vw(35)}>
            <Image src={getResource('addressImage')} />
          </ImageBox> 
        </Row>
        <Row width="80%" justifyStart>
          <Text fontSize={px2vw(45)}>{getResource('addressText', country)}</Text>
        </Row>
      </Row>
    </Col>
  )
}
