import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { 
  Image, ImageBox, Text, LoginBox, LoginInput, 
  LoginButton, SeePasswordButton, SeePasswordImage  
} from '../../../../styles/common';

import { getResource } from '../../../../utils/constants';
import px2vw from "../../../../utils/responsiveness/px2vw";

import { home } from "../../../../routes/routeMap";

export default function AdminLoginWeb({ handler }) {
  return (
    <Col alignCenter padtop={px2vw(80)}>
      <ImageBox width={px2vw(420)} height={px2vw(60)} margbottom={px2vw(40)}>
        <a href={home}>
          <Image src={getResource('logoImageBlack')} />
        </a>
      </ImageBox>
      <LoginBox>
        <Col alignStart margleft={px2vw(90)}>
          <Text margbottom={px2vw(5)}>{getResource('adminLoginUserLabelText')}</Text>
          <LoginInput  
            name="user"
            width={px2vw(300)} 
            margbottom={px2vw(20)} 
            type="text"
            value={handler.user}
            onChange={(e) => handler.setUser(e.target.value)}
          />
          <Text margbottom={px2vw(5)}>{getResource('adminLoginPwdLabelText')}</Text>
          <Row justifyStart margbottom={px2vw(30)} height="auto">
            <LoginInput 
              name="pwd"
              width={px2vw(300)} 
              type={handler.passwordVisible ? "text" : "password"}
              value={handler.pwd}
              onChange={(e) => handler.setPwd(e.target.value)}
            />
            <SeePasswordButton
              type="button"
              onClick={() => handler.setPasswordVisible(!handler.passwordVisible)}
            >
              <SeePasswordImage
                src={getResource(handler.passwordVisible ? 'adminLoginSeePasswordImage' : 'adminLoginNoSeePasswordImage')}
              />
            </SeePasswordButton>
          </Row>
          <LoginButton 
            width={px2vw(315)}
            height={px2vw(35)}
            borderradius={px2vw(30)}
            onClick={() => handler.submitLogin()}
          >
            <Text color={white}>{getResource('adminLoginButtonLabelText')}</Text>
          </LoginButton>  
        </Col>
      </LoginBox>
    </Col>
  );
}
