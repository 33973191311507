import styled from 'styled-components';

import { Input } from '@rocketseat/unform';

import px2vw from '../utils/responsiveness/px2vw';
import { 
  black, gray, gray1, gray2, gray3, 
  gray4, gray5, red1, red2, red3, 
  red4, white, yellow, yellow2, yellow3
} from './colors';

export const Text = styled.div`
  font-family: ${(props) => props.font === 'overlock' ? 'OverlockRegular' : 'InterRegular'};
  letter-spacing: 0.36px;
  color: ${(props) => props.color ? props.color : black};
  width: ${(props) => props.width};
  margin-top: ${(props) => props.margtop};
  margin-left: ${(props) => props.margleft};
  margin-right: ${(props) => props.margright};
  margin-bottom: ${(props) => props.margbottom};
  font-size: ${(props) => props.fontSize ? props.fontSize : px2vw(12)};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : 'normal'};
  z-index: ${(props) => props.zIndex};
  cursor: ${(props) => props.cursor};

  ${(props) => props.fontWeight === 'bold' && `
    font-family: ${props.font === 'overlock' ? 'OverlockBold' : 'InterBold'};
  `}

  ${(props) => props.textAlign && `
    text-align: center;
  `}

  ${(props) => props.gradient && `
    background: ${props.gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `};

  ${(props) => props.underline && `
    text-decoration: underline;
  `};

  ${(props) => props.lineThrough && `
    text-decoration: line-through;
    text-decoration-color: ${red1};
    text-decoration-thickness: 5px;
  `};
  
  .bold {
    font-weight: bold;
    font-family: ${(props) => props.font === 'overlock' ? 'OverlockBold' : 'InterBold'};
  }

  .red {
    color: ${red1};
  }

  .yellow {
    color: ${yellow3};
  }

  .harmonizeText {
    font-size: ${px2vw(28)};
  }

  .fellowText {
    font-size: ${px2vw(33)};
    font-weight: bold;
    font-family: OverlockBlackItalic;
  }

  .fellowMobileText {
    font-size: ${px2vw(53)};
    font-weight: bold;
    font-family: OverlockBlackItalic;
  }

  .fellowTextGratient {
    background: linear-gradient(${yellow}, ${yellow2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .harmonizeMobileText {
    font-size: ${px2vw(88)};
  }

  .newAmountText {
    font-size: ${px2vw(33)};
  }

  .newAmountMobileText {
    font-size: ${px2vw(80)};
  }

  .blackFridayText {
    font-size: ${px2vw(25)};
    text-align: center;
  }

  .blackFridayMobileText {
    font-size: ${px2vw(70)};
  }

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const ImageBox = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: ${(props) => props.cursor ? props.cursor : 'default'};
  margin-top: ${(props) => props.margtop ? props.margtop : 0};
  margin-bottom: ${(props) => props.margbottom ? props.margbottom : 0};
  margin-right: ${(props) => props.margright ? props.margright : 0};
  margin-left: ${(props) => props.margleft ? props.margleft : 0};
  box-shadow: ${(props) => props.boxshadow};
  background: ${(props) => props.background};
  background-image: ${(props) => `url(${props.backgroundImage})`};
  z-index: ${(props) => props.zIndex};
  position: ${(props) => props.position};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;

  ${(props) => props.hasInnerShadow && `
    z-index: -2; 
    position: relative;
  `};

  ${(props) => props.isInvisible && `
    display: none;
  `};
`;

export const NavPageButton = styled.button`
  background-image: linear-gradient(${red1}, ${red2});
  width: ${(props) => props.width ? props.width : px2vw(220)};
  height: ${(props) => props.height ? props.height : px2vw(45)};
  border: none;
  border-radius: ${(props) => props.borderradius ? props.borderradius : px2vw(10)};
  cursor: pointer;

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const TalkToExpertButton = styled.button`
  width: ${(props) => props.width ? props.width : px2vw(220)};
  height: ${(props) => props.height ? props.height : px2vw(45)};
  border: ${(props) => props.noBorder ? 'none' : `1px solid ${gray1}`};
  border-radius: ${(props) => props.borderradius ? props.borderradius : px2vw(50)};
  cursor: pointer;
  margin-left: ${(props) => props.margleft ? props.margleft : 0};
  margin-top: ${(props) => props.margtop ? props.margtop : 0};
  margin-bottom: ${(props) => props.margbottom ? props.margbottom : 0};
  z-index: ${(props) => props.zIndex};

  ${(props) => props.harmonize && `
    background-image: linear-gradient(${red1}, ${red2});
    border: none;
  `};
  
  ${(props) => props.harmonize && props.inactive && `
    background: ${black};
    border: 1px solid ${red1};
  `};

  ${(props) => props.fellow && `
    background-image: linear-gradient(${yellow}, ${yellow2});
    border: none;
  `};

  ${(props) => props.fellow && props.inactive && `
    background-image: linear-gradient(${white}, ${gray});
    border: none;
  `};

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const Timeline = styled.div`
  margin-left: ${px2vw(5)};
  width: ${px2vw(1.3)};
  height: ${(props) => props.height ? props.height : px2vw(300)};
  background: ${black};
`;

export const TimelineCircle = styled.div`
  border-radius: 50%;
  width: ${px2vw(11)};
  height: ${px2vw(11)};
  background: ${black};
`;

export const RouletteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: ${(props) => props.isMobile ? 'column': 'row'};
`;

export const RouletteContent = styled.div`
  position: relative;
  width: ${(props) => props.isMobile ? px2vw(1300) : px2vw(400)};
  height: ${(props) => props.isMobile ? px2vw(1300) : px2vw(400)};
`;

export const RouletteButton = styled.button`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 999;
  cursor: pointer;
  border: none;
  background: transparent;
`;
  
export const RouletteItemBox = styled.div`
  background-image: linear-gradient(${red3}, ${red4});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: ${(props) => props.margleft};
  border-radius: 20px;

  ${(props) => props.isMobile ? `
    padding-top: ${props.padding};
    padding-left: ${props.padding};
    padding-right: ${props.padding};
    padding-bottom: ${px2vw(20)};
  ` : `
    padding: ${props.padding};
  `};
`;

export const ReasonsBox = styled.div`
  width: ${(props) => props.fellow ? (props.isMobile ? px2vw(1100) : px2vw(250)) : (props.isMobile ? px2vw(1100) : px2vw(220))};
  height: ${(props) => props.fellow ? (props.isMobile ? px2vw(500) : px2vw(130)) : (props.isMobile ? px2vw(500) : px2vw(100))};
  border: 4px solid ${(props) => props.fellow ? yellow3 : red4};
  border-radius: 20px;
  margin-left: ${(props) => props.fellow ? props.margleft : 0};
  margin-right: ${(props) => props.fellow ? (props.isMobile ? 0 : px2vw(40)) : (props.isMobile ? 0 : px2vw(20))};
  margin-bottom: ${(props) => props.fellow ? (props.isMobile ? px2vw(50) : px2vw(40)) : (props.isMobile ? px2vw(50) : px2vw(20))};
  padding-left: ${(props) => props.fellow ? (props.isMobile ? px2vw(50) : px2vw(20)) : (props.isMobile ? px2vw(50) : px2vw(10))};
  padding-right: ${(props) => props.fellow ? (props.isMobile ? px2vw(50) : px2vw(20)) : (props.isMobile ? px2vw(50) : px2vw(10))};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${(props) => props.fellow && `
    box-shadow: 0px 10px 10px 0px ${gray};
  `};
`;

export const HarmonizeFooterBox = styled.div`
  background-image: ${(props) => props.isMobile ? `linear-gradient(${red1}, ${red2}, ${black})` : `linear-gradient(45deg, ${red1}, ${red2}, ${black})`};
  width: 100%;
  height: ${(props) => props.isMobile ? px2vw(600) : px2vw(100)};
  margin-top: ${(props) => props.isMobile ? px2vw(80) : px2vw(40)};
`;

export const TalkToExpertFormOverlay = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${gray2}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const TalkToExpertFormContainer = styled.div`
  width: ${(props) => props.isMobile ? '95%' : px2vw(453)};
  height: ${(props) => props.isMobile ? '95%' : '95%'};
  background-color: ${white};
  border: none;
  border-radius: ${(props) => props.isMobile ? '25px': '50px'};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `};
`;

export const TalkToExpertFormHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  height: auto;
  flex-direction: ${(props) => props.isMobile ? 'column': 'row'};
  background-image: ${(props) => props.bgColor ? props.bgColor : `linear-gradient(${red1}, ${red2})`};
  padding: ${(props) => props.isMobile ? px2vw(40) : px2vw(20)};
  border-top-left-radius: ${(props) => props.isMobile ? '25px': '50px'};
  border-top-right-radius: ${(props) => props.isMobile ? '25px': '50px'};
`;

export const TalkToExpertFormInput = styled(Input)`
  padding: 0.5em;
  border: 1px solid ${gray};
  border-radius: ${(props) => props.isMobile ? px2vw(15) : px2vw(5)};
  font-size: ${(props) => props.isMobile ? px2vw(60) : px2vw(14)};
  font-family: InterRegular;
  letter-spacing: 0.36px;
  margin-top: ${(props) => props.isMobile ? px2vw(16) : px2vw(8)};
  width: ${(props) => props.width ? props.width : props.isMobile ? '83%' : '79%'};
  height: ${(props) => props.isMobile ? px2vw(100) : px2vw(20)};

  :focus {
    border: 1px solid ${gray3} !important;
    box-shadow: none !important;
  }
`;

export const TalkToExpertFormSelect = styled.select`
  padding: 0.5em;
  border: 1px solid ${gray};
  border-radius: ${(props) => props.isMobile ? px2vw(15) : px2vw(5)};
  height: ${(props) => props.isMobile ? px2vw(170) : px2vw(40)};
  width: ${(props) => props.isMobile ? '88%' : '82.4%'};
  margin-top: ${(props) => props.isMobile ? px2vw(16) : px2vw(8)};
  font-family: InterRegular;
  font-size: ${(props) => props.isMobile ? px2vw(60) : px2vw(14)};
  line-height: 130%;
  letter-spacing: 0.36px;
`;

export const TimelineBox = styled.div`
  width: ${(props) => props.isMobile ? px2vw(1150) : px2vw(620)};
  height: auto;
  padding: ${(props) => props.isMobile ? px2vw(60) : px2vw(30)};
  border: none;
  border-radius: ${(props) => props.isMobile ? px2vw(70) : px2vw(5)};
  box-shadow: 6px 6px 10px -1px ${gray};
`;

export const ModuleDateBox = styled.div`
  width: ${(props) => props.isMobile ? px2vw(260) : px2vw(90)};
  height: ${(props) => props.isMobile ? px2vw(140) : px2vw(60)};
  padding: ${(props) => props.isMobile ? '0.5em' : '1em'};
  background-color: ${gray4};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.isMobile ? px2vw(30) : px2vw(10)};
`;

export const FellowFooterBox = styled.div`
  background-image: linear-gradient(${yellow}, ${yellow3}, ${yellow2});
  width: 100%;
  height: ${(props) => props.isMobile ? px2vw(600) : px2vw(100)};
  margin-top: ${(props) => props.isMobile ? px2vw(80) : px2vw(40)};
`;

export const LoginBox = styled.div`
  width: ${(props) => props.isMobile ? "80%" : px2vw(500)};
  height: ${(props) => props.isMobile ? px2vw(830) : px2vw(300)};
  box-shadow: 0px 10px 10px 0px ${gray};
`;

export const LoginInput = styled.input`
  padding: 10px 10px !important;
  margin: 0 !important;
  border: 1px solid ${gray2} !important;
  border-radius: 5px !important;
  font-size: ${(props) => props.isMobile ? px2vw(45) : px2vw(16)} !important;
  font-family: font-family: InterRegular !important;

  ::placeholder {
    color: ${gray3} !important;
  }

  :focus {
    border: 1px solid ${gray3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-bottom: ${props.margbottom} !important;
  `};
`;

export const SeePasswordButton = styled.button`
  margin-left: ${(props) => props.isMobile ? px2vw(-100) : px2vw(-28)};
  margin-top: ${(props) => props.isMobile ? px2vw(23) : px2vw(7)};
  width: ${(props) => props.isMobile ? px2vw(60) : px2vw(18)};
  height: ${(props) => props.isMobile ? px2vw(60) : px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;
  cursor: pointer;

  :focus {
    background-color: transparent !important;
  }
`;

export const SeePasswordImage = styled.img`
  width: ${(props) => props.isMobile ? px2vw(60) : px2vw(18)};
  height: ${(props) => props.isMobile ? px2vw(60) : px2vw(18)};
  cursor: pointer;
`;

export const LoginButton = styled.button`
  background-color: ${gray1};
  border: 1px solid ${gray1};
  padding: 0.3em;
  cursor: pointer;

  :focus {
    background-color: ${gray1} !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    height: ${props.height} !important;
    margin-bottom: ${props.margbottom} !important;
    border-radius: ${props.borderradius} !important;
  `};
`;

export const AdminHeaderButtons = styled.button`
  height: ${(props) => props.isMobile ? px2vw(100) : px2vw(40)};
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-right: ${(props) => props.margright ? props.margright : px2vw(5)};
  padding-left: ${(props) => props.isMobile ? px2vw(40) : px2vw(20)};
  padding-right: ${(props) => props.isMobile ? px2vw(40) : px2vw(20)};

  :hover {
    background-color: ${gray5};
    border-radius: ${(props) => props.borderradius} !important;
  }

  ${(props) => props.active && `
    background-color: ${gray5};
    border-radius: ${props.borderradius} !important;
  `};

  ${(props) => props.isMobile && `
    margin-top: ${props.margtop ? props.margtop : px2vw(60)};
  `};
`;

export const SaveBox = styled.div`
  width: ${(props) => props.isMobile ? "80%" : "auto"};
  height: "auto";
  padding: ${(props) => props.isMobile ? px2vw(80) : px2vw(40)};
  box-shadow: 0px 10px 10px 0px ${gray};
`;

export const SaveInput = styled.input`
  padding: 10px 10px !important;
  margin: 0 !important;
  border: 1px solid ${gray2} !important;
  border-radius: 5px !important;
  font-size: ${(props) => props.isMobile ? px2vw(45) : px2vw(16)} !important;
  font-family: font-family: InterRegular !important;

  ::placeholder {
    color: ${gray3} !important;
  }

  :focus {
    border: 1px solid ${gray3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-bottom: ${props.margbottom} !important;
    margin-left: ${props.margleft} !important;
    margin-right: ${props.margright} !important;
  `};

  ${(props) => props.type === 'checkbox' && `
    transform: ${props.isMobile ? 'scale(1.3)' : 'scale(2)'};
  `};
`;

export const SaveSelect = styled.select`
  padding: 10px 10px !important;
  margin-left: ${px2vw(5)};
  margin-right: ${px2vw(10)};
  border: 1px solid ${gray2} !important;
  border-radius: 5px !important;
  font-size: ${(props) => props.isMobile ? px2vw(45) : px2vw(16)} !important;
  font-family: font-family: InterRegular !important;

  ${(props) => props.isMobile && `
    margin-top: ${px2vw(30)};
  `};
`;

export const SaveButton = styled.button`
  background-color: ${gray1};
  border: 1px solid ${gray1};
  padding: 0.3em;
  cursor: pointer;

  :focus {
    background-color: ${gray1} !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    height: ${props.height} !important;
    margin-bottom: ${props.margbottom} !important;
    border-radius: ${props.borderradius} !important;
  `};
`;
