import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { ImageBox, Image, SaveBox, SaveInput, SaveButton, Text, SaveSelect } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';
import { onlyNumbers } from "../../../../utils/utils";

import Header from "../../../../components/Admin/Header";

import { fellowSpecialization } from "../../../../routes/routeMap";

export default function AdminFellowWeb({ handler }) {
  return (
    <Col alignCenter padtop={px2vw(80)}>
      <Header handler={handler} />
      <Row margtop={px2vw(40)}>
        <SaveBox>
          <Row alignCenter spaceBetween>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminFellowIsWaitingListActiveText')}</Text>
            </Row>
            <Row width={px2vw(209)} justifyStart>
              <SaveInput
                type="checkbox"
                width={px2vw(500)}
                checked={handler.fellowSpecializationWaiting}
                onClick={() => handler.handleSetFellowSpecializationWaiting(!handler.fellowSpecializationWaiting)}
              />
            </Row>
            <ImageBox width={px2vw(250)} height={px2vw(75)} margtop={px2vw(-30)}>
              <a href={fellowSpecialization} target="_blank" rel="noreferrer">
                <Image src={getResource('adminHomeFellowImage')} />
              </a>
            </ImageBox>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminFellowAvailableSeatsText')}</Text>
            </Row>
            <Row width={px2vw(509)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.availableSeatsFellowSpecialization}
                onChange={(e) => handler.handleSetAvailableSeatsFellowSpecialization(onlyNumbers(e.target.value))}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminFellowNextClassDateText')}</Text>
            </Row>
            <Row width={px2vw(509)} justifyStart>
              <SaveInput
                type="date"
                width={px2vw(500)}
                value={handler.nextClassFellowSpecialization?.substring(0, 10)}
                onChange={(e) => handler.handleSetNextClassFellowSpecialization(e.target.value + " 09:00:00")}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(20)}>
            <Row width={px2vw(210)} justifyStart>
              <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource('adminFellowSchedulePDFURLText')}</Text>
            </Row>
            <Row width={px2vw(509)} justifyStart>
              <SaveInput
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFFellowSpecialization}
                onChange={(e) => handler.handleSetScheduleDocURLPDFFellowSpecialization(e.target.value)}
              />
            </Row>
          </Row>
          {handler.moduleDatesFellowSpecialization.map((_, idx) => {
            return (
              <Row key={`module-${idx}`} alignCenter margtop={px2vw(20)}>
                <Row width={px2vw(210)} justifyStart>
                  <Text fontSize={px2vw(17)} margright={px2vw(30)}>{getResource(`adminFellowModule${idx+1}DateText`)}</Text>
                </Row>
                <Row width={px2vw(509)} justifyStart>
                  <Text fontSize={px2vw(15)}>
                    Dia 1: 
                    <SaveSelect 
                      value={handler.moduleDatesFellowSpecialization[idx].val.day_1}
                      onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.day_1 = e.target.value}
                    >
                      {handler.selectModulesDays.map((day) => {
                        return (
                          <option value={`${day}`}>
                            {day}
                          </option>
                        )
                      })}
                    </SaveSelect>
                    Dia 2: 
                    <SaveSelect
                      value={handler.moduleDatesFellowSpecialization[idx].val.day_2}
                      onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.day_2 = e.target.value}
                    >
                      {handler.selectModulesDays.map((day) => {
                        return (
                          <option value={`${day}`}>
                            {day}
                          </option>
                        )
                      })}
                    </SaveSelect>
                    Mês: 
                    <SaveSelect
                      value={handler.moduleDatesFellowSpecialization[idx].val.month}
                      onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.month = e.target.value}
                    >
                      <option value="JANEIRO">JANEIRO</option>
                      <option value="FEVEREIRO">FEVEREIRO</option>
                      <option value="MARÇO">MARÇO</option>
                      <option value="ABRIL">ABRIL</option>
                      <option value="MAIO">MAIO</option>
                      <option value="JUNHO">JUNHO</option>
                      <option value="JULHO">JULHO</option>
                      <option value="AGOSTO">AGOSTO</option>
                      <option value="SETEMBRO">SETEMBRO</option>
                      <option value="OUTUBRO">OUTUBRO</option>
                      <option value="NOVEMBRO">NOVEMBRO</option>
                      <option value="DEZEMBRO">DEZEMBRO</option>
                    </SaveSelect>
                    Ano: 
                    <SaveSelect
                      value={handler.moduleDatesFellowSpecialization[idx].val.year}
                      onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.year = e.target.value}
                    >
                      {handler.selectModulesYears.map((year) => {
                        return (
                          <option value={`${year}`}>
                            {year}
                          </option>
                        )
                      })}
                    </SaveSelect>
                  </Text>
                </Row>
              </Row> 
            );
          })}  
          <Row margtop={px2vw(50)}>
            <SaveButton 
              width={px2vw(315)}
              height={px2vw(35)}
              borderradius={px2vw(30)}
              onClick={() => handler.handleSaveData()}
            >
              <Text color={white}>{getResource('adminSaveButtonLabelText')}</Text>
            </SaveButton>  
          </Row>
        </SaveBox>
      </Row>
    </Col>
  );
}
