import React, { useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Row } from '../../styles/grid';
import { 
  Text, Image, ImageBox, TalkToExpertFormOverlay, TalkToExpertFormContainer, 
  TalkToExpertFormHeader, TalkToExpertFormInput, TalkToExpertButton,
  TalkToExpertFormSelect
} from '../../styles/common';
import { white } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';
import { getResource } from '../../utils/constants';
import { validateEmail, dddPhoneMask } from '../../utils/utils';

export default function TalkToExpertForm({ handler, bgColor, isVisible = false, isMobile = false, rdEventType = null, isAtHarmonize = false, isAtFellow = false }) {
  const sendRDEvent = process.env.REACT_APP_SEND_RD_EVENT === 'true';
  const closeImageSize = isMobile ? px2vw(80) : px2vw(20);
  const inputLabelTextSize = isMobile ? px2vw(50) : px2vw(11);
  const inputMargtopSize = isMobile ? px2vw(60) : px2vw(20);
  const successImageSize = isMobile ? px2vw(500) : px2vw(100);
  const graduationList = [
    'Selecione',
    'Biomedicina',
    'Enfermagem',
    'Farmácia',
    'Odontologia',
    'Medicina',
  ]
  const [formSuccessSent, setFormSuccessSent] = useState(false);
  const [talkToExpertFormName, setTalkToExpertFormName] = useState('');
  const [talkToExpertFormEmail, setTalkToExpertFormEmail] = useState('');
  const [talkToExpertFormWhatsapp, setTalkToExpertFormWhatsapp] = useState('');
  const [talkToExpertFormGraduation, setTalkToExpertFormGraduation] = useState('Selecione');

  function clearFormFields() {
    setTalkToExpertFormName('');
    setTalkToExpertFormEmail('');
    setTalkToExpertFormWhatsapp('');
    setTalkToExpertFormGraduation('');
  }

  function handleCloseForm() {
    clearFormFields();
    handler.handleSetIsTalkToExpertFormOpen(false);
  }

  function sendWhatsappLeadData() {
    // Send data to team whatsapp
    let learnMoreText
    if (rdEventType?.includes(getResource('rdEventTalkToExpertBR'))) {
      learnMoreText = 'os cursos no *Brasil*.'
    } else if (rdEventType?.includes(getResource('rdEventTalkToExpertPT'))) {
      learnMoreText = 'os cursos em *Portugal*.'
    } else if (rdEventType?.includes(getResource('rdEventTHarmonizeMethodBR'))) {
      learnMoreText = 'o curso *Método Harmonize Brasil*'
    } else if (rdEventType?.includes(getResource('rdEventTHarmonizeMethodPT'))) {
      learnMoreText = 'o curso *Método Harmonize Portugal*'
    } else if (rdEventType?.includes(getResource('rdEventFellowBR'))) {
      learnMoreText = 'o curso *Especialização Fellow Brasil*'
    } else {
      learnMoreText = 'os cursos.'
    }

    const whatsappMessage = encodeURI(`Vim do site. 
    
Me chamo *${talkToExpertFormName.trim()}*, formação em *${talkToExpertFormGraduation}*.

Gostaria de saber mais sobre ${learnMoreText}`);
    
    const expertWhatsappPhone = '5521998422821';

    // Inside a timeout to avoid IOS popup blocker with async calls
    setTimeout(() => {
      if (isMobile) {
        window.open(`whatsapp://send?phone=${expertWhatsappPhone}&text=${whatsappMessage}`, '_blank');
      } else {
        window.open(`https://wa.me/${expertWhatsappPhone}?text=${whatsappMessage}`, '_blank');
      }
    })

    clearFormFields();
  }

  function submitContactData() {
    if (talkToExpertFormName.trim() === "" || talkToExpertFormEmail.trim() === "" || talkToExpertFormWhatsapp.trim() === "" || talkToExpertFormGraduation.trim() === "") {
      toast.error("Todos os campos são obrigatórios!", {
        autoClose: 6000,
      });
    } else if (!validateEmail(talkToExpertFormEmail.trim())) {
      toast.error("Por gentileza informar um email válido!", {
        autoClose: 6000,
      });
    } else if (
      (handler.country === 'br' && talkToExpertFormWhatsapp.length < 14) || 
      (handler.country === 'pt' && talkToExpertFormWhatsapp.length < 11)
    ) {
      toast.error("Por gentileza informe um número de telefone válido!", {
        autoClose: 6000,
      });
    } else if (talkToExpertFormGraduation === 'Selecione') {
      toast.error("Por gentileza informe a sua formação!", {
        autoClose: 6000,
      });
    } else {
      const rdObjectData = {
        "event_type": "CONVERSION",
        "event_family": "CDP",
        "payload": {
          "conversion_identifier": rdEventType,
          "name": talkToExpertFormName.trim(),
          "email": talkToExpertFormEmail.trim(),
          "mobile_phone": (handler.country === 'br' ? '55' : '351') + talkToExpertFormWhatsapp,
          "job_title": talkToExpertFormGraduation
        }
      }

      if (sendRDEvent) {
        axios.post("https://api.rd.services/platform/conversions?api_key=duQVDDgPKzZlJvgaTHvSBBpAHfhOfOBGVtnl", rdObjectData)
        .then(() => {
          setFormSuccessSent(true);
        })
        .catch((error) => {
          console.log('Error sending rd convertion event: ', error);
          toast.error("Ocorreu um erro ao enviar a sua inscrição. Tente novamente mais tarde!", {
            autoClose: 6000,
          });
        })
        .finally(() => {
          sendWhatsappLeadData();
        })
      } else {
        console.log('[DEBUG MODE] RD Event: ', rdObjectData);
        setFormSuccessSent(true);
        sendWhatsappLeadData();
      }
    }
  }

  return (
    <TalkToExpertFormOverlay isVisible={isVisible}>
      {!formSuccessSent && (
        <TalkToExpertFormContainer isMobile={isMobile}>
          <TalkToExpertFormHeader bgColor={bgColor} isMobile={isMobile}>
            <ImageBox width={closeImageSize} height={closeImageSize} cursor="pointer" onClick={() => handleCloseForm()} margtop={px2vw(10)}>
              <Image src={getResource('closeFormImage')}></Image>
            </ImageBox>
            <Col margleft={isMobile ? px2vw(15) : px2vw(20)} margtop={isMobile ? px2vw(50) : '0'}>
              <Text width={isMobile ? px2vw(1200) : px2vw(370)} color={white} fontSize={isMobile ? px2vw(60) : px2vw(20)} fontWeight="bold" textAlign>{getResource('talkToExpertFormHeaderText1', handler.country)}</Text>
              <Text color={white} margtop={isMobile ? px2vw(30) : px2vw(15)} textAlign={!isMobile} fontSize={isMobile ? px2vw(48) : px2vw(12)}>{getResource('talkToExpertFormHeaderText2', handler.country)}</Text>
            </Col>
          </TalkToExpertFormHeader>
          <Col justifyStart height="auto" pad={isMobile ? px2vw(80) : px2vw(40)} margtop={px2vw(-15)}>
            <Col alignStart>
              <Text margleft={px2vw(5)} fontWeight="bold" fontSize={inputLabelTextSize}>{getResource('talkToExpertFormNameText', handler.country)}</Text>
              <TalkToExpertFormInput
                isMobile={isMobile}
                name="talkToExpertFormName"
                value={talkToExpertFormName}
                maxLength={200}
                onChange={(t) => setTalkToExpertFormName(t.target.value)}
              />
            </Col>
            <Col alignStart margtop={inputMargtopSize}>
              <Text margleft={px2vw(5)} fontWeight="bold" fontSize={inputLabelTextSize}>{getResource('talkToExpertFormEmailText', handler.country)}</Text>
              <TalkToExpertFormInput
                isMobile={isMobile}
                name="talkToExpertFormEmail"
                value={talkToExpertFormEmail}
                maxLength={200}
                onChange={(t) => setTalkToExpertFormEmail(t.target.value)}
              />
            </Col>
            <Col alignStart margtop={inputMargtopSize}>
              <Text margleft={px2vw(5)} fontWeight="bold" fontSize={inputLabelTextSize}>{getResource('talkToExpertFormWhatsappText', handler.country)}</Text>
              <Row width={isMobile ? "88%" : handler.country === 'pt' ? "84%" : "85%"} alignCenter>
                <Text 
                  margright={isMobile ? px2vw(40) : px2vw(12)} 
                  margtop={px2vw(5)} 
                  fontSize={isMobile ? px2vw(60) : px2vw(15)}
                >
                  {getResource('talkToExpertFormWhatsappCountryCodeText', handler.country)}
                </Text>
                <TalkToExpertFormInput
                  isMobile={isMobile}
                  name="talkToExpertFormWhatsapp"
                  value={talkToExpertFormWhatsapp}
                  maxLength={handler.country === 'br' ? 15 : 11}
                  onChange={(t) => setTalkToExpertFormWhatsapp(dddPhoneMask(t.target.value, handler.country))}
                />
              </Row>
            </Col>
            <Col alignStart margtop={inputMargtopSize}>
              <Text margleft={px2vw(5)} fontWeight="bold" fontSize={inputLabelTextSize}>{getResource('talkToExpertFormGraduationText', handler.country)}</Text>
              <TalkToExpertFormSelect
                isMobile={isMobile}
                name="talkToExpertFormGraduation"
                value={talkToExpertFormGraduation}
                onChange={(t) => {setTalkToExpertFormGraduation(t.target.value)}}
              >
                {graduationList.map((graduation, idx) => {
                  return (
                    <option key={`graduationList${idx}`} value={graduation}>
                      {graduation}
                    </option>
                  );
                })}
              </TalkToExpertFormSelect>
            </Col>
            <Text width="79%" margtop={isMobile ? px2vw(70) : px2vw(15)} fontSize={isMobile ? px2vw(40) : px2vw(12)}>{getResource('talkToExpertFormText1', handler.country)}</Text>
            <TalkToExpertButton width={isMobile ? "87%" : "82.5%"} height={isMobile ? px2vw(150) : px2vw(45)} 
              margtop={isMobile ? px2vw(70) : px2vw(30)} 
              harmonize={isAtHarmonize} 
              fellow={isAtFellow} 
              borderradius={isMobile ? px2vw(100) : px2vw(50)}
              onClick={() => submitContactData()} 
            >
              <Text color={white} fontWeight="bold" fontSize={isMobile ? px2vw(60) : px2vw(15)}>{getResource('talkToExpertFormButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Col>
        </TalkToExpertFormContainer>
      )}
      {formSuccessSent && (
        <TalkToExpertFormContainer width={isMobile ? '95%' : px2vw(353)} height={isMobile ? px2vw(1200): px2vw(300)}>
          <Col justifyCenter alignCenter>
            <ImageBox width={successImageSize} height={successImageSize} margtop={px2vw(10)}>
              <Image src={getResource('successFormImage')}></Image>
            </ImageBox>
            <Text width={isMobile ? px2vw(1000) : px2vw(280)} margtop={isMobile ? px2vw(70) : px2vw(30)} fontWeight="bold" textAlign fontSize={isMobile ? px2vw(50) : px2vw(12)}>{getResource('talkToExpertSuccessText1', handler.country)}</Text>
            <TalkToExpertButton width={isMobile ? "87%" : "82.5%"} height={isMobile ? px2vw(150) : px2vw(45)}
              margtop={isMobile ? px2vw(70) : px2vw(30)} 
              harmonize={isAtHarmonize} 
              fellow={isAtFellow} 
              borderradius={isMobile ? px2vw(100) : px2vw(50)}
              onClick={() => {
                setFormSuccessSent(false);
                handleCloseForm();
              }}
            >
              <Text color={white} fontWeight="bold" fontSize={isMobile ? px2vw(60) : px2vw(15)}>{getResource('talkToExpertSuccessButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Col>
        </TalkToExpertFormContainer>
      )}
    </TalkToExpertFormOverlay>
  )
}
