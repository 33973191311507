import React from "react";

import FooterWeb from "../../components/Footer/Web";
import FooterMobile from "../../components/Footer/Mobile";

export default function Footer({ handler, isAtFellow = false }) {
  if (handler.isWeb) {
    return <FooterWeb country={handler.country} isAtFellow={isAtFellow} />
  } else {
    return <FooterMobile country={handler.country} isAtFellow={isAtFellow} />
  }
}
