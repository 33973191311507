import React from "react";

import HomeWeb from './Web';
import HomeMobile from './Mobile';

export default function Home({ handler }) {
  if (handler.isWeb) {
    return <HomeWeb country={handler.country} />
  } else {
    return <HomeMobile country={handler.country} />
  }
}
