import React from 'react';

import { black } from '../../styles/colors';
import { Row, Col } from '../../styles/grid';
import { Text } from '../../styles/common';

import px2vw from '../../utils/responsiveness/px2vw';
import { getResource } from '../../utils/constants';
import { leadingZeros } from '../../utils/utils';

export default function ClassTimer({ timer, textColor = black, isMobile = false }) { 
  const fontSizeNumber = isMobile ? px2vw(160) : px2vw(30);
  const fontSizeExplain = isMobile ? px2vw(50) : px2vw(9);
  const margRightNumbers = isMobile ? px2vw(50) : px2vw(10); 

  return (
    <Row width="auto">
      <Col width="auto" margright={margRightNumbers} alignCenter>
        <Text fontSize={fontSizeNumber} fontWeight="bold" color={textColor}>{leadingZeros(timer.days)}</Text>
        <Text fontSize={fontSizeExplain} color={textColor}>{`${getResource('headerTimerDayText')}${timer.days === 1 ? '' : 'S'}`}</Text>
      </Col>
      <Col width="auto" margright={margRightNumbers} alignCenter>
        <Text fontSize={fontSizeNumber} fontWeight="bold" color={textColor}>{leadingZeros(timer.hours)}</Text>
        <Text fontSize={fontSizeExplain} color={textColor}>{`${getResource('headerTimerHourText')}${timer.hours === 1 ? '' : 'S'}`}</Text>
      </Col>
      <Col width="auto" margright={margRightNumbers} alignCenter>
        <Text fontSize={fontSizeNumber} fontWeight="bold" color={textColor}>{leadingZeros(timer.minutes)}</Text>
        <Text fontSize={fontSizeExplain} color={textColor}>{`${getResource('headerTimerMinuteText')}${timer.minutes === 1 ? '' : 'S'}`}</Text>
      </Col>
      <Col width="auto" margright={margRightNumbers} alignCenter>
        <Text fontSize={fontSizeNumber} fontWeight="bold" color={textColor}>{leadingZeros(timer.seconds)}</Text>
        <Text fontSize={fontSizeExplain} color={textColor}>{getResource('headerTimerSecondText')}</Text>
      </Col>
    </Row>
  )
}
