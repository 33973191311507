export const white = '#FFFFFF';
export const black = '#000000';
export const gray = "#DBDBDB";
export const gray1 = "#3F3F3F";
export const gray2 = "#383539";
export const gray3 = "#A3A3A3";
export const gray4 = "#D9D9D970";
export const gray5 = "#e5e5e5";
export const red = "#FF5858";
export const red1 = "#F83636";
export const red2 = "#8E0707";
export const red3 = "#FC0116";
export const red4 = "#78000A";
export const yellow = "#F8CD36";
export const yellow2 = "#8E6007";
export const yellow3 = "#C2961E";