import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { ImageBox, Image, SaveBox, SaveInput, SaveButton, Text } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';
import { onlyNumbers } from "../../../../utils/utils";

import Header from "../../../../components/Admin/Header";

import { harmonizeMethod } from "../../../../routes/routeMap";

export default function AdminHarmonizeBRMobile({ handler }) {
  return (
    <Col alignCenter height="auto" padtop={px2vw(150)}>
      <Header handler={handler} />
      <Col margtop={px2vw(220)} alignCenter>
        <Row justifyCenter margbottom={px2vw(50)}>
          <ImageBox width={px2vw(900)} height={px2vw(300)} margtop={px2vw(-30)}>
            <a href={harmonizeMethod} target="_blank" rel="noreferrer">
              <Image src={getResource('adminHomeHarmonizeBRImage')} />
            </a>
          </ImageBox>
        </Row>
        <SaveBox isMobile>
          <Row alignCenter spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminHarmonizeBRAvailableSeatsText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="text"
                width={px2vw(500)}
                value={handler.availableSeatsHarmonizeMethodBR}
                onChange={(e) => handler.handleSetAvailableSeatsHarmonizeMethodBR(onlyNumbers(e.target.value))}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(50)} spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminHarmonizeBRNextClassDateText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="date"
                width={px2vw(500)}
                value={handler.nextClassHarmonizeMethodBR?.substring(0, 10)}
                onChange={(e) => handler.handleSetNextClassHarmonizeMethodBR(e.target.value + " 09:00:00")}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(50)} spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminHarmonizeBRSchedulePDFURLText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFHarmonizeMethodBR}
                onChange={(e) => handler.handleSetScheduleDocURLPDFHarmonizeMethodBR(e.target.value)}
              />
            </Row>
          </Row>
          <Row margtop={px2vw(100)}>
            <SaveButton
              width="100%"
              height={px2vw(130)}
              borderradius={px2vw(60)}
              onClick={() => handler.handleSaveData()}
            >
              <Text color={white} fontSize={px2vw(45)}>{getResource('adminSaveButtonLabelText')}</Text>
            </SaveButton>  
          </Row>
        </SaveBox>
      </Col>
    </Col>
  );
}
