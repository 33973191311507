/* ------------------------ Texts ------------------------ */
// RD Events
const rdEventsResources = {
  rdEventTalkToExpertBR: 'falar_com_especialista_brasil',
  rdEventTalkToExpertPT: 'falar_com_especialista_portugal',
  rdEventTHarmonizeMethodBR: 'metodo_harmonize_brasil',
  rdEventTHarmonizeMethodPT: 'metodo_harmonize_portugal',
  rdEventFellowBR: 'especializacao_brasil'
}
// Header
const headerTextsResources = {
  headerClassHarmonizeText1BR: 'GARANTA O SEU LUGAR,',
  headerClassHarmonizeText2BR: 'APENAS @@SEATS@@',
  headerClassHarmonizeText3BR: 'PARA PRÓXIMA TURMA',
  headerClassHarmonizeText1PT: 'GARANTA O SEU LUGAR,',
  headerClassHarmonizeText2PT: 'APENAS @@SEATS@@',
  headerClassHarmonizeText3PT: 'PARA PRÓXIMA TURMA',
  headerClassHarmonizeWaitingTextPT: <span>EM BREVE PRÓXIMA TURMA, <span className="bold">GARANTA O SEU LUGAR</span></span>,
  headerClassHarmonizeWaitingMobileTextPT: <span>EM BREVE PRÓXIMA TURMA,<br /><span className="bold">GARANTA O SEU LUGAR</span></span>,
  headerClassFellowText1BR: 'GARANTA O SEU LUGAR,',
  headerClassFellowText2BR: 'APENAS @@SEATS@@',
  headerClassFellowText3BR: 'PARA PRÓXIMA TURMA',
  headerClassFellowWaitingTextBR: <span>EM BREVE PRÓXIMA TURMA, <span className="bold">GARANTA O SEU LUGAR</span></span>,
  headerClassFellowWaitingMobileTextBR: <span>EM BREVE PRÓXIMA TURMA,<br /><span className="bold">GARANTA O SEU LUGAR</span></span>,
  headerTimerDayText: 'DIA',
  headerTimerHourText: 'HORA',
  headerTimerMinuteText: 'MINUTO',
  headerTimerSecondText: 'SEGUNDOS',
  navPageButtonHarmonizeText: 'Método Harmonize',
  navPageButtonFellowText: 'Especialização Fellow',
  talkToExpertText: 'Fale com um especialista',  
}
// Talk to Expert Form
const talkToExpertFormResources = {
  talkToExpertFormHeaderText1BR: 'FICO FELIZ EM PODER FAZER PARTE DA SUA TRANSFORMAÇÃO,',
  talkToExpertFormHeaderText1PT: 'FICO FELIZ EM PODER FAZER PARTE DA SUA TRANSFORMAÇÃO,',
  talkToExpertFormHeaderText2BR: 'Participe do programa, entre em um grupo seleto de alunos e comece a gerar melhores resultados',
  talkToExpertFormHeaderText2PT: 'Participe do programa, entre em um grupo seleto de alunos e comece a gerar melhores resultados',
  talkToExpertFormNameTextBR: 'Nome *', 
  talkToExpertFormNameTextPT: 'Nome *',
  talkToExpertFormEmailTextBR: 'Email *', 
  talkToExpertFormEmailTextPT: 'Email *',
  talkToExpertFormWhatsappTextBR: 'Whatsapp *', 
  talkToExpertFormWhatsappTextPT: 'Whatsapp *',
  talkToExpertFormWhatsappCountryCodeTextBR: '+55', 
  talkToExpertFormWhatsappCountryCodeTextPT: '+351',
  talkToExpertFormGraduationTextBR: 'Formação *', 
  talkToExpertFormGraduationTextPT: 'Formação *',
  talkToExpertFormText1BR: 'Prometemos não utilizar suas informações de contato para qualquer tipo de SPAM.', 
  talkToExpertFormText1PT: 'Prometemos não utilizar suas informações de contato para qualquer tipo de SPAM.',
  talkToExpertFormButtonTextBR: 'REALIZAR INSCRIÇÃO',
  talkToExpertFormButtonTextPT: 'REALIZAR INSCRIÇÃO',
  talkToExpertSuccessText1BR: 'Em breve um dos nossos representantes entrará em contato',
  talkToExpertSuccessText1PT: 'Em breve um dos nossos representantes entrará em contato',
  talkToExpertSuccessButtonTextBR: 'VOLTAR A PÁGINA INICIAL',
  talkToExpertSuccessButtonTextPT: 'VOLTAR A PÁGINA INICIAL'
}
// Home
const homeTextsResources = {
  // Home - Session 1
  homeSession1TextBR: 'Apaixonado em ensinar às pessoas o que há de melhor na harmonização Orofacial',
  homeSession1TextPT: 'Apaixonado por ensinar às pessoas o melhor na harmonização orofacial',
  // Home - Session 2
  homeSession2TitleText: 'DR. LUCAS CHAGAS',
  homeSession2InstagramText: '@dr.lucaschagas',
  homeSession2YoutubeText: '/metodo-harmonize',
  homeSession2Bio1TextBR: `Natural do Rio de Janeiro, é graduado em
  Odontologia pela Universidade Salgado de
  Oliveira, especialista em Harmonização Orofacial
  e implantodontia.`,
  homeSession2Bio1TextPT: `Natural do Rio de Janeiro, é licenciado em
  Medicina Dentária pela Universidade Salgado de
  Oliveira, especialista em Harmonização Orofacial
  e Implantodontia.`,
  homeSession2Bio2TextBR: `Com apenas 27 anos, possui um vasto currículo
  na Harmonização Facial e no empreendedorismo,
  tendo experiências em cursos e palestras
  nacionais e internacionais em diversos Estados
  do Brasil e residências na Coréia do Sul.`,
  homeSession2Bio2TextPT: `Aos 27 anos, tem um vasto currículo
  na Harmonização Facial e Empreendedorismo, tendo
  experiência em cursos nacionais e internacionais
  e palestras em vários Estados do Brasil e
  residências na Coréia do Sul.`,
  homeSession2Bio3TextBR: `Em seus cursos, Lucas já formou mais de 1.000
  alunos em pouco mais de 5 anos e atualmente é
  visto como uma das maiores revelações nas redes
  sociais quando o assunto é estética facial. Dessa
  forma, está disposto a proporcionar uma
  experiência única aos seus alunos com todo o seu
  conhecimento e experiência no assunto.`,
  homeSession2Bio3TextPT: `Nos seus cursos, Lucas formou mais de 1.000
  alunos em pouco mais de 5 anos e é atualmente
  visto como uma das maiores revelações nas redes
  sociais no que diz respeito à estética facial. Desta
  forma, está disposto a proporcionar uma
  experiência única com todo o seu
  conhecimento e experiência na disciplina.`,
  // Home - Session 3
  homeSession3TitleText: 'TRAJETÓRIA',
  homeSession3Sec1TextBR: `Em janeiro de 2017, aos 21 anos, me formo na graduação de
  Odontologia pela Universo. Sem dúvidas, o dia mais marcante da
  minha vida. Tinha certeza que apartir desse dia contruiria uma
  grande história!`,
  homeSession3Sec1TextPT: `Em janeiro de 2017, aos 21 anos, formei-me na licenciatura em
  Medicina Dentária no Universo. Sem dúvida, o dia mais notável da
  minha vida. Tinha a certeza que a partir desse dia iria construir uma
  grande história!`,
  homeSession3Sec2TextBR: `Finalizando a graduação, resolvi escutar meu coração e dar o
  próximo passo no assunto que eu era apaixonado. Me matriculei na
  especialização de Implantodontia para obter conhecimentos mais
  específicos nas matérias de prótese e cirurgia.`,
  homeSession3Sec2TextPT: `Terminando a formatura, decidi ouvir meu coração e dar o
  próximo passo no assunto pelo que me apaixonado. Inscrevi-me na
  especialização de Implantodontia para obter conhecimentos mais
  específicos nas matérias de protése e cirurgia.`,
  homeSession3Sec3TextBR: `Com o objetivo de me diferenciar no mercado e obter novos
  horizontes para a minha carreira profissional, fui em busca do meu
  primeiro curso de imersão em Harmonização Orofacial em meados
  de 2017.`,
  homeSession3Sec3TextPT: `Para me diferenciar no mercado, e obter novos
  horizontes para a minha carreira profissional, fui à procura do meu
  primeiro curso de imersão em Harmonização Orofacial em meados
  de 2017.`,
  homeSession3Sec4TextBR: `Demonstrando muita dedicação em todo curso, recebi o convite
  do próprio professor para em conjunto estarmos realizando um novo
  curso. Dessa vez minha primeira vez como professor.`,
  homeSession3Sec4TextPT: `Demonstrando muita dedicação ao longo do curso, recebi o convite
  do próprio professor para, em conjunto, estar a realizar um novo curso. Desta vez, a minha
  primeira vez como professora.`,
  homeSession3Sec5TextBR: `Após 1 ano, batemos a nossa primeira grande marca de 100 alunos
  formados, em comemoração, também realizamos a nossa primeira
  turma em outro estado. Paraíba nos acolheu de braços abertos!`,
  homeSession3Sec5TextPT: `Depois de 1 ano, vencemos a nossa primeira grande marca de 100 alunos
  formados, em celebração, também fizemos a nossa primeira
  aula noutro estado. Paraíba nos recebeu de braços abertos!`,
  homeSession3Sec6TextBR: `Com apenas 2 anos no mercado, recebi o convite para fazer parte
  da Redidência em fios de PDO em Seoul, na Coréia do Sul com o
  criador dos fios, Dr Kwon Han Jin.`,
  homeSession3Sec6TextPT: `Com apenas 2 anos no mercado, recebi o convite para fazer parte
  da Redidência do Fio PDO em Seul, Coreia do Sul com o
  criador de fios, Dr. Kwon Han Jin.`,
  homeSession3Sec7TextBR: `Em 2020, batemos a marca de 500 alunos formados no Discovery. O
  primeiro curso de imersão em Harmonização Orofacial e Empreendedorismo do Brasil.`,
  homeSession3Sec7TextPT: `Em 2020, atingimos a marca dos 500 alunos que foram formados no Discovery. O
  primeiro curso de imersão em Harmonização Orofacial e Empreendedorismo no Brasil.`,
  homeSession3Sec8TextBR: `Em 2021, batemos a marca de 1.000 alunos formados no Método
  Harmonize. Além da Harmonização orofacial e Empreendedorismo,
  também adicionamos em nossa imersão, aulas de Marketing Digital
  e Vendas.`,
  homeSession3Sec8TextPT: `Em 2021, atingimos a marca 1.000 alunos que foram formados no Método
  Harmonizado. Para além da harmonização orofacial e do empreendedorismo,
  adicionámos também aulas de marketing e vendas digitais à nossa imersão.`,
  homeSession3Sec9TextBR: `Em 2022, fui convidado para ser professor auxiliar no curso de 
  imersão Anatomy and facial rejuvenation na faculdade de Harvard
  nos Estados Unidos e tivemos a experiência de realizar a primeira
  turma do Método Harmonize em Lisboa, Portugal.`,
  homeSession3Sec9TextPT: `Em 2022, fui convidado para ser professor assistente no curso de 
  Anatomia e Rejuvenescimento Facil na Harvard College, nos Estados Unidos, e tivemos
  a experiência de realizar a primeira aula do Método Harmonização em Lisboa, Portugal.`,
}
// Harmonize
const harmonizeTextsResources = {
  // Harmonize - Session 1
  webHarmonizeSession1Text1BR: <span>A ÚNICA IMERSÃO DE <span className="bold harmonizeText">HARMONIZAÇÃO FACIAL</span> E EMPREENDEDORISMO DO BRASIL</span>,
  webHarmonizeSession1Text1PT: <span>A MAIS COMPLETA IMERSÃO DE <span className="bold harmonizeText">HARMONIZAÇÃO FACIAL</span> E EMPREENDEDORISMO EM LISBOA</span>,
  mobileHarmonizeSession1Text1BR: <span>A ÚNICA IMERSÃO DE<br /><span className="bold harmonizeMobileText">HARMONIZAÇÃO FACIAL</span><br/>E EMPREENDEDORISMO DO BRASIL</span>,
  mobileHarmonizeSession1Text1PT: <span>A MAIS COMPLETA IMERSÃO DE<br /><span className="bold harmonizeMobileText">HARMONIZAÇÃO FACIAL</span><br/>E EMPREENDEDORISMO EM LISBOA</span>,
  harmonizeSession1Text2BR: `Conquiste a confiança em realizar os procedimentos e se prepare para os desafios da sua carreira.`,
  harmonizeSession1Text2PT: `Ganhe confiança na realização dos procedimentos e prepare-se para os desafios da sua carreira.`,
  harmonizeSession1ButtonTextBR: 'MAIS INFORMAÇÕES',
  harmonizeSession1ButtonTextPT: 'MAIS INFORMAÇÕES',
  // Harmonize - Session 2
  harmonizeSession2TitleTextBR: 'A EXPERIÊNCIA MAIS COMPLETA EM HARMONIZAÇÃO FACIAL DO BRASIL',
  harmonizeSession2TitleTextPT: 'A EXPERIÊNCIA MAIS COMPLETA EM HARMONIZAÇÃO FACIAL DO BRASIL AGORA EM PORTUGAL',
  harmonizeSession2Text1BR: `O Método Harmonize é um curso 360º que engloba
  os principais procedimentos da harmonização
  orofacial, desde o básico da anatomia até os mais
  complexos desdobramentos de intercorrências.`,
  harmonizeSession2Text1PT: `O Método harmonizado é um curso de 360º que
  engloba os principais procedimentos de harmonização
  orofacial, desde os fundamentos da anatomia até ao
  desenrolar mais complexo das intercorrências.`,
  harmonizeSession2Text2BR: `Além disso, o curso também engloba marketing
  digital, Vendas e Gestão e atualizações sobre o
  mercado da Harmonização facial no mundo, visto
  que o nosso objetivo é formar profissionais
  amplamente capacitados tanto na parte técnica
  quanto na divulgação e venda de seus serviços.`,
  harmonizeSession2Text2PT: `Além disso, o curso inclui também Marketing digital,
  Vendas e Gestão e atualizações sobre o mercado de
  harmonização face no mundo, uma vez que o nosso
  objetivo é formar profissionais amplamente formados
  tanto na parte técnica como na divulgação e venda dos
  seus serviços.`,
  harmonizeSession2ButtonTextBR: 'FALE COM UM ESPECIALISTA',
  harmonizeSession2ButtonTextPT: 'FALAR COM UM ESPECIALISTA',
  // Harmonize - Session 3
  harmonizeSession3Button1TextPT: 'MÉTODO HARMONIZE',
  harmonizeSession3Button2TextPT: 'ADVANCED HARMONIZE',
  harmonizeSession3MethodHarmonizeTitleTextPT: 'MÉTODO HARMONIZE',
  harmonizeSession3MethodAdvancedTitleTextPT: 'ADVANCED HARMONIZE',
  harmonizeSession3MethodHarmonizeTextPT: `Curso focado em quem está a começar na
  harmonização orofacial e quer especializar-se para
  ter segurança para se candidatar nos seus
  escritórios e alavancar a carreira, terá aulas de
  marketing digital e cuidados com o paciente para
  aprender a divulgar o seu serviço, comunicar com o
  seu público ideal e tornar-se um profissional de
  referência na sua localidade.`,
  harmonizeSession3MethodAdvancedTextPT: `A proposta do curso de Harmonização Avançada é
  fazer com que os profissionais que já trabalham na
  área da Harmonização Facil em referências, com
  técnicas exclusivas e avançadas. Você vai aprender
  novos procedimentos, deixando o curso com um
  vasto conhecimento técnico para se destacar no
  mercado orofacial.`,
  harmonizeSession3LearnTextBR: `O QUE VOCÊ IRÁ APRENDER:`,
  harmonizeSession3LearnTextPT: `O QUE VOCÊ IRÁ APRENDER:`,
  harmonizeSession3RouletteTitleText1HarmonizeBR: `ANATOMIA FACIAL`,
  harmonizeSession3RouletteText1HarmonizeBR: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da anatomia da
  face é fundamental.`,
  harmonizeSession3RouletteTitleText1HarmonizePT: `ANATOMIA FACIAL`,
  harmonizeSession3RouletteText1HarmonizePT: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da anatomia da
  face é fundamental.`,
  harmonizeSession3RouletteTitleText2HarmonizeBR: `TOXINA BOTULÍNICA`,
  harmonizeSession3RouletteText2HarmonizeBR: `A toxina botulínica, mais conhecida como botox, é um dos procedimentos
  mais conhecidos e procurados em clínicas de estética de todo o mundo.
  Seja por seus notáveis benefícios na melhora da estética ou por sua
  máxima segurança, ela é a queridinha dos pacientes.`,
  harmonizeSession3RouletteTitleText2HarmonizePT: `TOXINA BOTULÍNICA`,
  harmonizeSession3RouletteText2HarmonizePT: `A toxina botulínica, mais conhecida como botox, é um dos procedimentos
  mais conhecidos e procurados em clínicas de estética de todo o mundo.
  Seja por seus notáveis benefícios na melhora da estética ou por sua
  máxima segurança, ela é a queridinha dos pacientes.`,
  harmonizeSession3RouletteTitleText3HarmonizeBR: `PREENCHIMENTOS FACIAIS`,
  harmonizeSession3RouletteText3HarmonizeBR: `Com o passar dos anos é normal que algumas áreas do rosto percam
  volume e ocorra o aparecimento das rugas. Apesar de fazerem parte do
  ritmo natural do envelhecimento da pele, essas marcas podem causar um
  semblante abatido ou mesmo aborrecido para a fisionomia e mexer com a
  autoestima das pessoas. Para esses casos, atualmente, um dos
  procedimentos estéticos mais seguros e com resultados praticamente
  instantâneos são os preenchimentos faciais com o ácido hialurônico.
  Entre os principais usos dessa técnica minimamente invasiva, está a
  suavização de rugas faciais, aumento no volume dos lábios, tratamento
  de “olheiras” , suavização de pequenas retrações cicatriciais e os ajustes
  no contorno e volume da face.`,
  harmonizeSession3RouletteTitleText3HarmonizePT: `PREENCHIMENTOS FACIAIS`,
  harmonizeSession3RouletteText3HarmonizePT: `Com o passar dos anos é normal que algumas áreas do rosto percam
  volume e ocorra o aparecimento das rugas. Apesar de fazerem parte do
  ritmo natural do envelhecimento da pele, essas marcas podem causar um
  semblante abatido ou mesmo aborrecido para a fisionomia e mexer com a
  autoestima das pessoas. Para esses casos, atualmente, um dos
  procedimentos estéticos mais seguros e com resultados praticamente
  instantâneos são os preenchimentos faciais com o ácido hialurônico.
  Entre os principais usos dessa técnica minimamente invasiva, está a
  suavização de rugas faciais, aumento no volume dos lábios, tratamento
  de “olheiras” , suavização de pequenas retrações cicatriciais e os ajustes
  no contorno e volume da face.`,
  harmonizeSession3RouletteTitleText4HarmonizeBR: `FIOS DE SUSTENTAÇÃO`,
  harmonizeSession3RouletteText4HarmonizeBR: `Atualmente, existe no mercado, uma infinidade de tipos e modelos de
  fios, e isso pode acabar trazendo um pouco de confusão para alguns
  pacientes. De forma geral, existem dois tipos de fios: os absorvíveis (fios
  silhouette e fios de pdo) e os não-absorvíveis, ou seja, os permanentes
  (fios russos, fios de ouro, fios búlgaros) - e a diferença entre essas duas
  categorias é tipo de material pelo qual o fio é composto. Os fios são
  chamados de fios de sustentação, mas a questão é que nem todos
  sustentam, pois alguns tem finalidade de suporte de colágeno e outros
  tem objetivo de preenchimento.`,
  harmonizeSession3RouletteTitleText4HarmonizePT: `FIOS DE SUSTENTAÇÃO`,
  harmonizeSession3RouletteText4HarmonizePT: `Atualmente, existe no mercado, uma infinidade de tipos e modelos de
  fios, e isso pode acabar trazendo um pouco de confusão para alguns
  pacientes. De forma geral, existem dois tipos de fios: os absorvíveis (fios
  silhouette e fios de pdo) e os não-absorvíveis, ou seja, os permanentes
  (fios russos, fios de ouro, fios búlgaros) - e a diferença entre essas duas
  categorias é tipo de material pelo qual o fio é composto. Os fios são
  chamados de fios de sustentação, mas a questão é que nem todos
  sustentam, pois alguns tem finalidade de suporte de colágeno e outros
  tem objetivo de preenchimento.`,
  harmonizeSession3RouletteTitleText5HarmonizeBR: `INTERCORRÊNCIAS`,
  harmonizeSession3RouletteText5HarmonizeBR: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da
  anatomia da face é fundamental.`,
  harmonizeSession3RouletteTitleText5HarmonizePT: `INTERCORRÊNCIAS`,
  harmonizeSession3RouletteText5HarmonizePT: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da
  anatomia da face é fundamental.`,
  harmonizeSession3RouletteTitleText6HarmonizeBR: `BIOESTIMULADORES`,
  harmonizeSession3RouletteText6HarmonizeBR: `Os Bioestimuladores de Colágeno são substâncias aplicadas na face para
  estimular a produção de novas fibras de colágeno. Desta forma, auxiliam
  na melhora do aspecto da face, minimizando os sinais do tempo,
  atenuando a flacidez e proporcionando uma pele mais uniforme. Além
  disso, as substâncias podem ser aplicadas em outras regiões do corpo,
  como barriga e coxas, para amenizar a flacidez. Os Bioestimuladores de Colágeno 
  são aplicados na pele de forma minimamente invasiva, em pontos específicos da face.`,
  harmonizeSession3RouletteTitleText6HarmonizePT: `BIOESTIMULADORES`,
  harmonizeSession3RouletteText6HarmonizePT: `Os Bioestimuladores de Colágeno são substâncias aplicadas na face para
  estimular a produção de novas fibras de colágeno. Desta forma, auxiliam
  na melhora do aspecto da face, minimizando os sinais do tempo,
  atenuando a flacidez e proporcionando uma pele mais uniforme. Além
  disso, as substâncias podem ser aplicadas em outras regiões do corpo,
  como barriga e coxas, para amenizar a flacidez. Os Bioestimuladores de Colágeno 
  são aplicados na pele de forma minimamente invasiva, em pontos específicos da face.`,
  harmonizeSession3RouletteTitleText7HarmonizeBR: `VENDAS E MARKETING DIGITAL`,
  harmonizeSession3RouletteText7HarmonizeBR: `Com o passar dos anos é normal que algumas áreas do rosto percam
  volume e ocorra o aparecimento das rugas. Apesar de fazerem parte do
  ritmo natural do envelhecimento da pele, essas marcas podem causar um
  semblante abatido ou mesmo aborrecido para a fisionomia e mexer com a
  autoestima das pessoas. Para esses casos, atualmente, um dos
  procedimentos estéticos mais seguros e com resultados praticamente
  instantâneos são os preenchimentos faciais com o ácido hialurônico.
  Entre os principais usos dessa técnica minimamente invasiva, está a
  suavização de rugas faciais, aumento no volume dos lábios, tratamento
  de “olheiras” , suavização de pequenas retrações cicatriciais e os ajustes
  no contorno e volume da face.`,
  harmonizeSession3RouletteTitleText7HarmonizePT: `VENDAS E MARKETING DIGITAL`,
  harmonizeSession3RouletteText7HarmonizePT: `Com o passar dos anos é normal que algumas áreas do rosto percam
  volume e ocorra o aparecimento das rugas. Apesar de fazerem parte do
  ritmo natural do envelhecimento da pele, essas marcas podem causar um
  semblante abatido ou mesmo aborrecido para a fisionomia e mexer com a
  autoestima das pessoas. Para esses casos, atualmente, um dos
  procedimentos estéticos mais seguros e com resultados praticamente
  instantâneos são os preenchimentos faciais com o ácido hialurônico.
  Entre os principais usos dessa técnica minimamente invasiva, está a
  suavização de rugas faciais, aumento no volume dos lábios, tratamento
  de “olheiras” , suavização de pequenas retrações cicatriciais e os ajustes
  no contorno e volume da face.`,
  harmonizeSession3RouletteTitleText1AdvancedPT: `ANATOMIA FACIAL`,
  harmonizeSession3RouletteText1AdvancedPT: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da anatomia da
  face é fundamental.`,
  harmonizeSession3RouletteTitleText2AdvancedPT: `SKIN BOOSTER`,
  harmonizeSession3RouletteText2AdvancedPT: `O Skinbooster é uma hidratação injetável que trata profundamente a pele
  através de ácido hialurônico e diversas vitaminas que possui na sua
  composição. Eles trazem um resultado muito superior aos cremes
  noturnos, que atuam somente na camada mais superficial.`,
  harmonizeSession3RouletteTextA2AdvancedPT: <span>- Proporciona hidratação profunda<br />- Estimula a produção de colágeno<br />- Auxilia no tratamento de cicatrizes de acne<br />- Melhora a aparência das olheiras<br />- Rejuvenesce a área do colo e das mãos<br />- Tras mais viço e brilho à pele</span>,
  harmonizeSession3RouletteTitleText3AdvancedPT: `PREENCHIMENTOS FACIAIS - TÉCNICAS AVANÇADAS EM MD CODES`,
  harmonizeSession3RouletteText3AdvancedPT: `O termo MD Codes significa Códigos Médicos (Medical Codes). Foi
  criado pelo cirurgião plástico brasileiro Dr. Mauricio de Maio.
  O preenchimento facil com a técnica MD Codes é um dos tratamentos
  mais avançados, seguros e eficazes atualmente. Com a técnica, é
  possível trazer sustentação e volume para a face, com naturalidade e
  sem necessidade de afastamento das atividades rotineiras. Com isto,
  observa-se melhora no contorno facial e na firmeza da pele, trazendo o
  "efeito lifting" sem necessidade de passar por um procedimento mais
  invasivo.`,
  harmonizeSession3RouletteTitleText4AdvancedPT: `FIOS TENSORES`,
  harmonizeSession3RouletteText4AdvancedPT: `A técnica de utilização dos fios para procedimentos estéticos iniciou em
  meados de 2006, pelo renomado Dr. Kwon Han, médico Coreado, na qual
  o Dr. Lucas teve sua residência.
  Os tratamentos com Fios tensores são uma técnica inovadora
  de rejuvenescimento facial, que oferece o benefício de uma intervenção não
  cirúrgica e, portanto, requer apenas um curto período de recuperação. O
  material consiste em fios biodegradáveis que há muito são uilizados em
  cirurgias. Durante este procedimento, os fios são inseridos nos tecidos
  conjuntivos mais profundos, fixando assim o tecido subcutâneo e, se
  posicionados corretamente, criam uma tensão na direção desejada. Isso
  cria um leve alongamento do tecido subcutâneo e da pele, que é firmado
  após alguns dias pelo aumento natural do tecido conjuntivo (fibrosante).`,
  harmonizeSession3RouletteTitleText5AdvancedPT: `INTERCORRÊNCIAS`,
  harmonizeSession3RouletteText5AdvancedPT: `A face, ou o rosto, é uma janela para o mundo, que revela muito sobre o
  que somos e como nos sentimos. Trata-se de uma região delimitada
  pelos espaços entre testa e queixo e de uma orelha à outra.
  Procedimentos estéticos, invasivos ou não, podem trazer ótimos
  resultados, e melhorar a autoestima e a qualidade de vida dos pacientes.
  No entanto, exigem qualificação e preparo. Afinal, na face existem
  estruturas nobres que, quando lesadas podem gerar sequelas
  irreversíveis para os pacientes. Portanto, o conhecimento da
  anatomia da face é fundamental.`,
  harmonizeSession3RouletteTitleText6AdvancedPT: `BIOESTIMULADORES DE COLÁGENO`,
  harmonizeSession3RouletteText6AdvancedPT: `São substâncias injetáveis que provocam a produção do colágeno tipo 1,
  responsável pela firmeza e elasticidade da pele. As aplicações são feitas
  no próprio consultório dermatológico, causam o mínimo de desconforto e
  o paciente pode voltar à sua rotina normal logo após a sessão.
  Os resultados dos bioestimuladores de colágeno não são imediatos, a
  substância continua estimulando essa produção até ser totalmente
  absorvida pelo organismo. Os resultados podem durar até 4 anos,
  dependendo da substância utilizada.`,
  harmonizeSession3RouletteTitleText7AdvancedPT: `VENDAS E MARKETING DIGITAL`,
  harmonizeSession3RouletteText7AdvancedPT: `Com o passar dos anos é normal que algumas áreas do rosto percam
  volume e ocorra o aparecimento das rugas. Apesar de fazerem parte do
  ritmo natural do envelhecimento da pele, essas marcas podem causar um
  semblante abatido ou mesmo aborrecido para a fisionomia e mexer com a
  autoestima das pessoas. Para esses casos, atualmente, um dos
  procedimentos estéticos mais seguros e com resultados praticamente
  instantâneos são os preenchimentos faciais com o ácido hialurônico.
  Entre os principais usos dessa técnica minimamente invasiva, está a
  suavização de rugas faciais, aumento no volume dos lábios, tratamento
  de “olheiras” , suavização de pequenas retrações cicatriciais e os ajustes
  no contorno e volume da face.`,
  harmonizeSession3DownloadScheduleBR: 'Clique aqui para baixar a ementa completa e o cronograma do curso',
  harmonizeSession3DownloadSchedulePT: 'Clique aqui para baixar o menu completo e a programação do curso',
  harmonizeSession3ButtonTextBR: 'TENHO INTERESSE',
  harmonizeSession3ButtonTextPT: 'ESTOU INTERESSADO',
  // Harmonize - Session 4
  webHarmonizeSession4ReasonsTitleTextBR: 'OS PRINCIPAIS MOTIVOS QUE CONSOLIDAM O MÉTODO HARMONIZE NO BRASIL E PORTUGAL',
  webHarmonizeSession4ReasonsTitleTextPT: 'OS PRINCIPAIS MOTIVOS QUE CONSOLIDAM O MÉTODO HARMONIZE NO BRASIL E PORTUGAL',
  mobileHarmonizeSession4ReasonsTitleTextBR: <span>OS PRINCIPAIS MOTIVOS QUE<br/>CONSOLIDAM O<br/>MÉTODO HARMONIZE<br/>NO BRASIL E PORTUGAL</span>,
  mobileHarmonizeSession4ReasonsTitleTextPT: <span>OS PRINCIPAIS MOTIVOS QUE<br/>CONSOLIDAM O<br/>MÉTODO HARMONIZE<br/>NO BRASIL E PORTUGAL</span>,
  harmonizeSession4Reason1TextBR: <span><span className="bold red">Os resultados sutis e assertivos</span> que o Dr Lucas Chagas consegue entregar aos seus pacientes utilizando suas técnicas de visagismo</span>,
  harmonizeSession4Reason1TextPT: <span><span className="bold red">Os resultados sutis e assertivos</span> que o Dr Lucas Chagas consegue entregar aos seus pacientes utilizando suas técnicas de visagismo</span>,
  harmonizeSession4Reason2TextBR: <span><span className="bold red">O conteúdo completo e sem segredos.</span> Dr. Lucas Chagas repassa todo seu conhecimento e dicas pontuais para a realização dos protocolos</span>,
  harmonizeSession4Reason2TextPT: <span><span className="bold red">O conteúdo completo e sem segredos.</span> Dr. Lucas Chagas repassa todo seu conhecimento e dicas pontuais para a realização dos protocolos</span>,
  harmonizeSession4Reason3TextBR: <span>A quantidade de <span className="bold red">aulas práticas oferecida no curso</span> é muito acima da média dos cursos do mercado. Com uma média de 50 pacientes modelo por turma</span>,
  harmonizeSession4Reason3TextPT: <span>A quantidade de <span className="bold red">aulas práticas oferecida no curso</span> é muito acima da média dos cursos do mercado. Com uma média de 50 pacientes modelo por turma</span>,
  harmonizeSession4Reason4TextBR: <span>A <span className="bold red">didática e paciência</span> na transferência dos temas abordados desde o básico até temas mais complexos</span>,
  harmonizeSession4Reason4TextPT: <span>A <span className="bold red">didática e paciência</span> na transferência dos temas abordados desde o básico até temas mais complexos</span>,
  harmonizeSession4Reason5TextBR: <span>As <span className="bold red">técnicas ensinadas são muito seguras</span>, com baixa propensão a intecorrências</span>,
  harmonizeSession4Reason5TextPT: <span>As <span className="bold red">técnicas ensinadas são muito seguras</span>, com baixa propensão a intecorrências</span>,
  harmonizeSession4Reason6TextBR: <span><span className="bold red">Suporte pós curso</span> em nossos grupos de whatsapp onde Dr. Lucas e nossos monitores respondem suas dúvidas em tempo real</span>,
  harmonizeSession4Reason6TextPT: <span><span className="bold red">Suporte pós curso</span> em nossos grupos de whatsapp onde Dr. Lucas e nossos monitores respondem suas dúvidas em tempo real</span>,
  harmonizeSession4ButtonTextBR: 'SAIBA MAIS',
  harmonizeSession4ButtonTextPT: 'SAIBA MAIS',
  // Harmonize - Session 5
  harmonizeSession5TitleBR: 'OS RESULTADOS FALAM POR SI SÓ!',
  harmonizeSession5TitlePT: 'OS RESULTADOS FALAM POR SI!',
  // Harmonize - Session 6
  harmonizeSession6TitleBR: 'MAIS DE 1000 ALUNOS FORMADOS E SATISFEITOS',
  harmonizeSession6TitlePT: 'MAIS DE 1000 ALUNOS SATISFEITOS',
  harmonizeSession6ButtonTextBR: 'GARANTA SUA VAGA',
  harmonizeSession6ButtonTextPT: 'PROTEJA O SEU LUGAR',
  harmonizeSession6FooterText1BR: 'Fale com a gente agora mesmo!',
  harmonizeSession6FooterText1PT: 'Fale com a gente agora mesmo!',
  harmonizeSession6FooterText2BR: 'Ficou alguma dúvida? Contate nosso pessoal e tire todas as suas dúvidas a respeito!',
  harmonizeSession6FooterText2PT: 'Ficou alguma dúvida? Contate nosso pessoal e tire todas as suas dúvidas a respeito!',
  harmonizeSession6FooterButtonTextBR: 'FALE COM A GENTE',
  harmonizeSession6FooterButtonTextPT: 'FALE CONOSCO',
}
// Fellow
const fellowTextsResources = {
  // Fellow - Session 1
  webFellowSession1Text1: <span>TE CONVIDO A SE TORNAR ESPECIALISTA NO <span className="bold harmonizeText">MERCADO DA HARMONIZAÇÃO FACIAL</span></span>,
  mobileFellowSession1Text1: <span>TE CONVIDO A SE TORNAR ESPECIALISTA NO <span className="bold harmonizeMobileText">MERCADO DA HARMONIZAÇÃO FACIAL</span></span>,
  fellowSession1Text2: `Você vai aprender com muita prática, os temas que estão em alta na Harmonização Facial, com professores renomados que irão maximizar sua aprendizagem`,
  fellowSession1ButtonText: 'MAIS INFORMAÇÕES',
  // Fellow - Session 2
  fellowSession2TitleText1: 'O QUE É A',
  fellowSession2TitleText2: 'ESPECIALIZAÇÃO FELLOW?',
  fellowSession2Text1: 'É a especialização mais completa já vista pelo mercado da Harmonização Facial, com conteúdos de alta qualidade e temas abordados com clareza e profundidade, em parceria com a Faculdade Oeste Paulista - FACOP.',
  fellowSession2Text2: 'A especialização Fellow Harmonize lhe trará segurança para aplicar em seu consultório as técnicas mais atuais e mais avançadas em relação à harmonia e estética orofacial.',
  fellowSession2Text3: 'Se torne destaque no mercado que movimenta mais de 168 bilhões de reais por ano, sendo uma das áreas mais lucrativas do País.',
  fellowSession2Text4: 'MÓDULOS DO CURSO',
  fellowSession2ButtonText: 'QUERO ME TORNAR REFERÊNCIA',
  // Fellow - Session 3
  webFellowSession3Text1: <span>Tudo que você precisa para não perder mais tempo e dinheiro para se tornar especialista na harmonização facial. Pare de gastar rios de dinheiro com vários cursos, tenha tudo na <span className="bold fellowText">Especialização Fellow.</span></span>,
  mobileFellowSession3Text1: <span>Tudo que você precisa para não perder mais tempo e dinheiro para se tornar especialista na harmonização facial. Pare de gastar rios de dinheiro com vários cursos, tenha tudo na <span className="bold fellowMobileText">Especialização Fellow.</span></span>,
  fellowSession3Text2: <span>MAIS SOBRE A <span className="bold fellowTextGratient">ESPECIALIZAÇÃO FELLOW HARMONIZE</span></span>,
  fellowSession3More1Text: 'Permitir ao aluno a identificação do diagnóstico ideal para cada procedimento de harmonização orofacial',
  fellowSession3More2Text: <span>Além do conteúdo teórico, são mais de <span className="bold yellow">200 horas de aula prática,</span> com centenas de pacientes modelos, tendo acesso a técnicas exclusivas e monitores para te ajudar</span>,
  fellowSession3More3Text: 'Tratamento de intercorrências e complicações nos procedimentos',
  fellowSession3More4Text: 'Duração de 18 meses. Serão combinadas aulas presenciais e aulas online para se destacar no mercado com o conteúdo mais completo do mercado',
  fellowSession3More5Text: <span>Modalidade presencial, com suporte da equipe e experiências práticas. <span className="bold yellow">Todos os materiais necessários</span> do curso serão fornecidos</span>,
  fellowSession3More6Text: 'Ao final do curso, você recebe um certificado de participação contando com 560 horas de curso. Emitido pela faculdade FACOP, registrado no Ministério da Educação',
  fellowSession3ButtonText: 'FALE COM UM ESPECIALISTA',
  // Fellow - Session 4
  fellowSession4Text1: 'PROGRAMAÇÃO',
  fellowSession4Module1Text1: <span><span className="bold">Módulo 1 - </span>Anatomia facial, Fisiologia do envelhecimento e metabologia</span>,
  fellowSession4Module1Text2: '- Dinâmica em grupo (Prática)',
  fellowSession4Module2Text1: <span><span className="bold">Módulo 2 - </span>Toxina Botulínica (pontos básicos e avançados)</span>,
  fellowSession4Module2Text2: '- Prática clínica',
  fellowSession4Module3Text1: <span><span className="bold">Módulo 3 - </span>Preenchimentos faciais com ácido hialurônico 1</span>,
  fellowSession4Module3Text2: '(Skinbooster e preenchimentos básicos) - Prática clínica',
  fellowSession4Module4Text1: <span><span className="bold">Módulo 4 - </span>Diagnóstico, visagismo, proporções faciais, identificação de personalidade e elaboração de protocolos</span>,
  fellowSession4Module4Text2: '(skinbooster e preenchimentos básicos)- Prática clínica',
  fellowSession4Module5Text1: <span><span className="bold">Módulo 5 - </span>Preenchimentos faciais com ácido hialurônico 2</span>,
  fellowSession4Module5Text2: '(MD Codes e preenchimentos avançados) - Prática clínica',
  fellowSession4Module6Text1: <span><span className="bold">Módulo 6 - </span>Bioestimuladores de colágeno</span>,
  fellowSession4Module6Text2: '- Prática clínica',
  fellowSession4Button1Text: 'VER MAIS',
  fellowSession4Module7Text1: <span><span className="bold">Módulo 7 - </span>IPRF, PRF e microagulhamento</span>,
  fellowSession4Module7Text2: '- Prática clínica',
  fellowSession4Module8Text1: <span><span className="bold">Módulo 8 - </span>Fios de sustentação 1 (PDO Filosofia Coreana)</span>,
  fellowSession4Module8Text2: '- Prática clínica',
  fellowSession4Module9Text1: <span><span className="bold">Módulo 9 - </span>Bichectomia e lipo cirúrgica de papada</span>,
  fellowSession4Module9Text2: '- Prática clínica',
  fellowSession4Module10Text1: <span><span className="bold">Módulo 10 - </span>Fios de sustentação 2 Lifting temporal e rinomodelação com fios.</span>,
  fellowSession4Module10Text2: '(PDO, PCL, PLLA) - Prática clínica',
  fellowSession4Module11Text1: <span><span className="bold">Módulo 11 - </span>Intercorrências Clínicas</span>,
  fellowSession4Module11Text2: '- Prática clínica',
  fellowSession4Module12Text1: <span><span className="bold">Módulo 12 - </span>Jato de plasma e Laserterapia</span>,
  fellowSession4Module12Text2: '- Prática clínica',
  fellowSession4Module13Text1: <span><span className="bold">Módulo 13 - </span>Cosmetologia e tratamento de pele (Peelings e protocolos)</span>,
  fellowSession4Module13Text2: '- Prática clínica',
  fellowSession4Module14Text1: <span><span className="bold">Módulo 14 - </span>Modulação hormonal, soroterapia na HOF</span>,
  fellowSession4Module14Text2: '- Prática clínica',
  fellowSession4Module15Text1: <span><span className="bold">Módulo 15 - </span>Ultrassom microfocado (Ulthera)</span>,
  fellowSession4Module15Text2: '- Prática clínica',
  fellowSession4Module16Text1: <span><span className="bold">Módulo 16 - </span>Marketing digital</span>,
  fellowSession4Module16Text2: '- Prática clínica',
  fellowSession4Module17Text1: <span><span className="bold">Módulo 17 - </span>Gestão Clínica, finanças e contabilidade na área da saúde</span>,
  fellowSession4Module17Text2: '- Prática clínica',
  fellowSession4Module18Date: 'FINAL',
  fellowSession4Module18Text1: <span><span className="bold">Módulo 18 - </span>Entrega dos artigos (TCC)</span>,
  fellowSession4Module18Text2: '- Prática clínica',
  fellowSession4Button2Text: 'VER MENOS',
  // Fellow - Session 5
  fellowSession5DownloadSchedule: 'Clique aqui para baixar a ementa completa e o cronograma do curso',
  fellowSession5OldAmount: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;24x de R$1.800,00&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>,
  webFellowSession5NewAmount: <span className="blackFridayText"><span className="newAmountText">24x de R$ 1.200,00</span></span>,
  mobileFellowSession5NewAmount: <span className="blackFridayMobileText"><span className="newAmountMobileText">24x de R$ 1.200,00</span></span>,
  fellowSession5ButtonText: 'QUERO ME TORNAR REFERÊNCIA',
  // Fellow - Session 6
  fellowSession6Title: 'MAIS DE 1000 ALUNOS FORMADOS E SATISFEITOS',
  fellowSession6ButtonText: 'GARANTA SUA VAGA',
  fellowSession6FooterText1: 'Fale com a gente agora mesmo!',
  fellowSession6FooterText2: 'Ficou alguma dúvida? Contate nosso pessoal e tire todas as suas dúvidas a respeito!',
  fellowSession6FooterButtonText: 'FALE COM A GENTE',
}  
// Footer
const footerTextsResources = {
  addressTextBR: 'CEP 24220-300 | R. MIN. OTÁVIO KELLY, 383 - ICARAÍ, NITERÓI - RJ',
  addressTextPT: 'CEP 2675-669 | ODIVELAS, LISBOA - PT',
  whatsappTextBR: '+55 (21) 99842-2821',
  whatsappTextPT: '+55 (21) 99842-2821',
  mailTextBR: 'comercial@drlucaschagas.com.br',
  mailTextPT: 'comercial@drlucaschagas.com.br',
  instagramTextLinkBR: 'https://www.instagram.com/dr.lucaschagas',
  instagramTextLinkPT: 'https://www.instagram.com/dr.lucaschagas',
  youtubeTextLinkBR: 'https://www.youtube.com/channel/UC6eW29uK-MOM0Wr0Cm4N3yg',
  youtubeTextLinkPT: 'https://www.youtube.com/channel/UC6eW29uK-MOM0Wr0Cm4N3yg',
}
// Admin
const adminTextsResources = {
  // Login
  adminLoginUserLabelText: 'Usuário',
  adminLoginPwdLabelText: 'Senha',
  adminLoginButtonLabelText: 'Entrar',
  // Common
  adminSaveButtonLabelText: 'Salvar',
  // Harmonize BR
  adminHarmonizeBRAvailableSeatsText: 'Vagas Disponíveis:',
  adminHarmonizeBRNextClassDateText: 'Data Próxima Turma:', 
  adminHarmonizeBRIsWaitingListActiveText: 'Lista de Espera Ativa?',
  adminHarmonizeBRSchedulePDFURLText: 'URL PDF Cronograma:',
  // Harmonize PT
  adminHarmonizePTAvailableSeatsText: 'Vagas Disponíveis:',
  adminHarmonizePTNextClassDateText: 'Data Próxima Turma:', 
  adminHarmonizePTIsWaitingListActiveText: 'Lista de Espera Ativa?',
  adminHarmonizePTSchedulePDFURLText: 'URL PDF Cronograma:',
  adminHarmonizePTScheduleAdvancedPDFURLText: 'URL PDF Cronograma Advanced:',  
  // Fellow
  adminFellowAvailableSeatsText: 'Vagas Disponíveis:',
  adminFellowNextClassDateText: 'Data Próxima Turma:', 
  adminFellowIsWaitingListActiveText: 'Lista de Espera Ativa?',
  adminFellowSchedulePDFURLText: 'URL PDF Cronograma:',
  adminFellowModule1DateText: 'Data Módulo 1:',
  adminFellowModule2DateText: 'Data Módulo 2:',
  adminFellowModule3DateText: 'Data Módulo 3:',
  adminFellowModule4DateText: 'Data Módulo 4:',
  adminFellowModule5DateText: 'Data Módulo 5:',
  adminFellowModule6DateText: 'Data Módulo 6:',
  adminFellowModule7DateText: 'Data Módulo 7:',
  adminFellowModule8DateText: 'Data Módulo 8:',
  adminFellowModule9DateText: 'Data Módulo 9:',
  adminFellowModule10DateText: 'Data Módulo 10:',
  adminFellowModule11DateText: 'Data Módulo 11:',
  adminFellowModule12DateText: 'Data Módulo 12:',
  adminFellowModule13DateText: 'Data Módulo 13:',
  adminFellowModule14DateText: 'Data Módulo 14:',
  adminFellowModule15DateText: 'Data Módulo 15:',
  adminFellowModule16DateText: 'Data Módulo 16:',
  adminFellowModule17DateText: 'Data Módulo 17:'
}

export const textsResources = {
  ...rdEventsResources,
  ...headerTextsResources,
  ...talkToExpertFormResources,
  ...homeTextsResources,
  ...harmonizeTextsResources,
  ...fellowTextsResources,
  ...footerTextsResources,
  ...adminTextsResources
}