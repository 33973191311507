import React from "react";
import { Navigate } from 'react-router-dom';

import { useAuth } from "../../hooks/useAuth";

import { admin } from "../../routes/routeMap";

export default function RequireAuth({ children }) {
  const { authed } = useAuth();

  return authed === true ? children : <Navigate to={admin} replace />;
}
