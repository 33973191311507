import React from "react";


import { red1, red2, white } from "../../../styles/colors";
import { Col, Row } from "../../../styles/grid";
import { Image, ImageBox, Text } from '../../../styles/common';

import { getResource } from '../../../utils/constants';
import px2vw from "../../../utils/responsiveness/px2vw";

export default function HomeMobile({ country }) {
  return (
    <Col alignCenter height="auto" padtop={px2vw(150)}>
      {/* Session 1 */}
      <ImageBox 
        width={px2vw(1400)}
        height={px2vw(1000)}
        boxshadow={`inset 0px -50px 70px 10px ${white}`}
      >
        <Image src={getResource('mobileHomeSession1Image')} hasInnerShadow />
      </ImageBox>
      <ImageBox width={px2vw(1000)} height={px2vw(150)} margtop={px2vw(-80)} margbottom={px2vw(10)}>
        <Image src={getResource('logoImageBlack')} />
      </ImageBox>
      <Col margtop={px2vw(80)} margleft={px2vw(50)} margright={px2vw(30)} width="auto" height="auto">
        <Text fontSize={px2vw(62)}>
          {getResource('homeSession1Text', country)}
        </Text>
        <Row spaceAround={country === 'br'} alignCenter={country !== 'br'} margtop={px2vw(100)} height="auto">
          <ImageBox width={px2vw(580)} height={px2vw(180)}>
            <Image src={getResource('homeSession1HarmonizeImage')} />
          </ImageBox>
          {country === 'br' && (
            <ImageBox width={px2vw(480)} height={px2vw(200)}>
              <Image src={getResource('homeSession1FellowImage')} />
            </ImageBox>
          )}
        </Row>
      </Col>
      {/* Session 2 */}
      <Col alignStart width="auto">
        <Row justifyStart margtop={px2vw(160)}>
          <ImageBox 
            width={px2vw(640)}
            height={px2vw(840)} 
            margbottom={px2vw(10)}
          >
            <Image src={getResource('homeSession2DrLucasImage')} />
          </ImageBox>
          <Col width="auto" margleft={px2vw(20)}>
            <Row width="auto">
              <ImageBox width={px2vw(80)} height={px2vw(80)}>
                <Image src={getResource('homeSession2InstagramImage')} />
              </ImageBox>
              <Text fontSize={px2vw(40)} gradient={`linear-gradient(${red1}, ${red2})`} margtop={px2vw(12)} margleft={px2vw(10)}>
                {getResource('homeSession2InstagramText')}
              </Text>
            </Row>
            <Text fontSize={px2vw(55)} width={px2vw(650)} margtop={px2vw(60)}>
              {getResource('homeSession2Bio1Text', country)}
            </Text>          
          </Col>
        </Row>
        <Col alignCenter width="auto">
          <Text fontSize={px2vw(55)} width={px2vw(1300)} margtop={px2vw(40)}>
            {getResource('homeSession2Bio2Text', country)}
          </Text>   
          <Text fontSize={px2vw(55)} width={px2vw(1300)} margtop={px2vw(40)}>
            {getResource('homeSession2Bio3Text', country)}
          </Text>
        </Col>
      </Col>
      <Row 
        margtop={px2vw(country === 'br' ? -1950 : -1870)}
        justifyEnd
        zIndex="-1"
      >
        <ImageBox width={px2vw(1100)} height={px2vw(1100)}>
          <Image src={getResource('homeCircleRedRightMobileImage')} />
        </ImageBox>
      </Row>
      <Row 
        margtop={px2vw(500)}
        margleft={px2vw(-760)}
        justifyCenter 
        zIndex="-1"
      >
        <ImageBox width={px2vw(600)} height={px2vw(700)}>
          <Image src={getResource('homeCircleRedLeftMobileImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 */}
      <Col alignStart margtop={px2vw(-70)}>
        <Text fontWeight="bold" fontSize={px2vw(80)} margleft={px2vw(80)} margbottom={px2vw(20)}>
          {getResource('homeSession3TitleText')}
        </Text>
      </Col>
      {/* Session 3 - Sec 1 */}
      <Col alignCenter margtop={px2vw(40)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec1Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec1Text', country)}
        </Text>
        <ImageBox width={px2vw(700)} height={px2vw(180)} margtop={px2vw(80)}>
          <Image src={getResource('homeSession3Sec1TextImage')} />
        </ImageBox>
      </Col>
      <Row 
        margtop={px2vw(-1100)}
        margbottom={px2vw(-400)}
        justifyEnd
        zIndex="-1"
      >
        <ImageBox width={px2vw(1100)} height={px2vw(1500)}>
          <Image src={getResource('homeCircleYellowRightMobileImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 2 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec2Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec2Text', country)}
        </Text>
      </Col>
      <Row 
        margtop={px2vw(-300)}
        margbottom={px2vw(-400)}
        justifyStart
        zIndex="-1"
      >
        <ImageBox width={px2vw(500)} height={px2vw(700)}>
          <Image src={getResource('homeCircleYellowLeftMobileImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 3 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec3Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec3Text', country)}
        </Text>
      </Col>
      <Row 
        margtop={px2vw(-700)}
        margbottom={px2vw(-400)}
        justifyCenter
        zIndex="-1"
      >
        <ImageBox width={px2vw(1100)} height={px2vw(1100)}>
          <Image src={getResource('homeCircleRedImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 4 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec4Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec4Text', country)}
        </Text>
      </Col>
      {/* Session 3 - Sec 5 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec5Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec5Text', country)}
        </Text>
      </Col>
      <Row 
        margtop={px2vw(-300)}
        margbottom={px2vw(-800)}
        justifyStart
        zIndex="-1"
      >
        <ImageBox width={px2vw(900)} height={px2vw(1100)}>
          <Image src={getResource('homeCircleRedLeftMobileImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 6 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec6Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec6Text', country)}
        </Text>
        <ImageBox width={px2vw(700)} height={px2vw(180)} margtop={px2vw(80)}>
          <Image src={getResource('homeSession3Sec6TextImage')} />
        </ImageBox>
      </Col>
      {/* Session 3 - Sec 7 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec7Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec7Text', country)}
        </Text>
      </Col>
      {/* Session 3 - Sec 8 */}
      <Col alignCenter margtop={px2vw(130)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec8Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec8Text', country)}
        </Text>
      </Col>
      {/* Session 3 - Sec 9 */}
      <Col alignCenter margtop={px2vw(130)} margbottom={px2vw(150)}>
        <ImageBox width={px2vw(1210)} height={px2vw(890)} >
          <Image src={getResource('homeSession3Sec9Image')} />
        </ImageBox>
        <Text fontSize={px2vw(55)} width="90%" margtop={px2vw(60)} margleft={px2vw(30)}>
          {getResource('homeSession3Sec9Text', country)}
        </Text>
        <ImageBox width={px2vw(700)} height={px2vw(180)} margtop={px2vw(80)}>
          <Image src={getResource('homeSession3Sec9TextImage')} />
        </ImageBox>
      </Col>
    </Col>
  );
}
