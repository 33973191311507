import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import InterBold from '../assets/fonts/Inter/Inter-Bold.ttf';
import InterRegular from '../assets/fonts/Inter/Inter-Regular.ttf';
import OverlockBlack from '../assets/fonts/Overlock/Overlock-Black.ttf';
import OverlockBlackItalic from '../assets/fonts/Overlock/Overlock-BlackItalic.ttf';
import OverlockBold from '../assets/fonts/Overlock/Overlock-Bold.ttf';
import OverlockRegular from '../assets/fonts/Overlock/Overlock-Regular.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: InterBold;
    src: url(${InterBold});
  }

  @font-face {
    font-family: InterRegular;
    src: url(${InterRegular});
  }

  @font-face {
    font-family: OverlockBlack;
    src: url(${OverlockBlack});
  }

  @font-face {
    font-family: OverlockBlackItalic;
    src: ) url(${OverlockBlackItalic});
  }

  @font-face {
    font-family: OverlockBold;
    src: url(${OverlockBold});
  }

  @font-face {
    font-family: OverlockRegular;
    src: url(${OverlockRegular});
  }
`;
