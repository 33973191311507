import React, { useState, useEffect } from 'react';

import { Text, Image, ImageBox, RouletteContainer, RouletteContent, RouletteButton, RouletteItemBox } from '../../styles/common';

import px2vw from '../../utils/responsiveness/px2vw';
import { getResource } from '../../utils/constants';
import { white } from '../../styles/colors';

export default function Roulette({ country, methodType, isMobile = false }) {
  const methodTypeName = methodType.charAt(0).toUpperCase() + methodType.slice(1);
  const [selectedRouletteItem, setSelectedRouletteItem] = useState(`1${methodTypeName}`);
  const rouletteImageSize = isMobile ? px2vw(1300) : px2vw(400);
  const rouletteButtonWidth = isMobile ? px2vw(250) : px2vw(80);
  const rouletteButtonHeight = isMobile ? px2vw(180) : px2vw(70);
  const rouletteDeviceType = isMobile ? 'mobile' : 'web';

  function rouletteClick(selectedItem) {
    setSelectedRouletteItem(`${selectedItem}${methodTypeName}`);
  }

  useEffect(() => { 
    setSelectedRouletteItem(`1${methodTypeName}`); 
  }, [methodTypeName])

  return (
    <RouletteContainer isMobile={isMobile}>
      <RouletteContent isMobile={isMobile}>
        <ImageBox width={rouletteImageSize} height={rouletteImageSize} position="absolute">
          {/* Harmonize */}
          <Image isInvisible={selectedRouletteItem !== '1Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage1Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '2Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage2Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '3Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage3Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '4Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage4Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '5Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage5Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '6Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage6Harmonize`)} />
          <Image isInvisible={selectedRouletteItem !== '7Harmonize'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage7Harmonize`)} />
          {/* Advanced */}
          <Image isInvisible={selectedRouletteItem !== '1Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage1Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '2Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage2Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '3Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage3Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '4Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage4Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '5Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage5Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '6Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage6Advanced`)} />
          <Image isInvisible={selectedRouletteItem !== '7Advanced'} src={getResource(rouletteDeviceType + `HarmonizeSession3RouletteImage7Advanced`)} />
        </ImageBox>
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(200) : px2vw(55)} 
          left={isMobile ? px2vw(640) : px2vw(195)}
          onClick={() => rouletteClick('1')}
        />
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(470) : px2vw(135)} 
          left={isMobile ? px2vw(860) : px2vw(265)}
          onClick={() => rouletteClick('2')}
        />
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(730) : px2vw(220)} 
          left={isMobile ? px2vw(830) : px2vw(260)}
          onClick={() => rouletteClick('3')}
        />      
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(950) : px2vw(280)} 
          left={isMobile ? px2vw(550) : px2vw(170)}
          onClick={() => rouletteClick('4')}
        />                     
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(790) : px2vw(240)} 
          left={isMobile ? px2vw(220) : px2vw(60)}
          onClick={() => rouletteClick('5')}
        />
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(510) : px2vw(150)} 
          left={isMobile ? px2vw(140) : px2vw(40)}
          onClick={() => rouletteClick('6')}
        />
        <RouletteButton 
          width={rouletteButtonWidth} 
          height={rouletteButtonHeight} 
          top={isMobile ? px2vw(220) : px2vw(60)} 
          left={isMobile ? px2vw(320) : px2vw(95)}
          onClick={() => rouletteClick('7')}
        />                   
      </RouletteContent>
      <RouletteItemBox 
        width={isMobile ? px2vw(1100) : px2vw(445)} 
        height={isMobile ? 'auto' : px2vw(200)}
        margleft={isMobile ? 0 : px2vw(40)}
        padding={isMobile ? px2vw(70) : px2vw(15)}
        isMobile={isMobile}
      >
        <Text color={white} fontWeight="bold" fontSize={isMobile ? px2vw(70) : px2vw(15)}>{getResource(`harmonizeSession3RouletteTitleText${selectedRouletteItem}`, country)}</Text>
        <Text color={white} fontSize={isMobile ? px2vw(57) : px2vw(12)} margtop={isMobile ? px2vw(50) : px2vw(10)}>{getResource(`harmonizeSession3RouletteText${selectedRouletteItem}`, country)}</Text>
        <Text color={white} fontSize={isMobile ? px2vw(57) : px2vw(12)} margtop={isMobile ? px2vw(50) : px2vw(10)}>{getResource(`harmonizeSession3RouletteTextA${selectedRouletteItem}`, country)}</Text>    
      </RouletteItemBox>
    </RouletteContainer>
  )
}
