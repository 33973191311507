import React from "react";

import { black, white, yellow, yellow2 } from "../../../styles/colors";
import { Row, Col } from "../../../styles/grid";
import { Image, ImageBox, ModuleDateBox, ReasonsBox, TalkToExpertButton, Text, TimelineBox, FellowFooterBox } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

export default function FellowSpecializationWeb({ handler }) {
  return (
    <Col>
      {/* Session 1 */}
      <Row relative margtop={px2vw(80)}>
        <ImageBox width={px2vw(1366)} height={px2vw(670)} absolute>
          <Image src={getResource('webFellowSession1Image')} />
        </ImageBox>
        <Col absolute>
          <ImageBox width={px2vw(300)} height={px2vw(130)} margtop={px2vw(80)} margleft={px2vw(150)}>
            <Image src={getResource('fellowSession1FellowImage')} />
          </ImageBox>
          <Text 
            margtop={px2vw(40)}
            margleft={px2vw(100)}
            color={black}
            width={px2vw(420)}
            fontSize={px2vw(25)}
          >
            {getResource('webFellowSession1Text1')}
          </Text>
          <Text 
            margtop={px2vw(20)}
            margleft={px2vw(100)}
            color={black}
            width={px2vw(370)}
            fontSize={px2vw(12)}
          >
            {getResource('fellowSession1Text2')}
          </Text>
          <Row width="auto" justifyStart margtop={px2vw(30)} margleft={px2vw(100)}>
            <TalkToExpertButton fellow inactive
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text gradient={`linear-gradient(${yellow}, ${yellow2})`} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession1ButtonText')}</Text>
            </TalkToExpertButton>
          </Row>
        </Col>
      </Row>   
      <Row 
        margtop={px2vw(-150)}
        justifyEnd
        zIndex="1"
      >
        <ImageBox width={px2vw(500)} height={px2vw(500)}>
          <Image src={getResource('webFellowCircleYellowRightImage')} />
        </ImageBox>
      </Row>
      {/* Session 2 */}
      <Col margtop={px2vw(-320)} width="auto" margleft={px2vw(130)}>
        <Text
          margtop={px2vw(20)}
          width={px2vw(500)}
          fontSize={px2vw(30)}
          fontWeight="bold"
        >
          {getResource('fellowSession2TitleText1')}
        </Text>
        <Text
          margtop={px2vw(5)}
          gradient={`linear-gradient(${yellow}, ${yellow2})`}
          width={px2vw(500)}
          fontSize={px2vw(30)}
          fontWeight="bold"
        >
          {getResource('fellowSession2TitleText2')}
        </Text>
        <Text
          margtop={px2vw(30)}
          width={px2vw(950)}
          fontSize={px2vw(20)}
        >
          {getResource('fellowSession2Text1')}
        </Text>
        <Text
          margtop={px2vw(20)}
          width={px2vw(950)}
          fontSize={px2vw(20)}
        >
          {getResource('fellowSession2Text2')}
        </Text>
        <Text
          margtop={px2vw(20)}
          width={px2vw(950)}
          fontSize={px2vw(20)}
        >
          {getResource('fellowSession2Text3')}
        </Text>
        <Text 
          margtop={px2vw(70)}
          width={px2vw(420)}
          fontSize={px2vw(23)}
          fontWeight="bold"
        >
          {getResource('fellowSession2Text4')}
        </Text>
      </Col>
      <Row alignCenter margtop={px2vw(30)}>
        <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetModules('previous')} zIndex="3">
          <Image src={getResource('fellowArrowLeftImage')} />
        </ImageBox>
        <ImageBox margleft={px2vw(-20)} width={px2vw(325)} height={px2vw(450)} zIndex="2">
          <Image isInvisible={handler.modules[0] !== 1} src={getResource(`fellowSession2Modules1Image`)} />
          <Image isInvisible={handler.modules[0] !== 2} src={getResource(`fellowSession2Modules2Image`)} />
          <Image isInvisible={handler.modules[0] !== 3} src={getResource(`fellowSession2Modules3Image`)} />
          <Image isInvisible={handler.modules[0] !== 4} src={getResource(`fellowSession2Modules4Image`)} />
          <Image isInvisible={handler.modules[0] !== 5} src={getResource(`fellowSession2Modules5Image`)} />
          <Image isInvisible={handler.modules[0] !== 6} src={getResource(`fellowSession2Modules6Image`)} />
          <Image isInvisible={handler.modules[0] !== 7} src={getResource(`fellowSession2Modules7Image`)} />
          <Image isInvisible={handler.modules[0] !== 8} src={getResource(`fellowSession2Modules8Image`)} />
          <Image isInvisible={handler.modules[0] !== 9} src={getResource(`fellowSession2Modules9Image`)} />
          <Image isInvisible={handler.modules[0] !== 10} src={getResource(`fellowSession2Modules10Image`)} />
          <Image isInvisible={handler.modules[0] !== 11} src={getResource(`fellowSession2Modules11Image`)} />
          <Image isInvisible={handler.modules[0] !== 12} src={getResource(`fellowSession2Modules12Image`)} />
          <Image isInvisible={handler.modules[0] !== 13} src={getResource(`fellowSession2Modules13Image`)} />
          <Image isInvisible={handler.modules[0] !== 14} src={getResource(`fellowSession2Modules14Image`)} />
          <Image isInvisible={handler.modules[0] !== 15} src={getResource(`fellowSession2Modules15Image`)} />
          <Image isInvisible={handler.modules[0] !== 16} src={getResource(`fellowSession2Modules16Image`)} />
          <Image isInvisible={handler.modules[0] !== 17} src={getResource(`fellowSession2Modules17Image`)} />
          <Image isInvisible={handler.modules[0] !== 18} src={getResource(`fellowSession2Modules18Image`)} />
        </ImageBox>
        <ImageBox margleft={px2vw(40)} width={px2vw(325)} height={px2vw(450)} zIndex="2">
          <Image src={getResource(`fellowSession2Modules${handler.modules[1]}Image`)} />
        </ImageBox>
        <ImageBox margleft={px2vw(40)} width={px2vw(325)} height={px2vw(450)} zIndex="2">
          <Image src={getResource(`fellowSession2Modules${handler.modules[2]}Image`)} />
        </ImageBox>
        <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetModules('forward')} margleft={px2vw(-20)} zIndex="3">
          <Image src={getResource('fellowArrowRightImage')} />
        </ImageBox>
      </Row>
      <Row justifyCenter margtop={px2vw(30)} zIndex="2">
        <TalkToExpertButton fellow
          width={px2vw(320)}
          onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
        >
          <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession2ButtonText')}</Text>
        </TalkToExpertButton>
      </Row>
      <Row 
        margtop={px2vw(-485)}
        justifyStart
        zIndex="1"
      >
        <ImageBox width={px2vw(460)} height={px2vw(500)}>
          <Image src={getResource('webFellowCircleYellowLeftImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 */}
      <Row relative margtop={px2vw(15)}>
        <ImageBox width={px2vw(1366)} height={px2vw(220)} absolute>
          <Image src={getResource('webFellowSession3Image')} />
        </ImageBox>
        <Col absolute alignCenter>
          <Text 
            color={black}
            width={px2vw(990)}
            fontSize={px2vw(30)}
            textAlign
            fontWeight="bold"
            font="overlock"
          >
            {getResource('webFellowSession3Text1')}
          </Text>
        </Col>
      </Row>
      <Col margtop={px2vw(50)} alignCenter>
        <Text 
          color={black}
          fontSize={px2vw(30)}
          textAlign
          fontWeight="bold"
        >
          {getResource('fellowSession3Text2')}
        </Text>
        <Row justifyCenter margtop={px2vw(50)}>
          <ReasonsBox fellow margleft={px2vw(40)}>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More1Text')}</Text>
          </ReasonsBox>
          <ReasonsBox fellow>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More2Text')}</Text>
          </ReasonsBox>
          <ReasonsBox fellow>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More3Text')}</Text>
          </ReasonsBox>
        </Row>
        <Row>
          <ReasonsBox fellow margleft={px2vw(40)}>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More4Text')}</Text>
          </ReasonsBox>
          <ReasonsBox fellow>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More5Text')}</Text>
          </ReasonsBox>
          <ReasonsBox fellow>
            <Text color={black} fontWeight="bold">{getResource('fellowSession3More6Text')}</Text>
          </ReasonsBox>
        </Row>
        <Row>
          <TalkToExpertButton fellow
            width={px2vw(300)}
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession3ButtonText')}</Text>
          </TalkToExpertButton>
        </Row>
      </Col>
      {/* Session 4 */}
      <Col margtop={px2vw(50)}>
        <Row justifyCenter>
          <Text 
            color={black}
            fontSize={px2vw(30)}
            textAlign
            fontWeight="bold"
          >
            {getResource('fellowSession4Text1')}
          </Text>
        </Row>
        <Row justifyStart margtop={px2vw(25)}>
          <ImageBox width={px2vw(500)} height={px2vw(700)}>
            <Image src={getResource('fellowSession4Image')} />
          </ImageBox>
          <TimelineBox isOpenned={handler.isModulesExpanded}>
            <Col justifyStart>
              {handler.moduleDatesFellowSpecializationPart1.map((module, idx) => {
                return (
                  <Row key={`modules-${idx + 1}`} height="auto" margtop={px2vw(idx === 0 ? 0 : 15)}>
                    <ModuleDateBox>
                      <Text fontWeight="bold" textAlign>
                        {`${module.val.day_1} E ${module.val.day_2}`}
                        <br />
                        {module.val.month}
                        <br />
                        {module.val.year}
                      </Text>
                    </ModuleDateBox>
                    <Col margleft={px2vw(30)}>
                      <Text fontSize={px2vw(20)}>{getResource(`fellowSession4Module${idx + 1}Text1`)}</Text>
                      <Text fontSize={px2vw(15)} margtop={px2vw(10)}>{getResource(`fellowSession4Module${idx + 1}Text2`)}</Text>
                    </Col>
                  </Row>
                );
              })}
              {!handler.isModulesExpanded && (
                <Row margtop={px2vw(40)}>
                  <TalkToExpertButton fellow
                    width={px2vw(200)}
                    onClick={() => handler.handleSetIsModulesExpanded(true)}
                  >
                    <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession4Button1Text')}</Text>
                  </TalkToExpertButton>
                </Row>
              )}
              {handler.isModulesExpanded && (
                <>
                  {handler.moduleDatesFellowSpecializationPart2.map((module, idx) => {
                    return (
                      <Row key={`modules-${idx + 7}`} height="auto" margtop={px2vw(15)}>
                        <ModuleDateBox>
                          <Text fontWeight="bold" textAlign>
                            {`${module.val.day_1} E ${module.val.day_2}`}
                            <br />
                            {module.val.month}
                            <br />
                            {module.val.year}
                          </Text>
                        </ModuleDateBox>
                        <Col margleft={px2vw(30)}>
                          <Text fontSize={px2vw(20)}>{getResource(`fellowSession4Module${idx + 7}Text1`)}</Text>
                          <Text fontSize={px2vw(15)} margtop={px2vw(10)}>{getResource(`fellowSession4Module${idx + 7}Text2`)}</Text>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row height="auto" margtop={px2vw(15)}>
                    <ModuleDateBox>
                      <Text fontWeight="bold" textAlign>{getResource('fellowSession4Module18Date')}</Text>
                    </ModuleDateBox>
                    <Col margleft={px2vw(30)}>
                      <Text fontSize={px2vw(20)}>{getResource('fellowSession4Module18Text1')}</Text>
                      <Text fontSize={px2vw(15)} margtop={px2vw(10)}>{getResource('fellowSession4Module18Text2')}</Text>
                    </Col>
                  </Row>
                  <Row margtop={px2vw(40)}>
                    <TalkToExpertButton fellow
                      width={px2vw(200)}
                      onClick={() => handler.handleSetIsModulesExpanded(false)}
                    >
                      <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession4Button2Text')}</Text>
                    </TalkToExpertButton>
                  </Row>
                </>
              )}
            </Col>
          </TimelineBox>
        </Row>
      </Col>
      {/* Session 5 */}
      <Col margtop={px2vw(30)} alignCenter>
        <Text gradient={`linear-gradient(${yellow}, ${yellow2})`} fontSize={px2vw(15)} cursor="pointer" underline>
          <a href={handler.scheduleDocURLPDFFellowSpecialization} target="_blank" rel="noreferrer">
            {getResource('fellowSession5DownloadSchedule')}
          </a>
        </Text>
        <Row margtop={px2vw(40)}>
          <ImageBox width={px2vw(400)} height={px2vw(400)}>
            <Image src={getResource('fellowSession5Image')} />
          </ImageBox>
        </Row>
        <Row margtop={px2vw(30)}>
          <Text fontSize={px2vw(25)} fontWeight="bold" lineThrough>
            {getResource('fellowSession5OldAmount')}
          </Text>
        </Row>
        <Row margtop={px2vw(20)}>
          <Text fontSize={px2vw(22)} fontWeight="bold" textAlign>
            {getResource('webFellowSession5NewAmount')}
          </Text>
        </Row>
        <Row margtop={px2vw(20)} zIndex="2">
          <TalkToExpertButton fellow
            width={px2vw(320)}
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession5ButtonText')}</Text>
          </TalkToExpertButton>
        </Row>
        <Row 
          margtop={px2vw(-420)}
          justifyEnd
          zIndex="1"
        >
          <ImageBox width={px2vw(500)} height={px2vw(500)}>
            <Image src={getResource('webFellowCircleYellowRight2Image')} />
          </ImageBox>
        </Row>
      </Col>
      {/* Session 6 */}
      <Col margtop={px2vw(-20)} alignCenter>
        <Text fontWeight="bold" fontSize={px2vw(23)}>{getResource('fellowSession6Title')}</Text>
        <Row alignCenter margtop={px2vw(30)}>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetStudents('previous')} zIndex="2">
            <Image src={getResource('fellowArrowLeftImage')} />
          </ImageBox>
          <ImageBox margleft={px2vw(-20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image isInvisible={handler.students[0] !== 1} src={getResource(`fellowSession6Students1Image`)} />
            <Image isInvisible={handler.students[0] !== 2} src={getResource(`fellowSession6Students2Image`)} />
            <Image isInvisible={handler.students[0] !== 3} src={getResource(`fellowSession6Students3Image`)} />
            <Image isInvisible={handler.students[0] !== 4} src={getResource(`fellowSession6Students4Image`)} />
          </ImageBox>
          <ImageBox margleft={px2vw(20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image src={getResource(`fellowSession6Students${handler.students[1]}Image`)} />
          </ImageBox>
          <ImageBox margleft={px2vw(20)} width={px2vw(300)} height={px2vw(450)} zIndex="1">
            <Image src={getResource(`fellowSession6Students${handler.students[2]}Image`)} />
          </ImageBox>
          <ImageBox width={px2vw(40)} height={px2vw(40)} cursor="pointer" onClick={() => handler.handleSetStudents('forward')} margleft={px2vw(-20)} zIndex="2">
            <Image src={getResource('fellowArrowRightImage')} />
          </ImageBox>
        </Row>
        <Row justifyCenter margtop={px2vw(10)}>
          <TalkToExpertButton width={px2vw(220)} margtop={px2vw(20)} fellow
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession6ButtonText')}</Text>
          </TalkToExpertButton>
        </Row>
        <FellowFooterBox>
          <Row alignCenter spaceAround>
            <Col width="auto">
              <Text color={white} fontWeight="bold" fontSize={px2vw(23)}>{getResource('fellowSession6FooterText1')}</Text>
              <Text color={white} margtop={px2vw(10)} fontSize={px2vw(14)}>{getResource('fellowSession6FooterText2')}</Text>
            </Col>
            <TalkToExpertButton width={px2vw(220)} fellow inactive
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text gradient={`linear-gradient(${yellow}, ${yellow2})`} fontWeight="bold" fontSize={px2vw(15)}>{getResource('fellowSession6FooterButtonText')}</Text>
            </TalkToExpertButton>
          </Row>
        </FellowFooterBox>
      </Col>
    </Col>
  );
}
