import React from "react";

import { black, white, red1, gray1 } from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';
import { Image, ImageBox, ReasonsBox, TalkToExpertButton, Text, HarmonizeFooterBox } from '../../../styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getResource } from '../../../utils/constants';

import ClassTimer from "../../../components/ClassTimer";
import Roulette from "../../../components/Roulette";

export default function HarmonizeMethodMobile({ handler }) {
  let showTimer = true;
  let headerText1 = getResource('headerClassHarmonizeText1', handler.country);
  let headerText2 = getResource('headerClassHarmonizeText2', handler.country);
  let headerText3 = getResource('headerClassHarmonizeText3', handler.country);

  if (handler.country === 'pt' & handler.harmonizeMethodWaitingPT) {
    headerText1 = getResource('headerClassHarmonizeWaitingMobileText', handler.country);
    headerText2 = null;
    headerText3 = null;
    showTimer = false;
  } else {
    const availableSeats = handler.country === 'pt' ?  handler.availableSeatsHarmonizeMethodPT : handler.availableSeatsHarmonizeMethodBR;
    headerText2 = headerText2?.replace('@@SEATS@@', availableSeats + (availableSeats === 1 ? ' VAGA' : ' VAGAS'));
  }

  return (
    <Col height="auto">
      {/* Timer */}
      <Col justifyCenter margtop={px2vw(270)} padtop={px2vw(50)} padbottom={px2vw(50)} background={white} height="auto">
        <Col alignCenter>
          <Text textAlign fontSize={px2vw(90)} margbottom={px2vw(10)} color={black}>{headerText1}</Text>
          {headerText2 && <Text fontSize={px2vw(100)} margbottom={px2vw(10)} fontWeight="bold" color={black}>&nbsp;{headerText2}</Text>}
          {headerText3 && <Text fontSize={px2vw(90)} color={black}>&nbsp;{headerText3}</Text>}
        </Col>
        {showTimer && (
          <Row margtop={px2vw(30)} padleft={px2vw(25)}>
            <ClassTimer timer={handler.country === 'br' ? handler.timerHarmonizeMethodBR : handler.timerHarmonizeMethodPT} textColor={black} isMobile />
          </Row>
        )}
        {!showTimer && (
          <Row margtop={px2vw(60)} padleft={px2vw(25)}>
            <TalkToExpertButton 
              width={px2vw(1000)} height={px2vw(130)} borderradius={px2vw(100)}
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)} noBorder={handler.country === 'br'}>
              <Text color={gray1} fontSize={px2vw(50)}>{getResource('talkToExpertText')}</Text>
            </TalkToExpertButton>
          </Row>
        )}
      </Col>
      <Col background={black}>
        {/* Session 1 */}
        <ImageBox width={px2vw(1365)} height={px2vw(1100)}>
          <Image src={getResource('mobileHarmonizeSession1Image', handler.country)} />
        </ImageBox>
        <Text 
          margtop={px2vw(80)}
          margleft={px2vw(50)}
          color={white}
          fontSize={px2vw(70)}
        >
          {getResource('mobileHarmonizeSession1Text1', handler.country)}
        </Text>
        <Text 
          margtop={px2vw(80)}
          margleft={px2vw(50)}
          color={white}
          fontSize={px2vw(57)}
        >
          {getResource('harmonizeSession1Text2', handler.country)}
        </Text>
        <Row justifyCenter margtop={px2vw(80)} zIndex="2">
          <TalkToExpertButton harmonize width={px2vw(1100)} height={px2vw(150)} borderradius={px2vw(100)}
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession1ButtonText', handler.country)}</Text>
          </TalkToExpertButton>
        </Row>
        <Row 
          margtop={px2vw(-100)}
          justifyStart
          zIndex="1"
          width="auto"
        >
          <ImageBox width={px2vw(800)} height={px2vw(800)}>
            <Image src={getResource('mobileHarmonizeCircleRedLeftImage')} />
          </ImageBox>
        </Row>
        {/* Session 2 */}
        <Col margtop={px2vw(-620)} alignCenter justifyCenter zIndex="2">
          <ImageBox width={px2vw(1150)} height={px2vw(1350)}
            zIndex="2"> 
            <Image src={getResource('mobileHarmonizeSession2DrLucasExperienceImage')} />
          </ImageBox>
          <Row 
            margtop={px2vw(-300)}
            justifyEnd
            zIndex="1"
          >
            <ImageBox width={px2vw(400)} height={px2vw(550)}>
              <Image src={getResource('mobileHarmonizeCircleRedRightImage')} />
            </ImageBox>
          </Row>
          <Col margtop={px2vw(-300)} margleft={px2vw(50)} alignCenter zIndex="2">
            <Text
              margtop={px2vw(20)}
              color={white}
              fontSize={px2vw(70)}
              width={px2vw(1300)}
              fontWeight="bold"
            >
              {getResource('harmonizeSession2TitleText', handler.country)}
            </Text>
            <Text
              margtop={px2vw(40)}
              color={white}
              fontSize={px2vw(57)}
              width={px2vw(1300)}
            >
              {getResource('harmonizeSession2Text1', handler.country)}
            </Text>
            <Text
              margtop={px2vw(50)}
              color={white}
              fontSize={px2vw(57)}
              width={px2vw(1275)}
            >
              {getResource('harmonizeSession2Text2', handler.country)}
            </Text>
            <Row margtop={px2vw(100)}>
              <TalkToExpertButton width={px2vw(1100)} height={px2vw(150)} borderradius={px2vw(100)} harmonize
                onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
              >
                <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession2ButtonText', handler.country)}</Text>
              </TalkToExpertButton>
            </Row>
          </Col>
        </Col>
        {/* Session 3 */}
        {/* Methods Type - PT */}
        {handler.country === 'pt' && (
          <Col alignCenter margtop={px2vw(170)}>
            <Text color={white} fontWeight="bold" fontSize={px2vw(70)}>{getResource(handler.methodType === 'harmonize' ? 'harmonizeSession3MethodHarmonizeTitleText' : 'harmonizeSession3MethodAdvancedTitleText', handler.country)}</Text>
            <Text width={px2vw(1300)} margtop={px2vw(50)} color={white} fontSize={px2vw(57)}>{getResource(handler.methodType === 'harmonize' ? 'harmonizeSession3MethodHarmonizeText' : 'harmonizeSession3MethodAdvancedText', handler.country)}</Text>
            <TalkToExpertButton harmonize inactive={handler.methodType === 'advanced'}
              width={px2vw(1100)} height={px2vw(150)} borderradius={px2vw(100)}
              margtop={px2vw(110)}
              onClick={() => handler.handleSetMethodType('harmonize')}
            >
              <Text color={handler.methodType === 'advanced' ? red1 : white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession3Button1Text', handler.country)}</Text>
            </TalkToExpertButton>
            <TalkToExpertButton margleft={px2vw(20)} harmonize inactive={handler.methodType === 'harmonize'}
              width={px2vw(1100)} height={px2vw(150)} borderradius={px2vw(100)}
              margtop={px2vw(80)}
              onClick={() => handler.handleSetMethodType('advanced')}
            >
              <Text color={handler.methodType === 'harmonize' ? red1 : white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession3Button2Text', handler.country)}</Text>
            </TalkToExpertButton>
            <Row margtop={px2vw(80)}>
              <ImageBox width={px2vw(1200)} height={px2vw(1150)}>
                <Image isInvisible={handler.methodType !== 'harmonize'} src={getResource('harmonizeSession3MethodHarmonizeImage', handler.country)} />
                <Image isInvisible={handler.methodType !== 'advanced'} src={getResource('harmonizeSession3MethodAdvancedImage', handler.country)} />
              </ImageBox>
            </Row>
          </Col>
        )}
        <Col margtop={px2vw(100)} alignCenter>
          <Text color={white} fontWeight="bold" fontSize={px2vw(70)} margleft={px2vw(50)}>{getResource('harmonizeSession3LearnText', handler.country)}</Text>
          <Roulette country={handler.country} methodType={handler.methodType} isMobile />
          <Text textAlign color={white} fontSize={px2vw(57)} cursor="pointer" margtop={px2vw(90)} underline>
            <a href={handler.scheduleDownloadDoc} target="_blank" rel="noreferrer">
              {getResource('harmonizeSession3DownloadSchedule', handler.country)}
            </a>
          </Text>
          <TalkToExpertButton width={px2vw(1100)} height={px2vw(150)} 
            borderradius={px2vw(100)} margtop={px2vw(80)} harmonize
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession3ButtonText', handler.country)}</Text>
          </TalkToExpertButton>
        </Col>
        {/* Session 4 */}
        <Col alignCenter margtop={px2vw(100)}>
          <ImageBox width={px2vw(1360)} height={px2vw(1800)}>
            <Image src={getResource('mobileHarmonizeSession4DrLucasReasonsImage')} />
          </ImageBox>
          <Col alignCenter margtop={px2vw(80)}>
            <Text color={white} fontWeight="bold" fontSize={px2vw(75)}>{getResource('mobileHarmonizeSession4ReasonsTitleText', handler.country)}</Text>
            <ImageBox width={px2vw(300)} height={px2vw(85)} margleft={px2vw(940)} margtop={px2vw(-250)} margbottom={px2vw(230)}>
              <Image src={getResource('mobileHarmonizeSession4FlagsImage')} />
            </ImageBox>
            <ReasonsBox isMobile>
              <Text color={white} fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason1Text', handler.country)}</Text>
            </ReasonsBox>
            <ReasonsBox isMobile>
              <Text color={white}fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason2Text', handler.country)}</Text>
            </ReasonsBox>
            <ReasonsBox isMobile>
              <Text color={white}fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason3Text', handler.country)}</Text>
            </ReasonsBox>
            <ReasonsBox isMobile>
              <Text color={white}fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason4Text', handler.country)}</Text>
            </ReasonsBox>
            <ReasonsBox isMobile>
              <Text color={white}fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason5Text', handler.country)}</Text>
            </ReasonsBox>
            <ReasonsBox isMobile>
              <Text color={white}fontSize={px2vw(57)}>{getResource('harmonizeSession4Reason6Text', handler.country)}</Text>
            </ReasonsBox>
            <TalkToExpertButton width={px2vw(1000)} height={px2vw(150)} 
              borderradius={px2vw(100)} margtop={px2vw(20)} harmonize
              onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
            >
              <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession4ButtonText', handler.country)}</Text>
            </TalkToExpertButton>
          </Col>
        </Col>
        {/* Session 5 */}
        <Col margtop={px2vw(100)} alignCenter>
          <Text width={px2vw(1000)} color={white} textAlign fontWeight="bold" fontSize={px2vw(70)}>{getResource('harmonizeSession5Title', handler.country)}</Text>
          <Row alignCenter justifyCenter margtop={px2vw(100)} zIndex="2">
            <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetResultsMobile('previous')} zIndex="2">
              <Image src={getResource('harmonizeArrowLeftImage')} />
            </ImageBox>
            <ImageBox margleft={px2vw(-70)} width={px2vw(1200)} height={px2vw(1100)} zIndex="1">
              <Image isInvisible={handler.resultsMobile[0] !== 1} src={getResource(`mobileHarmonizeSession5Results1Image`)} />
              <Image isInvisible={handler.resultsMobile[0] !== 2} src={getResource(`mobileHarmonizeSession5Results2Image`)} />
              <Image isInvisible={handler.resultsMobile[0] !== 3} src={getResource(`mobileHarmonizeSession5Results3Image`)} />
              <Image isInvisible={handler.resultsMobile[0] !== 4} src={getResource(`mobileHarmonizeSession5Results4Image`)} />
              <Image isInvisible={handler.resultsMobile[0] !== 5} src={getResource(`mobileHarmonizeSession5Results5Image`)} />
              <Image isInvisible={handler.resultsMobile[0] !== 6} src={getResource(`mobileHarmonizeSession5Results6Image`)} />
            </ImageBox>
            <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetResultsMobile('forward')} margleft={px2vw(-70)} zIndex="2">
              <Image src={getResource('harmonizeArrowRightImage')} />
            </ImageBox>
          </Row>
        </Col>
        {/* Session 6 */}
        <Col margtop={px2vw(100)} alignCenter>
          <Text width={px2vw(1200)} color={white} textAlign fontWeight="bold" fontSize={px2vw(70)}>{getResource('harmonizeSession6Title', handler.country)}</Text>
          <Row alignCenter justifyCenter margtop={px2vw(100)} zIndex="2">
            <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetStudents('previous')} zIndex="2">
              <Image src={getResource('harmonizeArrowLeftImage')} />
            </ImageBox>
            <ImageBox margleft={px2vw(-70)} width={px2vw(1200)} height={px2vw(1900)} zIndex="1">
              <Image isInvisible={handler.students[0] !== 1} src={getResource(`mobileHarmonizeSession6Students1Image`)} />
              <Image isInvisible={handler.students[0] !== 2} src={getResource(`mobileHarmonizeSession6Students2Image`)} />
              <Image isInvisible={handler.students[0] !== 3} src={getResource(`mobileHarmonizeSession6Students3Image`)} />
              <Image isInvisible={handler.students[0] !== 4} src={getResource(`mobileHarmonizeSession6Students4Image`)} />
            </ImageBox>
            <ImageBox width={px2vw(120)} height={px2vw(120)} cursor="pointer" onClick={() => handler.handleSetStudents('forward')} margleft={px2vw(-70)} zIndex="2">
              <Image src={getResource('harmonizeArrowRightImage')} />
            </ImageBox>
          </Row>
          <TalkToExpertButton width={px2vw(1100)} height={px2vw(150)} 
            borderradius={px2vw(100)} margtop={px2vw(100)} harmonize zIndex="2" margbottom={px2vw(100)}
            onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
          >
            <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession6ButtonText', handler.country)}</Text>
          </TalkToExpertButton>
          <HarmonizeFooterBox isMobile>
            <ImageBox width="100%" height={px2vw(600)} margtop={px2vw(-600)} zIndex="1">
              <Image src={getResource('mobileHarmonizeSession6FooterImage')} />
            </ImageBox>
            <Col alignCenter>
              <Text color={white} fontWeight="bold" fontSize={px2vw(70)}>{getResource('harmonizeSession6FooterText1', handler.country)}</Text>
              <Text color={white} textAlign margtop={px2vw(50)} fontSize={px2vw(57)}>{getResource('harmonizeSession6FooterText2', handler.country)}</Text>
              <TalkToExpertButton margtop={px2vw(50)} width={px2vw(1100)} height={px2vw(150)} 
                borderradius={px2vw(100)} harmonize
                onClick={() => handler.handleSetIsTalkToExpertFormOpen(true)}
              >
                <Text color={white} fontWeight="bold" fontSize={px2vw(60)}>{getResource('harmonizeSession6FooterButtonText', handler.country)}</Text>
              </TalkToExpertButton>
            </Col>
          </HarmonizeFooterBox>
        </Col>
      </Col>
    </Col>
  );
}
