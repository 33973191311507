import { matchRoutes, useLocation } from "react-router-dom";

import { adminRoutes } from "../routes/routeMap";

export const useCurrentPath = () => {
  const location = useLocation()
  const [{ route }] = matchRoutes(adminRoutes, location)

  return route.path;
}
