import React from "react";

import { red1, red2, white } from "../../../styles/colors";
import { Col, Row } from "../../../styles/grid";
import { Image, ImageBox, Text, Timeline, TimelineCircle } from '../../../styles/common';

import { getResource } from '../../../utils/constants';
import px2vw from "../../../utils/responsiveness/px2vw";

export default function HomeWeb({ country }) {
  return (
    <Col alignCenter padtop={px2vw(80)}>
      {/* Session 1 */}
      <Row spaceBetween>
        <Col
          margleft={px2vw(68)} 
          margright={px2vw(38)} 
          margtop={px2vw(100)} 
          width="auto"
          zIndex="1"
        >
          <ImageBox width={px2vw(535)} height={px2vw(85)} margbottom={px2vw(10)}>
            <Image src={getResource('logoImageBlack')} />
          </ImageBox>
          <Text fontSize={px2vw(18)} width={px2vw(510)}>
            {getResource('homeSession1Text', country)}
          </Text>
          <Row spaceBetween={country === 'br'} alignCenter={country !== 'br'} margtop={px2vw(70)}>
            <ImageBox width={px2vw(250)} height={px2vw(70)}>
              <Image src={getResource('homeSession1HarmonizeImage')} />
            </ImageBox>
            {country === 'br' && (
              <ImageBox width={px2vw(200)} height={px2vw(80)}>
                <Image src={getResource('homeSession1FellowImage')} />
              </ImageBox>
            )}
          </Row>
        </Col>
        <ImageBox 
          margleft={px2vw(-60)}
          margtop={px2vw(-70)}
          boxshadow={`inset 50px -50px 70px 20px ${white}`}
        >
          <Image src={getResource('webHomeSession1Image')} hasInnerShadow />
        </ImageBox>
      </Row>
      {/* Session 2 */}
      <Row 
        justifyCenter 
        margtop={px2vw(-140)}
        margbottom={px2vw(-310)}
        margleft={px2vw(-50)}
        zIndex="1"
      >
        <ImageBox width={px2vw(450)} height={px2vw(450)}>
          <Image src={getResource('homeCircleRedImage')} />
        </ImageBox>
      </Row>
      <Row>
        <ImageBox 
          width={px2vw(500)}
          height={px2vw(650)} 
          margbottom={px2vw(10)}
        >
          <Image src={getResource('homeSession2DrLucasImage')} />
        </ImageBox>
        <Col 
          alignStart 
          width={px2vw(415)}
          margtop={px2vw(90)} 
          margleft={px2vw(180)}
        >
          <Text fontWeight="bold" fontSize={px2vw(40)} margbottom={px2vw(20)}>
            {getResource('homeSession2TitleText')}
          </Text>
          <Row width="auto">
            <ImageBox width={px2vw(20)} height={px2vw(20)}>
              <Image src={getResource('homeSession2InstagramImage')} />
            </ImageBox>
            <Text gradient={`linear-gradient(${red1}, ${red2})`} margtop={px2vw(2)} margleft={px2vw(10)}>
              {getResource('homeSession2InstagramText')}
            </Text>
          </Row>
          <Text fontSize={px2vw(16)} width={px2vw(390)} margtop={px2vw(30)}>
            {getResource('homeSession2Bio1Text', country)}
          </Text>          
          <Text fontSize={px2vw(16)} width={px2vw(390)} margtop={px2vw(20)}>
            {getResource('homeSession2Bio2Text', country)}
          </Text>   
          <Text fontSize={px2vw(16)} width={px2vw(400)} margtop={px2vw(20)}>
            {getResource('homeSession2Bio3Text', country)}
          </Text>
          <Row 
            margtop={px2vw(-50)}
            margbottom={px2vw(-310)}
            margleft={px2vw(300)}
            width="auto"
          >
            <ImageBox width={px2vw(225)} height={px2vw(225)}>
              <Image src={getResource('homeCircleRedImage')} />
            </ImageBox>
          </Row>
        </Col>
      </Row>
      {/* Session 3 */}
      {/* Session 3 - Sec 1 - Image Left */}
      <Row
        margtop={px2vw(40)} 
        height="auto"
        margleft={px2vw(-40)}
      >
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec1Image')} />
        </ImageBox>
        <Col width="auto" margtop={px2vw(150)} margleft={px2vw(70)} margright={px2vw(70)}>
          <TimelineCircle />
          <Timeline />
        </Col>
        <Col width="auto">
          <Text fontWeight="bold" fontSize={px2vw(40)} margbottom={px2vw(20)}>
            {getResource('homeSession3TitleText')}
          </Text>
          <Text fontSize={px2vw(16)} width={px2vw(500)} margtop={px2vw(40)} >
            {getResource('homeSession3Sec1Text', country)}
          </Text>
          <ImageBox width={px2vw(200)} height={px2vw(50)} margtop={px2vw(40)}>
            <Image src={getResource('homeSession3Sec1TextImage')} />
          </ImageBox>
        </Col>
      </Row>
      <Row 
        justifyCenter 
        margtop={px2vw(-380)}
        margbottom={px2vw(-170)}
        margleft={px2vw(100)}
        width="auto"
      >
        <ImageBox width={px2vw(550)} height={px2vw(550)}>
          <Image src={getResource('homeCircleYellowRightImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 2 - Image Right */}
      <Row
        height="auto"
        margtop={px2vw(-60)}
        margleft={px2vw(-40)}
      >
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(540)} margleft={px2vw(40)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec2Text', country)}
          </Text>
        </Col>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec2Image')} />
        </ImageBox>
      </Row>
      <Row
        justifyStart
        margtop={px2vw(-210)}
        margbottom={px2vw(-30)}
        margleft={px2vw(-40)}
      >
        <ImageBox width={px2vw(250)} height={px2vw(250)}>
          <Image src={getResource('homeCircleYellowLeftImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 3 - Image Left */}
      <Row
        height="auto"
        margtop={px2vw(-80)}
        margleft={px2vw(-40)}
      >
        <ImageBox width={px2vw(540)} height={px2vw(380)} zIndex="1">
          <Image src={getResource('homeSession3Sec3Image')} />
        </ImageBox>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(500)} margtop={px2vw(140)}>
            {getResource('homeSession3Sec3Text', country)}
          </Text>
        </Col>
      </Row>
      <Row
        justifyCenter
        margtop={px2vw(-330)}
        margbottom={px2vw(-180)}
        margleft={px2vw(-250)}
        width="auto"
      >
        <ImageBox width={px2vw(500)} height={px2vw(500)}>
          <Image src={getResource('homeCircleRedImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 4 - Image Right */}
      <Row
        height="auto"
        margtop={px2vw(-70)}
        margleft={px2vw(-40)}
      >
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(540)} margleft={px2vw(40)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec4Text', country)}
          </Text>
        </Col>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec4Image')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 5 - Image Left */}
      <Row
        height="auto"
        margtop={px2vw(-80)}
        margleft={px2vw(-40)}
      >
        <ImageBox width={px2vw(540)} height={px2vw(380)} zIndex="1">
          <Image src={getResource('homeSession3Sec5Image')} />
        </ImageBox>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(500)} margtop={px2vw(140)}>
            {getResource('homeSession3Sec5Text', country)}
          </Text>
        </Col>
      </Row>
      <Row
        justifyCenter
        margtop={px2vw(-270)}
        margbottom={px2vw(-30)}
        margleft={px2vw(-10)}
        width="auto"
      >
        <ImageBox width={px2vw(300)} height={px2vw(300)}>
          <Image src={getResource('homeCircleRedImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 6 - Image Right */}
      <Row
        height="auto"
        margtop={px2vw(-70)}
        margleft={px2vw(-40)}
      >
        <Col width="auto" alignEnd>
          <Text fontSize={px2vw(16)} width={px2vw(540)} margleft={px2vw(40)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec6Text', country)}
          </Text>
          <ImageBox width={px2vw(200)} height={px2vw(50)} margtop={px2vw(40)}>
            <Image src={getResource('homeSession3Sec6TextImage')} />
          </ImageBox>
        </Col>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <ImageBox width={px2vw(540)} height={px2vw(360)} zIndex="1">
          <Image src={getResource('homeSession3Sec6Image')} />
        </ImageBox>
      </Row>
      <Row 
        justifyCenter 
        margtop={px2vw(-230)}
        margbottom={px2vw(-320)}
        margleft={px2vw(100)}
        width="auto"
      >
        <ImageBox width={px2vw(550)} height={px2vw(550)}>
          <Image src={getResource('homeCircleYellowRightImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 7 - Image Left */}
      <Row
        height="auto"
        margtop={px2vw(-80)}
        margleft={px2vw(-40)}
      >
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec7Image')} />
        </ImageBox>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(500)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec7Text', country)}
          </Text>
        </Col>
      </Row>
      {/* Session 3 - Sec 8 - Image Right */}
      <Row
        height="auto"
        margtop={px2vw(-70)}
        margleft={px2vw(-40)}
      >
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(540)} margleft={px2vw(40)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec8Text', country)}
          </Text>
        </Col>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
          <Timeline />
        </Col>
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec8Image')} />
        </ImageBox>
      </Row>
      <Row
        justifyCenter
        margtop={px2vw(-210)}
        margbottom={px2vw(-30)}
        margleft={px2vw(-130)}
      >
        <ImageBox width={px2vw(280)} height={px2vw(280)}>
          <Image src={getResource('homeCircleYellowLeftImage')} />
        </ImageBox>
      </Row>
      {/* Session 3 - Sec 9 - Image Left*/}
      <Row
        height="auto"
        margtop={px2vw(-80)}
        margleft={px2vw(-40)}
        margbottom={px2vw(70)}
      >
        <ImageBox width={px2vw(540)} height={px2vw(380)}>
          <Image src={getResource('homeSession3Sec9Image')} />
        </ImageBox>
        <Col width="auto" margleft={px2vw(70)} margright={px2vw(70)}>
          <Timeline height={px2vw(170)}/>
          <TimelineCircle />
        </Col>
        <Col width="auto">
          <Text fontSize={px2vw(16)} width={px2vw(500)} margtop={px2vw(145)}>
            {getResource('homeSession3Sec9Text', country)}
          </Text>
          <ImageBox width={px2vw(200)} height={px2vw(55)} margtop={px2vw(40)}>
            <Image src={getResource('homeSession3Sec9TextImage')} />
          </ImageBox>
        </Col>
      </Row>
    </Col>
  );
}
