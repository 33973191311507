import React from "react";

import { white } from "../../../../styles/colors";
import { Col, Row } from "../../../../styles/grid";
import { ImageBox, Image, SaveBox, SaveInput, SaveButton, SaveSelect, Text } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';
import { onlyNumbers } from "../../../../utils/utils";

import Header from "../../../../components/Admin/Header";

import { fellowSpecialization } from "../../../../routes/routeMap";

export default function AdminFellowMobile({ handler }) {
  return (
    <Col alignCenter height="auto" padtop={px2vw(150)}>
      <Header handler={handler} />
      <Col margtop={px2vw(220)} alignCenter>
        <Row justifyCenter margbottom={px2vw(50)}>
          <ImageBox width={px2vw(900)} height={px2vw(300)} margtop={px2vw(-30)}>
            <a href={`${fellowSpecialization}`} target="_blank" rel="noreferrer">
              <Image src={getResource('adminHomeFellowImage')} />
            </a>
          </ImageBox>
        </Row>
        <SaveBox isMobile>
        <Row alignCenter>
            <Row justifyStart width="70%">
              <Text fontSize={px2vw(45)}>{getResource('adminFellowIsWaitingListActiveText')}</Text>
            </Row>
            <Row width="30%" justifyEnd>
              <SaveInput
                isMobile
                type="checkbox"
                width={px2vw(500)}
                checked={handler.fellowSpecializationWaiting}
                onClick={() => handler.handleSetFellowSpecializationWaiting(!handler.fellowSpecializationWaiting)}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(50)} spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminFellowAvailableSeatsText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="text"
                width={px2vw(500)}
                value={handler.availableSeatsFellowSpecialization}
                onChange={(e) => handler.handleSetAvailableSeatsFellowSpecialization(onlyNumbers(e.target.value))}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(50)} spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminFellowNextClassDateText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="date"
                width={px2vw(500)}
                value={handler.nextClassFellowSpecialization?.substring(0, 10)}
                onChange={(e) => handler.handleSetNextClassFellowSpecialization(e.target.value + " 09:00:00")}
              />
            </Row>
          </Row>
          <Row alignCenter margtop={px2vw(50)} spaceBetween>
            <Row justifyStart>
              <Text fontSize={px2vw(45)}>{getResource('adminFellowSchedulePDFURLText')}</Text>
            </Row>
            <Row justifyEnd>
              <SaveInput
                isMobile
                type="text"
                width={px2vw(500)}
                value={handler.scheduleDocURLPDFFellowSpecialization}
                onChange={(e) => handler.handleSetScheduleDocURLPDFFellowSpecialization(e.target.value)}
              />
            </Row>
          </Row>
          {handler.moduleDatesFellowSpecialization.map((_, idx) => {
            return (
              <>
                <Row key={`module-${idx}`} alignCenter margtop={px2vw(idx === 0 ? 20 : 50)} margbottom={px2vw(80)}>
                  <Row justifyStart>
                    <Text fontSize={px2vw(45)}>{getResource(`adminFellowModule${idx+1}DateText`)}</Text>
                  </Row>
                  <Row justifyEnd>
                    <Text fontSize={px2vw(43)}>
                      <Row alignCenter>
                        <Row justifyStart>Dia 1:</Row> 
                        <Row justifyEnd>
                          <SaveSelect 
                            isMobile
                            value={handler.moduleDatesFellowSpecialization[idx].val.day_1}
                            onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.day_1 = e.target.value}
                          >
                            {handler.selectModulesDays.map((day) => {
                              return (
                                <option value={`${day}`}>
                                  {day}
                                </option>
                              )
                            })}
                          </SaveSelect>
                        </Row>
                      </Row>
                      <Row alignCenter>
                        <Row justifyStart>Dia 2:</Row> 
                        <Row justifyEnd>
                          <SaveSelect 
                            isMobile
                            value={handler.moduleDatesFellowSpecialization[idx].val.day_2}
                            onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.day_2 = e.target.value}
                          >
                            {handler.selectModulesDays.map((day) => {
                              return (
                                <option value={`${day}`}>
                                  {day}
                                </option>
                              )
                            })}
                          </SaveSelect>
                        </Row>
                      </Row>
                      <Row alignCenter>
                        <Row justifyStart>Mês:</Row> 
                        <Row justifyEnd>
                          <SaveSelect 
                            isMobile
                            value={handler.moduleDatesFellowSpecialization[idx].val.month}
                            onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.month = e.target.value}
                          >
                            <option value="JANEIRO">JANEIRO</option>
                            <option value="FEVEREIRO">FEVEREIRO</option>
                            <option value="MARÇO">MARÇO</option>
                            <option value="ABRIL">ABRIL</option>
                            <option value="MAIO">MAIO</option>
                            <option value="JUNHO">JUNHO</option>
                            <option value="JULHO">JULHO</option>
                            <option value="AGOSTO">AGOSTO</option>
                            <option value="SETEMBRO">SETEMBRO</option>
                            <option value="OUTUBRO">OUTUBRO</option>
                            <option value="NOVEMBRO">NOVEMBRO</option>
                            <option value="DEZEMBRO">DEZEMBRO</option>
                          </SaveSelect>
                        </Row>
                      </Row>
                      <Row alignCenter>
                        <Row justifyStart>Ano:</Row> 
                        <Row justifyEnd>
                          <SaveSelect 
                            isMobile
                            value={handler.moduleDatesFellowSpecialization[idx].val.day_1}
                            onChange={(e) => handler.moduleDatesFellowSpecialization[idx].val.day_1 = e.target.value}
                          >
                            {handler.selectModulesYears.map((year) => {
                              return (
                                <option value={`${year}`}>
                                  {year}
                                </option>
                              )
                            })}
                          </SaveSelect>
                        </Row>
                      </Row>
                    </Text>
                  </Row>
                </Row>
                {idx !== handler.moduleDatesFellowSpecialization.length -1 && <hr />}
              </>
            );
          })}  
          <Row margtop={px2vw(100)}>
            <SaveButton
              width="100%"
              height={px2vw(130)}
              borderradius={px2vw(60)}
              onClick={() => handler.handleSaveData()}
            >
              <Text color={white} fontSize={px2vw(45)}>{getResource('adminSaveButtonLabelText')}</Text>
            </SaveButton>  
          </Row>
        </SaveBox>
      </Col>
    </Col>
  );
}
