import React from "react";
import { Navigate } from 'react-router-dom';

import FellowSpecializationWeb from './Web';
import FellowSpecializationMobile from './Mobile';

import { home } from "../../routes/routeMap";

export default function FellowSpecialization({ handler }) {
  if (handler.country !== 'pt') {
    const handlerFellowSpecialization = {
      ...handler,
      moduleDatesFellowSpecializationPart1: handler.moduleDatesFellowSpecialization.slice(0, 6),
      moduleDatesFellowSpecializationPart2: handler.moduleDatesFellowSpecialization.slice(6),
    }

    if (handler.isWeb) {
      return <FellowSpecializationWeb handler={ handlerFellowSpecialization }/>
    } else {
      return <FellowSpecializationMobile handler={ handlerFellowSpecialization }/>
    }
  } else {
    return <Navigate to={home} replace={true} />
  } 
}
