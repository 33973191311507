import React from 'react';

import { gray, white } from '../../../../styles/colors';
import { Row, Col } from '../../../../styles/grid';
import { Image, ImageBox } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getResource } from '../../../../utils/constants';

import { home } from '../../../../routes/routeMap';

export default function HeaderMobile({ handler }) {
  return (
    <Col height="auto" alignCenter padtop={px2vw(60)} padbottom={px2vw(60)} background={white} boxshadow={`0px 7px 9px -4px ${gray}`} fixed top="0" zIndex="10">
      <Row height="auto">
        <Row height="auto" justifyStart margtop={px2vw(40)} margleft={px2vw(70)}>
          <ImageBox width={px2vw(600)} height={px2vw(90)}>
            <a href={home}>
              <Image src={getResource('logoImageBlack')} />
            </a>
          </ImageBox>
        </Row>
        <Row width="auto" margright={px2vw(70)}>
          <ImageBox width={px2vw(150)} height={px2vw(150)} cursor="pointer" onClick={() => handler.handleSetSidebarOpen(true)}>
            <Image src={getResource('adminMobileMenuImage')} />
          </ImageBox> 
        </Row>
      </Row>
    </Col>
  )
}
