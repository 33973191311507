import React from "react";
import { toast } from "react-toastify";
import { ref, set } from "firebase/database";

import db from "../../../utils/firebase";

import AdminHarmonizePTWeb from './Web';
import AdminHarmonizePTMobile from './Mobile';

export default function AdminHarmonizePT({ handler }) {
  function handleSaveData() {
    if (handler.availableSeatsHarmonizeMethodPT.trim() === '' || handler.nextClassHarmonizeMethodPT.trim() === '' || handler.scheduleDocURLPDFHarmonizeMethodPT.trim() === '' || handler.scheduleDocURLPDFHarmonizeMethodAdvancedPT.trim() === '') {
      toast.error("Algum campo foi deixado sem preenchimento!", {
        autoClose: 6000,
      });
      return;
    }
    if (handler.harmonizeMethodWaitingPT !== true && handler.harmonizeMethodWaitingPT !== false) {
      toast.error("Valor inválido para lista de espera!", {
        autoClose: 6000,
      });
      return;
    }
    if (parseInt(handler.availableSeatsHarmonizeMethodPT) < parseInt(0)) {
      toast.error("Número de vagas não pode ser menor que zero", {
        autoClose: 6000,
      });
      return;
    }
    if (new Date(handler.nextClassHarmonizeMethodPT) <= new Date()) {
      toast.error("A data da próxima turma não pode ser menor ou igual a data atual!", {
        autoClose: 6000,
      });
      return;
    }

    set(ref(db, `methods/harmonize_pt`), {
      waiting_list: handler.harmonizeMethodWaitingPT,
      available_seats: handler.availableSeatsHarmonizeMethodPT,
      next_class_date: handler.nextClassHarmonizeMethodPT,
      schedule_doc_url: handler.scheduleDocURLPDFHarmonizeMethodPT,
      schedule_advanced_doc_url: handler.scheduleDocURLPDFHarmonizeMethodAdvancedPT,
    });

    toast.success("Dados salvos!", {
      autoClose: 6000,
    });
  }

  const adminHarmonizePTHandler = {
    ...handler,
    handleSaveData: () => handleSaveData(),
  }

  if (handler.isWeb) {
    return <AdminHarmonizePTWeb handler={adminHarmonizePTHandler} />
  } else {
    return <AdminHarmonizePTMobile handler={adminHarmonizePTHandler} />
  }
}
