import React from "react";
import { toast } from "react-toastify";
import { ref, set } from "firebase/database";

import db from "../../../utils/firebase";

import AdminHarmonizeBRWeb from './Web';
import AdminHarmonizeBRMobile from './Mobile';

export default function AdminHarmonizeBR({ handler }) {
  function handleSaveData() {
    if (handler.availableSeatsHarmonizeMethodBR.trim() === '' || handler.nextClassHarmonizeMethodBR.trim() === '' || handler.scheduleDocURLPDFHarmonizeMethodBR.trim() === '') {
      toast.error("Algum campo foi deixado sem preenchimento!", {
        autoClose: 6000,
      });
      return;
    }
    if (parseInt(handler.availableSeatsHarmonizeMethodBR) < parseInt(0)) {
      toast.error("Número de vagas não pode ser menor que zero", {
        autoClose: 6000,
      });
      return;
    }
    if (new Date(handler.nextClassHarmonizeMethodBR) <= new Date()) {
      toast.error("A data da próxima turma não pode ser menor ou igual a data atual!", {
        autoClose: 6000,
      });
      return;
    }

    set(ref(db, `methods/harmonize_br`), {
      available_seats: handler.availableSeatsHarmonizeMethodBR,
      next_class_date: handler.nextClassHarmonizeMethodBR,
      schedule_doc_url: handler.scheduleDocURLPDFHarmonizeMethodBR,
    });

    toast.success("Dados salvos!", {
      autoClose: 6000,
    });
  }

  const adminHarmonizeBRHandler = {
    ...handler,
    handleSaveData: () => handleSaveData(),
  }

  if (handler.isWeb) {
    return <AdminHarmonizeBRWeb handler={adminHarmonizeBRHandler} />
  } else {
    return <AdminHarmonizeBRMobile handler={adminHarmonizeBRHandler} />
  }
}
