export const home = '/';
export const harmonizeMethod = '/metodo-harmonize';
export const fellowSpecialization = '/especializacao-fellow';
export const admin = '/S8XP22NhZIYXReo/admin';
export const adminHarmonizeBR = '/S8XP22NhZIYXReo/admin/metodo-harmonize-br';
export const adminHarmonizePT = '/S8XP22NhZIYXReo/admin/metodo-harmonize-pt';
export const adminFellow = '/S8XP22NhZIYXReo/admin/especializacao-fellow';

export const adminRoutes = [
    { path: admin }, 
    { path: adminHarmonizeBR }, 
    { path: adminHarmonizePT }, 
    { path: adminFellow }
]