import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import AdminLoginWeb from './Web';
import AdminLoginMobile from './Mobile';

import { useAuth } from "../../../hooks/useAuth";

import { adminHarmonizeBR } from "../../../routes/routeMap";

export default function AdminLogin({ isWeb }) {
  const navigate = useNavigate()
  const { login } = useAuth();
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  function submitLogin() {
    if (user.trim() === '' && pwd.trim() === '') {
      toast.error("Todos os campos são obrigatórios!", {
        autoClose: 6000,
      });
      return;
    }

    if (user.trim() === '') {
      toast.error("Informe um usuário válido!", {
        autoClose: 6000,
      });
      return;
    } 
    
    if (pwd.trim() === '') {
      toast.error("Informe a senha!", {
        autoClose: 6000,
      });
      return;
    } 

    login(user, pwd)
      .then(() => {
        navigate(adminHarmonizeBR);
      })
  }

  const loginHandler = {
    user: user,
    pwd: pwd,
    passwordVisible: passwordVisible,
    setUser: (val) => setUser(val),
    setPwd: (val) => setPwd(val),
    setPasswordVisible: (val) => setPasswordVisible(val),
    submitLogin: () => submitLogin(),
  }

  if (localStorage.getItem("userAuthenticated")) {
    navigate(adminHarmonizeBR);
  } else {
    if (isWeb) {
      return <AdminLoginWeb handler={loginHandler} />
    } else {
      return <AdminLoginMobile handler={loginHandler} />
    }
  }
}
